import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})


// getcurrentdate service added by Kunal bansal on 22 nov 2021 start
export class Currentdate {
   

    Getcurrentdate(){
    var dateobj = new Date();
    var month = dateobj.getUTCMonth() + 1;
    var date = dateobj.getUTCDate();
    var year = dateobj.getUTCFullYear();
    if (month.toString().length == 1 && date.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + date;
      return [fulldate];
     
    }
    else if (month.toString().length == 2 && date.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + date;
     return [fulldate];
    }
    else if (month.toString().length == 1 && date.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + date;
      return [fulldate];
    }
    else {
      var fulldate = year + "-" + month + "-" + date;
      return [fulldate];
    }
    }
// ...end...

}