import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'app-sellerproductlisting',
  templateUrl: '../commondesign/commondesign.html',
  styleUrls: ['./sellerproductlisting.css']
})

export class Sellerproductlisting implements OnInit {
  sellerproductlisting: any[];
  realmaxload: number;
  realoffset: number = 2;
  sellerid = [];
  sellername = [];
  tab: number;
  //  phase 5 category wise view on seller listing added by Kunal bansal on 28 sep 2021 start
  labellisting = [];
  storelabel = [];
  parentlabel: string;
  buylist = [];
  activepage: string = "sellerlisting";
  locationstatus: number;
  Districtname: string;
  countrylocation: string;

  constructor(private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService) { }


  @HostListener("window:scroll", ["$event"])

  onWindowScroll(e) {
    //  phase 5 pagination scenario removed as per discussion with client
    // let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
    // let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
    // let max = document.documentElement.scrollHeight;
    // let max1 = document.body.scrollHeight - 10;
    // console.log(Math.round(pos) + " == " + max + " and " + Math.round(pos1) + " == " + max1)
    // if (Math.round(pos) == max ) {
    //   if ((this.realmaxload > this.sellerproductlisting.length)) {
    //     this.defaultapi(this.realoffset)


    //   }
    // }
  }

  ngOnInit() {

    // this.sellerproductlisting = this.storage.retrieve('slctprdctlstng');
    // this.buylist = this.storage.retrieve('slctprdctlstng');
    this.sellerid = this.storage.retrieve('Sellerid')
    this.sellername = this.storage.retrieve('Sellname')
    this.tab = this.storage.retrieve('pressedkey');
    //  phase 5 category wise view on seller listing added by Kunal bansal on 28 sep 2021 start
    this.locationstatus = this.storage.retrieve("locationstatus");
    this.Districtname = this.storage.retrieve("districtname");
    this.countrylocation = sessionStorage.getItem("phase5countrylocation");
    this.storelabel = this.storage.retrieve('brandlabelslisting');
    if (this.storelabel == null) {
      this.storelabel = [];
    }


    // ...end...
    this.defaultapi(1);
    if (this.tab == null) {
      this.tab = 1;
    }
    else {

    }
    // alert(this.sellerid)
    // alert(JSON.stringify(this.sellerproductlisting))
  }

  ngOnDestroy() {
    // this.storage.clear('slctprdctlstng')
    // this.storage.clear('Sellerid')
    // this.storage.clear('Sellname')
  }

  // <!-- phase 5 new scenario added by Kunal bansal on 28 sep 2021 start -->
  Removelabel(id,child) {
    if (id == 0) {
      this.storage.clear('brandlabelslisting');
      this.router.navigate(['/homepage/productListing']);
    }
    else {


      this.storelabel.push(id);
       // phase 5 auto child append for removal added by Kunal bansal on 01 oct 2021 start
       if (child != undefined) {
        if (child.length > 0) {
          for (let b = 0; b < child.length; b++) {
            this.storelabel.push(child[b])
          }
        }
      }
      // ...end...

      this.storage.store('brandlabelslisting', this.storelabel);
      this.authservice.apipost('SellerProductList/', {
        platform: 0, //0 for web and 1 for   app
        // offset: off,
        offset: 1,
        product_seller_id: this.sellerid,
        "location": this.locationstatus == 1 ? this.Districtname : "",
        "country": this.countrylocation,
        "remove_list": this.storelabel


      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          // alert(JSON.stringify(response.response.banners[2].action_type))
          // this.realoffset = this.realoffset + 1;
          // this.realmaxload = response.response.total_count;
          this.sellerproductlisting = response.response.buying_list;

          //  phase 5 category wise view on seller listing added by Kunal bansal on 28 sep 2021 start
          this.buylist = response.response.buying_list;

          this.labellisting = response.response.request_data;
          this.parentlabel=response.response.store_name;

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  //  ...end...

  defaultapi(off) {

    if (off == 1) {
      this.authservice.apipost('SellerProductList/', {
        platform: 0, //0 for web and 1 for   app
        // offset: off,
        offset: 1,
        product_seller_id: this.sellerid,
        "location": this.locationstatus == 1 ? this.Districtname : "",
        "country": this.countrylocation,
        "remove_list": this.storelabel


      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          // alert(JSON.stringify(response.response.banners[2].action_type))
          this.realoffset = this.realoffset + 1;
          this.realmaxload = response.response.total_count;
          this.sellerproductlisting = response.response.buying_list;

          //  phase 5 category wise view on seller listing added by Kunal bansal on 28 sep 2021 start
          this.buylist = response.response.buying_list;

          this.labellisting = response.response.request_data;
          this.parentlabel=response.response.store_name;

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else {

      this.authservice.apipost('SellerProductList/', {
        platform: 0,
        tab: this.sellerid,
        offset: 1,
        "location": this.locationstatus == 1 ? this.Districtname : "",
        "country": this.countrylocation,
        "remove_list": this.storelabel
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          this.realoffset = this.realoffset + 1;
          this.realmaxload = response.response.total_count;
          this.sellerproductlisting = this.sellerproductlisting.concat(response.response.buying_list);
          //  phase 5 category wise view on branch and search added by Kunal bansal on 28 sep 2021 start
          this.buylist = response.response.buying_list;
         
          this.labellisting = response.response.request_data;
          this.parentlabel=response.response.store_name;

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }


  pdetail(id, catid) {
    this.storage.store("pdid", id);
    this.storage.store("ptype", 0);
    // this.router.navigate(['/homepage/productdetail']);
    // link share module added by Kunal bansal on 16feb2021
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: catid, ptype: this.tab } });
  }
}