import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';
import { store } from '@angular/core/src/render3/instructions';
import { Uaepoboxes } from '../../app/Services/Uaepoboxes.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {
  updateForm: FormGroup;
  button: string = "Edit Profile";
  selectedFile: File = null;
  url: any = "../assets/images/dummy.png";
  address: any = [];
  isseller: any;
  pincode: any;
  demo: string = 'yes';
  temp: number;
  temp2: number = 0;
  category: any[];
  childcategory = [];
  matchingcategory = [];

  myagentcode: string;
  refferalagentcode: string;

  country = [];
  selectedcountry: string;
  uaecities = ["Albekistan"];
  location_type: string;
  // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 23 aug 2021 start........
  tradelicense: File = null;
  additionalfile:File=null;
  viewtradelicenseimage: any = "";
  viewadditionalimage: any = "";
  // added by Kunal bansal on 04 oct 2021 start
  editbtndisabled: boolean = true;
  // ...end...
  uaelocationtype: string;
  Freezonelisting = [];
  uaecountries = [];

  constructor(private pobox: Uaepoboxes, private authservice: AuthService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder,
    private translate: TranslateService, private router: Router, private storage: SessionStorageService, private modalService: NgbModal) { }

  ngOnInit() {
    // $(".countryunlock").attr("disabled", true);
    let language = this.storage.retrieve('language');
    if (language == 'Hindi') {
      $("#language").val("Hindi");
    }
    else if (language == 'Gujarati') {
      $("#language").val("Gujarati");
    }
    else if (language == 'Arabic') {
      $("#language").val("Arabic");
    }
    else if (language == 'Punjabi') {
      $("#language").val("Punjabi");
    }
    else{
      $("#language").val("English");
    }

    $(document).ready(function () {
      $("form :input").attr("disabled", true);
    });
    $(document).ready(function () {
   $("#noti").attr("disabled", false);
      $(".countryunlock").addClass("countrycol");
      $("#countrydisable").addClass("countrycol");

    });

    // added by Kunal bansal on 04feb2021 start
    // $(document).ready(function () {
    //   $("#noti").attr("disabled", false);
    // });
    // end
    $("#uaestate").attr("disabled", false);

    $(".triggerBtn").click(function () {
      $(".imm").trigger("click");
    });

    this.updateForm = this.formBuilder.group({
      name: [''], category: [''], email: [''], user_business_type: [''], phone: [''], pincode: [''], state: [''], city: [''], Address: [''], image: [''], is_Seller: [''], my_agent_code: [''], refered_agent_code: [''], gst_number: [''], notify_allow: [], display_name: [''],
      tax_registration_number: [''], trade_license: [''], addtional_document: [''], location_type: [''], country: [''], user_location: ['']
    });

    this.authservice.apiget('Profile/?flag=1').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.updateForm.setValue(response.response);
        // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 23 aug 2021 start........
        this.updateForm.patchValue({ "trade_license": response.response.trade_license });
        this.viewtradelicenseimage = response.response.trade_license;
        this.viewadditionalimage = response.response.addtional_document;
        this.uaelocationtype = response.response.location_type;
      
        // ...end...

        // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 19 aug 2021 start -->
        if (response.response.country) {
          if (response.response.country == 'India') {
            this.country = ["India", "UAE"];
            this.selectedcountry = "India";

          }
          else if (response.response.country == 'UAE') {
            this.country = ["UAE", "India"];
            this.selectedcountry = "UAE";
            this.location_type = response.response.location_type;

            this.getpobox();

          }

          else {
            this.country = [];
            this.selectedcountry = "";
          }
          this.inlineselectuaecountry(this.updateForm.value.state);
        }
        // ....end....
        this.url = response.response.image
        this.address = response.response.Address
        this.isseller = response.response.is_Seller
        this.pincode = response.response.pincode
        if (response.response.notify_allow == 1 || response.response.notify_allow == '1') {
          $("#noti").prop("checked", true);
        }
        else{
          $("#noti").prop("checked", false);
        }
        // edit category user preference api added by Kunal bansal on 08 april 2021 start
        for (let c = 0; c < response.response.category.length; c++) {
          this.matchingcategory.push(response.response.category[c]);
        }
        //     var b=[2,4,3,0];
        //     var c=3;
        //     if ($.inArray(c, b) != -1)
        // {
        //   alert('found')
        //   alert($.inArray(c, b));  

        // }
        // else{
        //   alert('not found')
        // }
        // alert(JSON.stringify(this.matchingcategory))
        //  end

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // this.matchingcategory.push({ "id": 185 }, { "id": 183 }, { "id": 184 }, { "id": 186 }, { "id":2 }
    // , { "id": 3 }
    // , { "id": 70 }
    // , { "id": 172 }
    // , { "id": 135 }, { "id": 174 },{"id":55},{"id":158},{"id":159},{"id":160});
    // this.childcategory.push({ "id": 185 }, { "id": 183 }, { "id": 184 }, { "id": 186 }, { "id":2 }
    // , { "id": 3 }
    // , { "id": 70 }
    // , { "id": 172 }
    // , { "id": 135 }, { "id": 174 },{"id":55},{"id":158},{"id":159},{"id":160});
    this.getfreezonelisting();
    //phase 5 added by Kunal bansal on 03 nov 2021 due to new scenario of pobox autofetch reklation break and city fetched based on country
    this.gettalluaecountries();
  }


  // phase 5 new cr uae freezone and mainland cities module added by Kunal bansal on 03 nov 2021 start
  getfreezonelisting() {
    try {
      this.pobox.getfreezonelisting().map(res => {

        this.Freezonelisting = res.response;
        console.log(this.Freezonelisting);
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  gettalluaecountries() {

    try {
      this.pobox.getallcountries().map(res => {

        if (res.status == 200) {
          console.log(res.response);

          for (let n = 0; n < res.response.length; n++) {
            this.uaecountries.push(res.response[n].country)
          }
          console.log(this.uaecountries)
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      console.log(err)
      // this.model.state = '';
      // // this.uaecities = [];
    }

  }
  // ...end...

  locationtype(event) {
    this.updateForm.patchValue({
      user_location: '',
    });
    this.uaelocationtype = event.target.value;


  }
  selectuaecountry(event) {
    this.updateForm.patchValue({
      city: '',
    });
    try {
      this.pobox.getcitybycountry(event.target.value).map(res => {

        if (res.status == 200) {
          this.uaecities = res.response.cities;
        }
        else {

        }
      })
    }
    catch (err) {

      console.log(err)
    }
  }

  inlineselectuaecountry(event) {

    try {
      this.pobox.getcitybycountry(event).map(res => {

        if (res.status == 200) {
          this.uaecities = res.response.cities;
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      // this.uaecities = [];
      console.log(err)
    }
  }
  // ...end...


  // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 19 aug 2021 start -->
  getpobox() {

    if (this.updateForm.value.pincode == undefined  || this.updateForm.value.pincode=="") {
      // Swal.fire('Enter valid Po Box Number')
    }
    else {
      try {
        this.pobox.Getponumber(this.updateForm.value.pincode).map(res => {

          if (res.status == 200) {
            console.log(res.response)

            // this.updateForm.patchValue({
            //   state: res.response.country,

            // });
            // this.uaecities = res.response.cities;

          }
          else {

          }
        })
      }
      catch (err) {
        Swal.fire("Enter valid Pobox number");
        // this.updateForm.patchValue({
        //   state: "",


        // });
        // this.uaecities = [];
        console.log(err)
      }

    }
  }

  // ..............end.............

  // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 23 aug 2021 start........

  resetdoc() {
    $("#docreset").val("");
  }

  additionalfilereset(){
    $("#addreset").val("");
  }


  // phase 5 added by Kunal bansa on 04 oct 2021 start

  viewtradelicense() {
    if (this.updateForm.value.trade_license == null) {
      Swal.fire('No image or document uploaded here till now')
    }
    else {
      window.open(this.viewtradelicenseimage);
    }
  }


  additionalimage() {
    if (this.updateForm.value.addtional_document == null) {
      Swal.fire('No image or document uploaded here till now')
    }
    else {
      window.open(this.viewadditionalimage);
    }

  }
  // ...end...

  Additionalfile(event: any) {

    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("File size should not be greater than 2 Mb.")
    }

    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          // this.viewtradelicenseimage= (<FileReader>event.target).result;
          console.log(this.viewtradelicenseimage)
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      this.additionalfile= event.target.files[0];

    }
    else {
      this.additionalfilereset();
      Swal.fire("Please Upload images or document only");
      // commented by Kunal bansal on 25 may 2021

      // end
    }
  }



  Tradelicense(event: any) {

    const file = event.target.files[0];
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("File size should not be greater than 2 Mb.")
    }

    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "application/pdf") {
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          // this.viewtradelicenseimage= (<FileReader>event.target).result;
          console.log(this.viewtradelicenseimage)
        }
        reader.readAsDataURL(event.target.files[0]);
      }
      this.tradelicense = event.target.files[0];

    }
    else {
      this.resetdoc();
      Swal.fire("Please Upload images or document only");
      // commented by Kunal bansal on 25 may 2021

      // end
    }
  }

  saveprofilenull() {

  }

  changecountry(event) {

    this.selectedcountry = event.target.value;
    console.log(this.updateForm.value.country)
    if (event.target.value == "India") {
      this.country = ["India", "UAE"];
      this.updateForm.patchValue({
        state: "",
        pincode: "",
        city: ""

      });
    }
    else {
      this.country = ["UAE", "India"];
      this.updateForm.patchValue({
        state: "",
        pincode: "",
        city: ""
      });
    }


  }


  // <!-- New phase 3 select category prefrence on signup added by kunal bansal on 10 march 2021 start -->
  opencatpopup(shopbycategory) {

    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.childcategory = [];
    this.temp = undefined;
    // <!-- New phase 3 cr categoryapi added by kunal bansal on 10 march 2021 start -->
    this.authservice.apipost('CategoryList/', {
      "flag": "filtercatgory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        this.category = response.response;
        // <!-- New phase 3 cr function creating for  showing category active and already ticked in checkbox that is selected at time of signup  by kunal bansal on 11 march 2021 start -->
        for (let i = 0; i < this.matchingcategory.length; i++) {
          for (let f = 0; f < this.category.length; f++) {
            if (this.matchingcategory[i].id == this.category[f].id) {
              this.category[f].checked = true;
              if (this.childcategory.includes(this.category[f].id)) {

              }
              else {
                this.childcategory.push(this.category[f].id);
              }

            }
            for (let k = 0; k < this.category[f].child_category.length; k++) {

              if (this.matchingcategory[i].id == this.category[f].child_category[k].id) {
                this.category[f].child_category[k].checked = true;
                if (this.childcategory.includes(this.category[f].child_category[k].id)) {

                }
                else {
                  this.childcategory.push(this.category[f].child_category[k].id);
                }

              }
            }
          }
        }
        // alert(JSON.stringify(this.childcategory))
        // end
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )



    // end

  }
  // end
  // <!-- New phase 3 function for select category prefrence for manual child category selection on signup added by kunal bansal on 11 march 2021 start -->
   onebyone(event, i, c) {
  
    if (event.target.checked == true) {


      if (this.childcategory.includes(this.category[i].child_category[c].id)) {
        
      }
      else {
        // added by Kunal bansal on 23 dec 2021 during phase 5 start
        if (this.childcategory.includes(this.category[i].id)) {

        }
        else {
          this.childcategory.push(this.category[i].id);
          this.category[i].checked = true;
       
        }

        // ...end...

        this.childcategory.push(this.category[i].child_category[c].id);
     
      }

      // New fucntionality added if all child selecting parent automatic selected by Kunal bansal on 01 april 2021 start
      let g = 0;
      for (let n = 0; n < this.category[i].child_category.length; n++) {


        if (this.category[i].child_category[n].checked == true) {
          // console.log(n)
          // console.log('true available')

          g = g + 1;
          // console.log(k);

        }
        else {
          // console.log("not found");

        }
        // console.log(k)
      }

      if (this.category[i].child_category.length == g) {
        console.log("all selected");
        // commented by Kunal bansal on 23 dec 2021 during phase 5 start
        // this.category[i].checked = true;
        // this.childcategory.push(this.category[i].id);
        // this.labelling.push(this.category[i]);
        // ....end...
      }
      console.log(g)
    }
    // end

    else {
      // commented by Kunal bansal on 23 dec 2021 during phase 5 start
      // this.category[i].checked = false;
      // if (this.childcategory.includes(this.category[i].id)) {
      //   let po = this.childcategory.indexOf(this.category[i].id);
      //   this.childcategory.splice(po, 1);
      //   this.labelling.splice(po, 1);
      // }


      // if (this.childcategory.includes(this.category[i].id)) {
      //   let f = this.childcategory.indexOf(this.category[i].id);
      //   this.childcategory.splice(f, 1);
      // }
      // ...end...

      let p = this.childcategory.indexOf(this.category[i].child_category[c].id);
      this.childcategory.splice(p, 1);
   




      // let k=this.childcategory.indexOf(this.category[i].id);
      // this.childcategory.splice(k,1);
    }

    // New fucntionality added if al child unselect one by one on unticking last child parent also removed by Kunal bansal on 01 april 2021 start

    let k = 0;
    for (let n = 0; n < this.category[i].child_category.length; n++) {


      if (this.category[i].child_category[n].checked == true) {
        // console.log(n)
        // console.log('true available')

        k = k + 1;
        // console.log(k);

      }
      else {
        // console.log("not found");

      }
      // console.log(k)
    }
    if (k == 0) {
      this.category[i].checked = false;
      let f = this.childcategory.indexOf(this.category[i].id);
      if (this.childcategory.includes(this.category[i].id)) {
        this.childcategory.splice(f, 1);

     
      }

      console.log(this.childcategory)
    }

    // end
    // alert(JSON.stringify(this.childcategory))
  }
  // end  // end

  // <!-- New phase 3 function for selecting parent category and select all child category inside it prefrence on signup added by kunal bansal on 10 march 2021 start -->
  selectall(event, f) {
    if (event.target.checked == true) {
      if (this.childcategory.includes(this.category[f].id)) {

      }
      else {
        this.childcategory.push(this.category[f].id)
      }

      for (let i = 0; i < this.category[f].child_category.length; i++) {
        this.category[f].child_category[i].checked = true;
        if (this.childcategory.includes(this.category[f].child_category[i].id)) {

        }
        else {
          this.childcategory.push(this.category[f].child_category[i].id);
        }

      }
    }
    else {


      for (let i = 0; i < this.category[f].child_category.length; i++) {
        this.category[f].child_category[i].checked = false;


        if (this.childcategory.includes(this.category[f].id)) {
          if (this.childcategory.includes(this.category[f].child_category[i].id)) {
            let k = this.childcategory.indexOf(this.category[f].child_category[i].id);
            this.childcategory.splice(k, 1);
          }
        }

      }
      if (this.childcategory.includes(this.category[f].id)) {
        let po = this.childcategory.indexOf(this.category[f].id);
        this.childcategory.splice(po, 1);
      }
    }
    // alert(JSON.stringify(this.childcategory))
  }
  // end
  // <!-- New phase 3 select category prefrence on signup added by kunal bansal on 18 march 2021 start -->
  submitcategory() {
    if (this.childcategory.length == 0) {
      Swal.fire("Please select at least one category")
    }
    else {
      this.modalService.dismissAll();
      this.authservice.apipost('UpdateCategoryPerferences/', {
        category: this.childcategory
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // alert(JSON.stringify(this.childcategory))
          // alert(JSON.stringify(response.response));
          location.reload();
          //this.router.navigate(['/homepage/profile']);
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

    //  alert(this.childcategory.length)

  }
  Resetcategory() {
    for (let j = 0; j < this.category.length; j++) {
      this.category[j].checked = false;
      for (let s = 0; s < this.category[j].child_category.length; s++) {



        if (this.category[j].child_category[s].checked == true) {
          this.category[j].child_category[s].checked = false;
        }

      }
    }
    this.childcategory = [];
  }

  // <!-- New phase 3 select category prefrence on signup added by kunal bansal on 10 march 2021 start -->
  openfaq(i) {

    if (this.temp == i) {
      this.temp = undefined;
    }
    else {


      this.temp = i

    }
  }
  // end

  changelanguage(value) {
    if (value == 'English') {
      $("#language").val("English");
      this.translate.setDefaultLang('en');
      this.storage.store("language", 'English');
    }
    else if (value == 'Hindi') {
      $("#language").val("Hindi");
      this.translate.setDefaultLang('hin');
      this.storage.store("language", 'Hindi');
    }
    else if (value == 'Gujarati') {//modified by Kunal 19/march/2020 spelling mistake
      $("#language").val("Gujarati");    //modified by Kunal 19/march/2020 spelling mistake
      this.translate.setDefaultLang('guj');
      this.storage.store("language", 'Gujarati');
    }
    else if (value == 'Punjabi') {
      $("#language").val("Punjabi");
      this.translate.setDefaultLang('punjabi');
      this.storage.store("language", 'Punjabi');
    }
    else if (value == 'Arabic') {
      $("#language").val("Arabic");
      this.translate.setDefaultLang('arabic');
      this.storage.store("language", 'Arabic');
    }
  }

  readUrl(event: any) {
    const file = event.target.files[0];

    this.selectedFile = event.target.files[0]
    if (file.size / 1000 / 1000 > 2) {
      Swal.fire("Image size should not be greater than 2 Mb.")
    }
    // .webp support phase 3 new cr added by Kunal bansal on 09 march 2021 start
    else if (file.type == "image/jpeg" || file.type == "image/png" || file.type == "image/webp") {

      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();
        reader.onload = (event: ProgressEvent) => {
          this.url = (<FileReader>event.target).result;
          console.log(this.url)
        }
        reader.readAsDataURL(event.target.files[0]);
      }
    }
    else {
      Swal.fire("Image should be in .png or .jpg format. or .webp format")
    }
    // end
  }


  // independent optout select module added by Kunal bansal on 04feb2021 start
  indcheckbox() {

    let formData = new FormData();
    formData.append("name", this.updateForm.value.name);
    formData.append("user_business_type", this.updateForm.value.user_business_type);
    formData.append("pincode", this.updateForm.value.pincode);
    formData.append("state", this.updateForm.value.state.trim());
    formData.append("city", this.updateForm.value.city.trim());
    formData.append("gst_number", this.updateForm.value.gst_number);
    formData.append("my_agent_code", this.updateForm.value.my_agent_code);
    formData.append("country", this.selectedcountry);
      formData.append("location_type", this.updateForm.value.location_type);
    formData.append("user_location", this.updateForm.value.user_location);
    formData.append("refered_agent_code", this.updateForm.value.refered_agent_code);
    if (this.selectedFile == null) {

    }
    else {
      formData.append("image", this.selectedFile);
    }
    var display_name = $.trim(this.updateForm.value.display_name);
    if (display_name.length == 0 || display_name == undefined || display_name == null || display_name == "") {
      formData.append("display_name", '');
    }
    else {
      formData.append("display_name", display_name);
    }
    if ($("#noti").prop("checked") == false) {
      formData.append('notify_allow', '0');
    }
    else {
      formData.append('notify_allow', '1');
    }
    this.authservice.formdata('Profile/', formData).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.msg)
      }
      else if (response.status == 200) {
        this.button = "Edit Profile"
        Swal.fire({
          title: "Profile Updated Successfully",
          allowOutsideClick: false,
          showCancelButton: false,
        }).then((result) => {
          if (result.value) {
            // location.reload();
            this.ngOnInit();
          }
        })
      }
      else {

        console.log("Server Down");
      }
    },
      err => { this.spinner.hide(); Swal.fire("Connect to the Internet"); }
    );
   

  }
  // end

  // phase 5 added by Kunal bansal on 24 sep 2021 start
  saveprofile() {

    if ($.trim(this.updateForm.value.name).length == 0) {
      Swal.fire("Please enter a valid name")
    }
    else if ($.trim(this.updateForm.value.pincode).length == 0) {
      Swal.fire("Please enter a valid pincode/Po box number")
    }
    else if ($.trim(this.updateForm.value.state).length == 0) {
      Swal.fire("Please enter a valid state")
    }
    else if ($.trim(this.updateForm.value.city).length == 0) {
      Swal.fire("Please enter a valid city")
    }
    // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 24 aug 2021 start........
    else if (this.updateForm.value.location_type == null && this.selectedcountry == "UAE") {
      Swal.fire("Please select location type")
    }

    else {
      let formData = new FormData();
      formData.append("name", this.updateForm.value.name);
      formData.append("user_business_type", this.updateForm.value.user_business_type);
      formData.append("pincode", this.updateForm.value.pincode);
      formData.append("state", this.updateForm.value.state);
      formData.append("city", this.updateForm.value.city);
      formData.append("gst_number", this.updateForm.value.gst_number);
      formData.append("country", this.selectedcountry);
      formData.append("location_type", this.updateForm.value.location_type);
      formData.append("user_location", this.updateForm.value.user_location);
      // formData.append("tax_registration_number", this.updateForm.value.tax_registration_number);
      // <!-- .....Phase 5 new cr Country selection module added by Kunal bansal on 23 aug 2021 start........
      if (this.tradelicense != null) {
        formData.append("trade_license", this.tradelicense)
      }
      if (this.additionalfile != null) {
        formData.append("addtional_document", this.additionalfile)
      }
      // ...end...

      if (this.selectedFile == null) {

      }
      else {
        formData.append("image", this.selectedFile);
      }
      var display_name = $.trim(this.updateForm.value.display_name);
      if (display_name.length == 0 || display_name == undefined || display_name == null || display_name == "") {
        formData.append("display_name", '');
      }
      else {
        formData.append("display_name", display_name);
      }
      if ($("#noti").prop("checked") == false) {
        formData.append('notify_allow', '0');
      }
      else {
        formData.append('notify_allow', '1');
      }

      this.authservice.formdata('Profile/', formData).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.msg)
        }
        else if (response.status == 200) {

          this.button = "Edit Profile"
          Swal.fire({
            title: "Profile Updated Successfully",
            allowOutsideClick: false,
            showCancelButton: false,
          }).then((result) => {
            if (result.value) {
              location.reload();
            }
          })

          // $(".countryunlock").attr("disabled", false);
          // $(".countryunlock").removeClass("countrycolnone").addClass("countrycol");
          // $("#countrydisable").removeClass("countrycolnone").addClass("countrycol");

        }
        else {

          console.log("Server Down");
        }
      },
        err => { this.spinner.hide(); Swal.fire("Connect to the Internet"); }
      );
      this.ngOnInit();
    }

  }



  // ...end...

  update() {

    // $(".countryunlock").attr("disabled", false);
    $(".countryunlock").removeClass("countrycol").addClass("countrycolnone");
    $("#countrydisable").removeClass("countrycol").addClass("countrycolnone");


    $("form :input.hh").attr("disabled", false);
    $("#uaecity").attr("disabled", false);
    $("#uaestate").attr("disabled", false);
    $("#uaelocationtype").attr("disabled", false);
    $("#uaefreezonelocation").attr("disabled", false);
    $("#docreset").attr("disabled", false);
    $("#addreset").attr("disabled", false);
    this.editbtndisabled = false;

    // commented by Kunal bansal on 04 feb  2021
    // $("#noti").attr('disabled', !$("#noti").attr('disabled'))


  }

  getpincode() {
    if (this.updateForm.value.pincode.length < 6 || this.updateForm.value.pincode.length > 6) {
      Swal.fire("Please enter a valid Pincode")
    }
    else {
      this.authservice.apipost('getPincode/', {
        "pincode": this.updateForm.value.pincode
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500) {
          Swal.fire(response.message)
        }
        else if (response.status == 100) {
          this.updateForm.patchValue({ pincode: this.pincode })
          Swal.fire('No State City found linked to your pincode , Please try another pincode')
        }
        else if (response.status == 200) {
          this.pincode = this.updateForm.value.pincode;
          this.updateForm.patchValue({
            state: response.response.state,
            city: response.response.city
          });
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  // added by Kunal bansal on 15 march 2021 start
  addaddress() {
    this.storage.store("goingthroughgeneratepickup", 0);
    this.router.navigate(['/homepage/addAddress'])
  }
  // end

  closeall() {
    this.modalService.dismissAll();
  }

  changeaddss(name, mob, add1, add2, land, pin, city, state, id, country, locationtype, uaelocationtype) {  //Added by Kunal on 16/april/2020 
    this.storage.store("goingthroughgeneratepickup", 0);
    let storeaddress = {
      name, mob, add1, add2, land, pin, city, state, id
    }
    this.storage.store("storedadd", storeaddress);   // Added by Kunal on 23 april 2020
    this.storage.store("fullname", name)
    this.storage.store("mobile", mob),
      this.storage.store("Add1", add1)// Added by Kunal on 23 april 2020
    this.storage.store("Add2", add2)
    this.storage.store("land", land)
    this.storage.store('pincode1', pin)
    this.storage.store('city1', city)
    this.storage.store('state1', state)  // Added by Kunal on 23 april 2020
    // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
    sessionStorage.setItem('countrymodulecountry', country)
    // ....end....
    // phase5 location type added in add and edit address by Kunal bansal on 06 oct 2021 start
    sessionStorage.setItem('phase5locationtype', locationtype)
    sessionStorage.setItem('uaelocationtype', uaelocationtype)

    this.router.navigate(['/homepage/changeaddrss']);
  }

  markdefault(e, id) {
    this.authservice.apipost('Address/?flag=2', {
      adid: id
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        //this.router.navigate(['/homepage/profile']);
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

}