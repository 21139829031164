import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Globals } from '../globals';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-Orderqueries',
  templateUrl: './Orderqueries.html',
  styleUrls: ['./Orderqueries.css']
})

export class Orderqueries implements OnInit {
  querylist: any[];
 realoffset:number=2
  realmaxload:number;
  buystatus:number;
  tab:number;
   
  constructor(private globals: Globals, private storage: SessionStorageService, private authservice: AuthService, private router: Router, private spinner: NgxSpinnerService) { }

  @HostListener("window:scroll", ["$event"])

  onWindowScroll(e) {
    let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
    let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
    let max = document.documentElement.scrollHeight;
    let max1 = document.body.scrollHeight - 10;
    // console.log(Math.round(pos) + " == " + max + " and " + Math.round(pos1) + " == " + max1)
    if (Math.round(pos) == max) {
      if ((this.realmaxload > this.querylist.length)) {
        this.orderqueries(this.realoffset)
        // alert('yes')
      }
    }
  }
  ngOnInit() {
    
   
    this.querylist = this.storage.retrieve('orderquerylist');
    this.tab=this.storage.retrieve('buystatus');
  
    // this.querylist = [];
    this.buystatus=this.storage.retrieve('buystatus');
    this.realmaxload = this.storage.retrieve('Totalorderqueries')
    // alert(JSON.stringify(this.realmaxload))
  }



  ngOnDestroy() {
    // this.storage.clear('orderquerylist')
    // this.storage.clear('Totalorderqueries')
  }
  // querylist2(queryid,queryrequest_type,queryname,queryaction_flag,querybrief,querycode,queryadmin_comment,querydate){
    querylist2(queryid){
    this.storage.store('orderqueryid',queryid);
    // this.storage.store('querylisting',quelist)
    this.authservice.apipost('getQueryList/',
    {

      platform: 0, //0 for web and 1 for   app
      offset: 1,
      query_id: queryid,
      "buy_request":this.buystatus==0?"no":"yes"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }

      else if (response.status == 200) {
       
        this.router.navigate(['/homepage/querydetail']);

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  
 
   
    

  }
  orderqueries(off) {
    this.authservice.apipost('getQueryList/',
      {
        platform: 0, //0 for web and 1 for   app
        offset: off,
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }

        else if (response.status == 200) {
          this.realoffset=this.realoffset+1
          this.realmaxload= response.response.total_queries;

         this.querylist = this.querylist.concat(response.response.query_list);
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
  }



}