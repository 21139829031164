import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  private async request(method: string, url: string, data?: any) {

    const result = this.http.request(method, url, {
      body: data,
      responseType: 'json',
      observe: 'body',
      headers: {
        //Authorization: `Bearer ${token}`
      }
    });
    return new Promise((resolve, reject) => {
      result.subscribe(resolve, reject);
    });
  }

  // getGags() {
  //   return this.request('GET', `${environment.serverUrl}/gags`);
  // }

  getContents() {
    return this.request('GET', `${environment.serverUrl}/homecontents`);
  }

  getTeamPhotographs() {
    return this.request('GET', `${environment.serverUrl}/teamphotographs`);
  }

  getEventPhotographs() {
    return this.request('GET', `${environment.serverUrl}/eventphotographs`);
  }

  getOfficePhotographs() {
    return this.request('GET', `${environment.serverUrl}/officephotographs`);
  }

  getTestimonials() {
    return this.request('GET', `${environment.serverUrl}/testimonials`);
  }

  getWhatwedoContents() {
    return this.request('GET', `${environment.serverUrl}/whatwedocontents`);
  }

  getWordkflow() {
    return this.request('GET', `${environment.serverUrl}/wordkflows`);
  }

  getTechnologyContents() {
    return this.request('GET', `${environment.serverUrl}/technology`);
  }

  getWhoweareContents() {
    return this.request('GET', `${environment.serverUrl}/whowearecontent`);
  }

  getpartner() {
    return this.request('GET', `${environment.serverUrl}/partner`);
  }

  getleadershipcontent() {
    return this.request('GET', `${environment.serverUrl}/leadershipcontent`);
  }

  getcareerscontent() {
    return this.request('GET', `${environment.serverUrl}/careerscontent`);
  }

  getrequirementscontent() {
    return this.request('GET', `${environment.serverUrl}/requirementscontent`);
  }

  getfaqcontent() {
    return this.request('GET', `${environment.serverUrl}/faqcontent`);
  }

  getFaqs() {
    return this.request('GET', `${environment.serverUrl}/faqs`);
  }

  gettermcontent() {
    return this.request('GET', `${environment.serverUrl}/termcontent`);
  }

  getTerms() {
    return this.request('GET', `${environment.serverUrl}/terms`);
  }

  getcontactcontent() {
    return this.request('GET', `${environment.serverUrl}/contactcontent`);
  }

  getHeaders() {
    return this.request('GET', `${environment.serverUrl}/headers`);
  }

  getFooters() {
    return this.request('GET', `${environment.serverUrl}/footers`);
  }

  // getBlogs() {
  //   return this.request('GET', `${environment.serverUrl}/blogs`);
  // }

  // createContactUs(contact) {
  //   return this.request('POST', `${environment.serverUrl}/contactus`, contact);
  // }

  // getGag(id) {
  //   return this.request('GET', `${environment.serverUrl}/gag/`+id);
  // }

  // likeGags(id) {
  //   return this.request('GET', `${environment.serverUrl}/gag-like/`+id);
  // }
  
}
