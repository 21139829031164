import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
declare let Email: any;

@Component({
  selector: 'app-buy-sell',
  templateUrl: './buy-sell.component.html',
  styleUrls: ['./buy-sell.component.css', '../../assets/Newwebfiles/style.css']
})
export class BuySellComponent implements OnInit {
  contents: any[] = [];

  angForm: FormGroup;
  status: any;
  angsecForm: FormGroup;
  statussec: any;

  constructor(private server: DataService, private fb: FormBuilder, private router: Router, private authservice: AuthService,private spinner:NgxSpinnerService) {
    this.createForm();
    this.createsecForm();
  }

  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
      ]],
      product: ['', Validators.required],
      city: ['', Validators.required],
    });
  }

  gotologin(){
    this.router.navigate(['/homepage/login']);
  }

  // email module added by Kunal bansal on 25 feb 2021 
  addProduct(name, phone, product, city) {
    this.authservice.apipost('WebQueryMail/', { 'name': name, 'phone': phone, 'product': product, 'city': city, 'form_type': 'sell' }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.status = 'Your request has been successfully submitted. we will contact you soon.';
        this.angForm.reset();
      }
      else {
        Swal.fire('Server down')
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Connect to the Internet')
    })
    // Email.send({
    //   Host: 'smtp.gmail.com',
    //   Username: 'naveen.sbinnovations@gmail.com',
    //   Password: 'yqlwqeuuijezuymf',
    //   To: 'business@eolstocks.com',
    //   From: 'naveen.sbinnovations@gmail.com',
    //   Subject: 'EOL - Want to Sell Query',
    //   Body: '<i>This is sent as a requirement from post your requirement page.</i><br> <br/> <b>Name: </b> ' + name + ' <br /> <b>Phone: </b>' + phone + '<br /><b>Product: </b>' + product + '<br /> <b>City:</b> ' + city + ' <br><br> <b>Team<br>EOL</b>'
    // }).then(
    //   message => {
    //     this.status = 'Your request has been successfully submitted. we will contact you soon.';
    //     this.angForm.reset();
    //   }
    // );

  }

  createsecForm() {
    this.angsecForm = this.fb.group({
      namez: ['', Validators.required],
      phonez: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
      ]],
      productz: ['', Validators.required],
      cityz: ['', Validators.required],
    });
  }

  
  // email module added by Kunal bansal on 25 feb 2021 
  addsecProduct(namez, phonez, productz, cityz) {
    this.authservice.apipost('WebQueryMail/', { 'name': namez, 'phone': phonez, 'product': productz, 'city': cityz, 'form_type': 'buy' }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.statussec = 'Your request has been successfully submitted. we will contact you soon.';
        this.angsecForm.reset();
      }
      else {
        Swal.fire('Server down')
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Connect to the Internet')
    })
    // Email.send({
    //   Host: 'smtp.gmail.com',
    //   Username: 'naveen.sbinnovations@gmail.com',
    //   Password: 'yqlwqeuuijezuymf',
    //   To: 'business@eolstocks.com',
    //   From: 'naveen.sbinnovations@gmail.com',
    //   Subject: 'EOL - Want to Buy Query',
    //   Body: '<i>This is sent as a requirement from post your requirement page.</i><br> <br/> <b>Name: </b> ' + namez + ' <br /> <b>Phone: </b>' + phonez + '<br /><b>Product: </b>' + productz + '<br /> <b>City:</b> ' + cityz + ' <br><br> <b>Team<br>EOL</b>'
    // }).then(
    //   message => {
    //     this.statussec = 'Your request has been successfully submitted. we will contact you soon.';
    //     this.angsecForm.reset();
    //   }
    // );

  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getrequirementscontent();
  }

  // Character binding module added for form fields by Kunal bansal on 01 march 2021
  validate(e){
    if((e.keyCode >= 65 && e.keyCode <= 90 ) || e.keyCode=="32" || e.keyCode=="08" ){
      
      return true
    }
    else {
      return false
    }
  
  }

  private getrequirementscontent() {
    this.server.getrequirementscontent().then((response: any) => {
      this.contents = response.data[0];
    });
  }

}
