import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner'
import { DataService } from '../data.service';
import Swal from 'sweetalert2';
declare let Email: any;

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css', '../../assets/Newwebfiles/style.css']
})
export class CareersComponent implements OnInit {
  contents: any = {};
  angForm: FormGroup;
  status: any;

  constructor(private server: DataService, private fb: FormBuilder, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.getcareerscontent();
    this.createForm();
  }

  // added by Kunal bansal on 07 may 2021 start
  gotosignup(){
    this.router.navigate(['/homepage/signup']);
  }
  // end

  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required],
      phone: ['', [
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.minLength(10),
      ]],
      areaofwork: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  // Email send new module added by Kunal bansal on 25feb2021
  addProduct(name, phone, areaofwork, description) {
    this.authservice.apipost('WebQueryMail/', { 'name': name, 'phone': phone, 'area_of_work': areaofwork, 'description': description, 'form_type': 'opportunities' }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.status = 'Your request has been successfully submitted. we will contact you soon.';
        this.angForm.reset();
      }
      else {
        Swal.fire('Server down')
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Connect to the Internet')
    }
    )
    // end
    //  alert('reached')
    //   Email.send({
    //     Host : 'smtp.gmail.com',
    //     Username : 'naveen.sbinnovations@gmail.com',
    //     Password : 'yqlwqeuuijezuymf',
    //     To : 'business@eolstocks.com',
    //     From : 'naveen.sbinnovations@gmail.com',
    //     Subject : 'EOL - Want to Sell Query',
    //     Body : '<i>This is sent as a requirement from post your requirement page.</i><br> <br/> <b>Name: </b> '+name+' <br /> <b>Phone: </b>'+phone+'<br /><b>Area of Work: </b>'+areaofwork+'<br /> <b>Description:</b> '+description+' <br><br> <b>Team<br>EOL</b>'
    //   }).then( 
    //     message => 
    //     {
    //       this.status = 'Your request has been successfully submitted. we will contact you soon.'; 
    //       this.angForm.reset(); 
    //     } 
    //   );

  }

  private getcareerscontent() {
    this.server.getcareerscontent().then((response: any) => {
      this.contents = response.data[0];
    });
  }

}
