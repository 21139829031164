import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';

@Component({
  selector: 'app-inventory-pending',
  templateUrl: './inventory-pending.component.html',
  styleUrls: ['./inventory-pending.component.css']
})

export class InventoryPendingComponent implements OnInit {
  inventory: any[];
  totalcount: any;
  flag: number = 1;
  category: any[];
  filter = [];
  selectedprodname = [];
  selectedprodtype = [];

  dropdownSettings = {};
  dropdownSettings2 = {};
  selectedItems2: any[];
  selectedItems3: any[];
  selectedItems4: any[];
  brands: any[];
  temp: number;
  temp2: number = 0;
  checkradio: number = 0;
  faq: any[];
  checkedsel: number = 1;
  pushbrandid = [];
  prodname: any[];
  producttype = [];
  offset: number = 1;
  inventoryactive: number = 0;

  showcategory = [];
  selectactive: number = 1;

  Appliedcatfilters = [];
  duplicatecatfilters = [];
  Appliedbrandfilter = [];

  Appliedproductname = [];

  Appliedproducttype = [];

  showlabel: number = 0;
  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  Applieddatefrom = [];
  Applieddateto = [];
  pickupdatefrom: string = "";
  pickupdateto: string = "";
  tomindate: string = "";
  frommaxdate: string = "";
  tomaxdate: string = "";
  // end
  manualsearchfield: string;
  // <!-- phase 4 cr payment option filteration module added by Kunal bansal on 15 july 2021 start -->
  paymentmodule = [];
  paymentlisting: any[];
  // end
  // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
  selectedpayment = [];
  selectedpaymentlabels = [];
  Appliedpaymentlabels = [];
  Applieddatelabelfrom = [];
  Applieddatelabelto = [];


  constructor(private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) { }

  // pending tab pagination issue fixed and new pagination module added by Kunal bansal on 02 sep 2020

  @HostListener("window:scroll", ["$event"])

  onWindowScroll(e) {

    if (($(window).scrollTop() + $(window).innerHeight()) >= $(document).height() - this.getScrollbarWidth()) {
      if (this.inventory.length < this.totalcount && this.inventoryactive == 0) {
        this.getList(this.flag)
      }
      else {
        if (this.inventory.length < this.totalcount) {
          this.Applyfilter()
        }
      }
    }
  }

  getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  }

  // @HostListener("window:scroll", ["$event"])

  // onWindowScroll(e) {
  // //In chrome and some browser scroll is given to body tag
  // let pos = document.documentElement.scrollTop  + document.documentElement.offsetHeight;
  //   let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   let max1 = document.body.scrollHeight-10;
  //   //console.log(Math.round(pos) +" == "+ max +" and "+ Math.round(pos1) +" == "+ max1)
  //   if(Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1)   {
  //     if(this.inventory.length < this.totalcount ){
  //       this.getList(this.flag)
  //     }
  //   }
  // }

  ngOnInit() {
    this.getList(1)
    // this.producttype.push({
    //   "name": "Feature Phone", id: 1
    // }, {
    //   "name": "Smart Phone", id: 2
    // }
    // )
    this.dropdownSettings = {
      singleSelection: true,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",

      labelKey: "category_name",
      classes: "font_drop",
      maxHeight: 200


    };
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      enableCheckAll: false,
      text: "Select",

      labelKey: "name",
      classes: "font_drop",
      maxHeight: 200


    };
    // <!-- phase 4 cr payment option filteration module added by Kunal bansal on 15 july 2021 start -->
    this.paymentmodule = [{ "name": "Online Payment", id: 2 }, { "name": "COD", id: 1 }, { "name": "Partial Payment", id: 3 }];
    // end
  }

  // <!-- /* phase 4 new cr search bar in inventory code styling added by Kunal bansal on 18 june 2021 start */ -->
  manualsearch() {

    this.offset = 1;
    this.authservice.apipost('inventory/', {
      "inventory_flag": 0,
      "offset": this.offset,
      "search": this.manualsearchfield
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.duplicatecatfilters = [];
        this.Applieddatefrom = [];
        this.Applieddateto = [];
        this.Appliedbrandfilter = [];
        this.Appliedproductname = [];
        this.Appliedproducttype = [];

        if (this.offset == 1) {
          this.inventory = response.response.final_list;
        }
        else {
          this.inventory = this.inventory.concat(response.response.final_list);
        }
        this.totalcount = response.response.total_count;
        this.manualsearchfield = "";



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("Connect to the Internet")
        Swal.fire("Network Error")
      }
    )
    // this.listing=[];
  }

  // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
  paymentselection(event) {
    if (this.selectedpayment.includes(event.id)) {

    }
    else {
      this.selectedpayment.push(event.id);
      this.selectedpaymentlabels.push({ "name": event.name });
    }
    console.log(this.selectedpaymentlabels);
  }

  paymentDeselection(event) {
    let find = this.selectedpayment.indexOf(event.id);
    this.selectedpayment.splice(find, 1);
    this.selectedpaymentlabels.splice(find, 1);
    console.log(this.selectedpaymentlabels);
  }

  // end

  // New module adding  flilter in inventory by Kunal bansal on 05 march 2021 start
  fromdate(event) {

    var fromdate = event.target.value.split('-').reverse().join('/');
    this.Applieddatefrom = fromdate;
    // added by Kunal bansal on 02feb2021 


    this.tomindate = event.target.value;






    // alert(event.target.value)
    // alert(newdate)


  }

  // end



  todate(event) {
    if (this.pickupdatefrom.length == 0) {
      Swal.fire("please select upper date first")
      this.pickupdateto = "";
    }
    else {

    }
    var todate = event.target.value.split('-').reverse().join('/');
    this.Applieddateto = todate;
  }

  // end


  // <!-- adding Filter module on 02 sep 2021 by Kunal bansal -->
  opencategorymodal(shopbycategory) {
    this.offset = 1;
    // New module adding  flilter in inventory by Kunal bansal on 05 march 2021 start
    this.pickupdatefrom = "";
    this.pickupdateto = "";
    this.tomindate = "";
    // end
    this.producttype = [];
    this.selectactive = 1;
    this.selectedItems2 = [];
    this.selectedItems3 = [];
    this.selectedItems4 = [];
    this.showcategory = [];
    this.brands = [];
    this.prodname = [];
    this.pushbrandid = [];
    this.selectedprodname = [];
    this.selectedprodtype = [];

    this.temp = undefined;
    this.temp2 = undefined;
    this.checkradio = 0;
    this.filter = [];
    this.checkedsel = 1;
    //  phase 3 new cr adding date filter in inventory on 05 march 2021 start
    var dateobj = new Date();
    var month = dateobj.getUTCMonth() + 1;
    var date = dateobj.getUTCDate();
    var year = dateobj.getUTCFullYear();
    if (month.toString().length == 1 && date.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 2 && date.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 1 && date.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else {
      var fulldate = year + "-" + month + "-" + date;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    // end
    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });

    this.authservice.apiget('static_Filter_Data/?rtype=all_brand').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        if (this.filter.length == 0) {
          this.brands = [];
          this.prodname = [];
          this.selectedItems2 = [];
          this.selectedItems3 = [];
        }

      }
      else if (response.status == 200) {
        // this.selectedItems2 = [];

        this.brands = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();

        Swal.fire("Network Error")
      }

    )

    this.authservice.apipost('CategoryList/', {
      "flag": "filtercatgory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        this.category = response.response;
        this.faq = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("ERROR : " + err)
        Swal.fire("Network Error")
      }
    )
    this.authservice.apipost('static_Filter_Data/?rtype=product_type', {

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // this.modalService.dismissAll();
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.producttype = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("ERROR : " + err)
        Swal.fire("Network Error")
      }
    )


  }

  categories(item: any) {
    // alert(item.id)
    this.authservice.apipost('CategoryBrandList/', {
      category: item.id

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.brands = response.response;

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
  }

  Bybrand(item: any) {
    this.pushbrandid.push(item.id);
    // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
    //  this.Appliedbrandfilter.push(item)
    // end
    this.authservice.apipost('static_Filter_Data/?rtype=product', {
      "category": this.filter,
      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // this.modalService.dismissAll();
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.prodname = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
    this.authservice.apipost('static_Filter_Data/?rtype=product_type', {

      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {

        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.producttype = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
  }

  removebrand(item: any) {
    var orno = this.pushbrandid.indexOf(item.ordernumber);
    var findbrand = this.Appliedbrandfilter.indexOf(item);
    this.Appliedbrandfilter.splice(findbrand, 1)
    this.pushbrandid.splice(orno, 1)
    this.authservice.apipost('static_Filter_Data/?rtype=product', {
      "category": this.filter,
      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // this.modalService.dismissAll();
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.prodname = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
    this.authservice.apipost('static_Filter_Data/?rtype=product_type', {
      "brand": this.pushbrandid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // this.modalService.dismissAll();
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedItems2=[];
        this.producttype = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("ERROR : " + err)
        Swal.fire("Network Error")
      }
    )

  }

  closeall() {
    this.modalService.dismissAll();
  }

  Byproductname(item: any) {
    this.selectedprodname.push(item.name);
    // this.Appliedproductname.push(item);


  }

  removeproductname(item: any) {
    var prname = this.selectedprodname.indexOf(item.name);

    this.selectedprodname.splice(prname, 1)
    let productpo = this.Appliedproductname.indexOf(item);
    this.Appliedproductname.splice(productpo, 1)
  }

  Byproducttype(item: any) {
    this.selectedprodtype.push(item.name);
    // this.Appliedproducttype.push(item);

  }

  removeproducttype(item: any) {
    var prtype = this.selectedprodtype.indexOf(item.name);

    this.selectedprodtype.splice(prtype, 1);
    let producttypepos = this.Appliedproducttype.indexOf(item);
    this.Appliedproducttype.splice(producttypepos, 1)
  }
  removeshowcategory(s, idchild, parent, child) {


    let index = this.filter.indexOf(idchild)

    this.Appliedcatfilters.splice(s, 1);
    this.filter.splice(index, 1)

    this.showcategory.splice(s, 1)


    this.category[parent].child_category[child].checked = false;


    this.authservice.apipost('static_Filter_Data/?rtype=brand', {
      "category": this.filter
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        if (this.filter.length == 0) {
          this.brands = [];
          this.prodname = [];
          this.selectedItems2 = [];
          this.selectedItems3 = [];
        }

        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems2 = [];

        this.brands = response.response;



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )



  }

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  commoninventoryfilter() {
    if (this.filter.length == 0 && this.pushbrandid.length == 0 && this.selectedprodname.length == 0 && this.selectedprodtype.length == 0) {
      this.getList(1)
    }
    else {
      this.authservice.apipost('inventoryFilter/', {
        "platform": 0,
        "tab": 0,
        "offset": this.offset,
        "category_id": this.filter,
        "brand": this.pushbrandid,
        "product": this.selectedprodname,
        "product_type": this.selectedprodtype,
         // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
         "payment_mode": this.selectedpayment

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          // this.filter=[];
          this.modalService.dismissAll();
          if (this.offset == 1) {
            this.inventory = response.response.inventory_list[0];
          }
          else {
            this.inventory = this.inventory.concat(response.response.inventory_list[0]);
          }
          this.totalcount = response.response.total_count;
           // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
           this.selectedpayment = [];
           this.paymentlisting = [];
           //  end
          this.inventoryactive = 1;
          this.offset = this.offset + 1;
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )

    }
    // }
  }
  //  end


  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removecatfilter(index, id) {

    this.offset = 1;
    this.Appliedcatfilters.splice(index, 1)
    let k = this.filter.indexOf(id);
    this.filter.splice(k, 1)
    this.commoninventoryfilter()
  }
  //  end


  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeappliedbrandfilter(index, id) {
    this.offset = 1;
    this.Appliedbrandfilter.splice(index, 1)

    let v = this.pushbrandid.indexOf(id);
    this.pushbrandid.splice(v, 1)
    this.commoninventoryfilter()
  }
  //  end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeappliedproductname(index, id) {
    this.offset = 1;
    this.Appliedproductname.splice(index, 1)
    let z = this.selectedprodname.indexOf(id);
    this.selectedprodname.splice(z, 1)
    this.commoninventoryfilter()
  }
  //  end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeappliedproducttype(index, id) {

    this.offset = 1;
    this.Appliedproducttype.splice(index, 1)
    let y = this.selectedprodtype.indexOf(id);
    this.selectedprodtype.splice(y, 1)
    this.commoninventoryfilter()
  }
  //  end

  toggleVisibility(e, a, catname, parindex, chindex) {


    var index2 = this.filter.indexOf(a);


    if (e.target.checked == true) {
      this.filter.push(a)
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      if (this.Appliedcatfilters.includes(catname)) {

      }
      else {
        this.Appliedcatfilters.push({ 'id': a, 'name': catname });

      }
      // end

      this.showcategory.push({ 'name': catname, "id": a, "parindex": parindex, "chiindex": chindex })

      this.authservice.apipost('static_Filter_Data/?rtype=brand', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // this.modalService.dismissAll();
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.brands = response.response;




        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )

      this.authservice.apipost('static_Filter_Data/?rtype=product_type', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // this.modalService.dismissAll();
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.producttype = response.response;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("ERROR : " + err)
          Swal.fire("Network Error")
        }
      )


    }
    else {
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      var element2 = this.Appliedcatfilters.indexOf(catname);
      this.Appliedcatfilters.splice(element2, 1)
      // end
      for (let index = 0; index < this.showcategory.length; index++) {
        var element = this.showcategory[index].name;
        if (element == catname) {
          this.showcategory.splice(index, 1)

        }
      }
      this.filter.splice(index2, 1)
      this.authservice.apipost('static_Filter_Data/?rtype=brand', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // this.modalService.dismissAll();
          // Swal.fire(response.message)
          if (this.filter.length == 0) {
            this.brands = [];
            this.prodname = [];
            this.selectedItems2 = [];
            this.selectedItems3 = [];
          }
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.brands = response.response;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )

      this.authservice.apipost('static_Filter_Data/?rtype=product_type', {
        "category": this.filter
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // this.modalService.dismissAll();
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.selectedItems2=[];
          this.producttype = response.response;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("ERROR : " + err)
          Swal.fire("Network Error")
        }
      )



    }



  }
  Applyfilter() {
    // if (this.filter.length == 0) {
    //   Swal.fire("Select at least one category filter cannot be empty")
    // }
    // else {

    var fromdate = this.pickupdatefrom.split('-').reverse().join('/');
    var todate = this.pickupdateto.split('-').reverse().join('/');
    if (this.pickupdateto.length == 0 && this.pickupdatefrom.length != 0) {
      Swal.fire("Please select To Date")
    }
    else {
      this.authservice.apipost('inventoryFilter/', {
        "platform": 0,
        "tab": 0,
        "offset": this.offset,
        "category_id": this.filter,
        "date_range": this.pickupdatefrom.length != 0 && this.pickupdateto.length != 0 ? fromdate + "-" + todate : "",
        "brand": this.pushbrandid,
        "product": this.selectedprodname,
        "product_type": this.selectedprodtype,
         // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
         "payment_mode": this.selectedpayment

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.duplicatecatfilters = this.Appliedcatfilters;
          this.Appliedcatfilters = [];
          this.Appliedbrandfilter = this.selectedItems2;
          this.Appliedproductname = this.selectedItems3;
          this.Appliedproducttype = this.selectedItems4;
            // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 29 july 2021 start
            
            this.paymentlisting = [];
            this.selectedpayment = [];
            this.Appliedpaymentlabels = this.selectedpaymentlabels;
            this.Applieddatelabelfrom=this.Applieddatefrom;
            this.Applieddatelabelto=this.Applieddateto;
    
            this.selectedpaymentlabels = [];
            this.Applieddatefrom=[];
            this.Applieddateto=[];
            //  end
          this.showlabel = 1;

          // this.filter=[];
          this.modalService.dismissAll();
          if (this.offset == 1) {
            this.inventory = response.response.inventory_list[0];
          }
          else {
            this.inventory = this.inventory.concat(response.response.inventory_list[0]);
          }
          this.totalcount = response.response.total_count;
          this.inventoryactive = 1;
          this.offset = this.offset + 1;
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("ERROR : " + err)
        }
      )
    }
    // }
  }

  selectall(a) {
    this.selectactive = 0;
    for (let index = 0; index < this.category.length; index++) {
      for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {

        if (this.filter.includes(this.category[index].child_category[Innerindex].id)) {
        }
        else {

          this.filter.push(this.category[index].child_category[Innerindex].id);
          this.Appliedcatfilters.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id });
          this.showcategory.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id, 'parindex': index, 'chiindex': Innerindex });
          //  $(".yup[type='checkbox']").prop('checked', true);
          for (let i = 0; i < this.category.length; i++) {
            for (let k = 0; k < this.category[i].child_category.length; k++) {
              this.category[i].child_category[k].checked = true;
            }

          }
        }
      }
    }
    //  this.authservice.apipost('static_Filter_Data/?rtype=brand', {
    //   "category":this.filter
    // }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     if(this.filter.length==0){
    //       this.brands=[];
    //       this.prodname=[];
    //       this.selectedItems2=[];
    //       this.selectedItems3=[];
    //                 }
    //     // this.modalService.dismissAll();
    //     // Swal.fire(response.message)
    //   }
    //   else if (response.status == 200) {
    //     this.selectedItems2=[];
    //     // this.selectedItems2=[];
    //    this.brands=response.response;



    //   }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();
    //     // Swal.fire("ERROR : " + err)
    //     Swal.fire("Network Error")
    //   }
    // )
    this.temp2 = 1;
    this.temp = 1;
  }

  Deselectall() {

    // $(".yup[type='checkbox']").prop('checked', false);
    for (let i = 0; i < this.category.length; i++) {
      for (let k = 0; k < this.category[i].child_category.length; k++) {
        this.category[i].child_category[k].checked = false;
      }

    }
    this.selectactive = 1;
    this.Appliedcatfilters = [];
    this.filter = [];
    this.showcategory = [];
    //  this.authservice.apipost('static_Filter_Data/?rtype=brand', {
    //   "category":this.filter
    // }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     if(this.filter.length==0){
    //       this.brands=[];
    //       this.prodname=[];
    //       this.selectedItems2=[];
    //       this.selectedItems3=[];
    //                 }
    //     // this.modalService.dismissAll();
    //     // Swal.fire(response.message)
    //   }
    //   else if (response.status == 200) {
    //     this.selectedItems2=[];
    //     // this.selectedItems2=[];
    //    this.brands=response.response;



    //   }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();
    //     // Swal.fire("ERROR : " + err)
    //     Swal.fire("Network Error")
    //   }
    // )
    // this.temp=undefined;
    // this.temp2=undefined;
  }

  openfaq(i) {
    this.checkradio = 5;
    if (this.temp == i) {
      this.temp = undefined;
    }
    else {


      this.temp = i

    }
  }

  onOpen() {
    this.checkradio = 0;
    this.temp2 = undefined;
    this.temp = undefined;
    // if (this.filter.length == 0) {
    //   Swal.fire('Please select Category First')
    // }

  }
  onOpenproduct() {
    // if (this.pushbrandid.length == 0) {
    //   Swal.fire('Please select Brand First')
    // }
  }

  openfaq2(c) {
    // this.temp=undefined;
    // this.temp2 = undefined;
    if (this.temp2 == c) {
      this.checkradio = 0;
      this.temp = undefined;
      this.temp2 = undefined;
    }
    else {

      this.checkradio = 5;
      this.temp2 = 1;

    }

  }
  Removefilter() {

    this.showcategory = [];
    this.filter = [];
    this.selectedItems2 = [];
    this.selectedItems3 = [];
    this.selectedItems4 = [];
    this.brands = [];
    this.prodname = [];
    this.producttype = [];

    $(".yup[type='checkbox']").prop('checked', false);
    location.reload();
  }


  getList(flag) {
    this.authservice.apipost('inventory/', {
      "inventory_flag": 0,
      "offset": flag
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.inventoryactive = 0;
        if (flag == 1) {
          this.inventory = response.response.final_list;
          this.totalcount = response.response.total_count
        }
        else {
          this.inventory = this.inventory.concat(response.response.final_list)
        }
        this.flag = this.flag + 1
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("ERROR : " + err)
      }
    )
  }

}
