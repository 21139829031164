import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { endTimeRange } from '@angular/core/src/profile/wtf_impl';
import { clear } from 'console';

let myVarsecond;
let myVar;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})



export class LoginComponent implements OnInit {

  log: any = '0';
  loginForm: FormGroup;
  otp: any = '0';
  productdetail: string;

  passwordchange: number = 0;

  // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
  otpvisible: boolean = false;
  otptimerminute: any;
  permanentotptimestore: any;
  otptimersecond: any = 60;
  otpbuttondisabled = false;


  // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 12 aug 2021 start........ -->
  borderactive: number = 0;
  selectedcountry: string = "India";
  // ......end.......
  regphonenumber: number;

  constructor(private storage: SessionStorageService, private router: Router, private formBuilder: FormBuilder, private authservice: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      phonenumber: ['', Validators.maxLength(50)],
      password: ['', Validators.maxLength(15)],
      mobile: ['', Validators.maxLength(10)]
    });
    this.productdetail = sessionStorage.getItem("pageurl");


  }

  ngOnDestroy() {
    clearInterval(myVar);
    clearInterval(myVarsecond);
  }

  // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 12 aug 2021 start........ -->
  borderactivate(a) {
    if (a == 1) {
      console.log('focus');
      this.borderactive = 1;
    }
    else {
      console.log("focus out");
      this.borderactive = 0;
    }
  }

  changecountry(event) {
    console.log(event.target.value)
    this.selectedcountry = event.target.value;
    this.loginForm.patchValue({
      mobile: ''
    });
    this.otp = '0';
    clearInterval(myVar);
    clearInterval(myVarsecond);
    this.otpvisible = false;

  }

  // ..............end.............


  // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
  // setotptimer() {
  //   myVar = setInterval(() => {
  //     if (this.otptimerminute <= 10) {
  //       let otpminute;

  //       otpminute = this.otptimerminute - 1;
  //       this.otptimerminute = "0" + otpminute;
  //     }
  //     else {
  //       this.otptimerminute = this.otptimerminute - 1;
  //     }

  //     if (this.otptimerminute == 0) {
  //       clearInterval(myVar);
  //     }
  //   }, 60000);

  // }

  lastseconds() {

    myVarsecond = setInterval(() => {


      if (this.otptimersecond <= 10) {
        let otp;

        otp = this.otptimersecond - 1;
        this.otptimersecond = "0" + otp;
      }
      else {
        this.otptimersecond = this.otptimersecond - 1;
      }
      if (this.otptimersecond == 0) {




        this.otptimersecond = "0" + 0;
        clearInterval(myVarsecond);
        this.otpbuttondisabled = false;





      }
    }, 1000);

  }

  setotptimersecond() {
    myVarsecond = setInterval(() => {


      if (this.otptimersecond <= 10) {
        let otp;

        otp = this.otptimersecond - 1;
        this.otptimersecond = "0" + otp;
      }
      else {
        this.otptimersecond = this.otptimersecond - 1;
      }
      if (this.otptimersecond == 0) {
        if (this.otptimerminute == 0) {
          // this.otptimersecond = 60;
          // setTimeout(() => {

          this.otptimersecond = "0" + 0;
          clearInterval(myVarsecond);
          this.otpbuttondisabled = false;
          // }, 60000);


        }
        else {
          this.otptimersecond = 60;
          if (this.otptimerminute <= 10) {
            let otpminute;

            otpminute = this.otptimerminute - 1;
            this.otptimerminute = "0" + otpminute;
          }
          else {
            this.otptimerminute = this.otptimerminute - 1;
          }
        }


      }
    }, 1000);
  }

  generateotp() {

    // this.otptimerminute = this.permanentotptimestore;
    this.otpvisible = true;
    this.otpbuttondisabled = true;
    // if (this.otptimerminute == 0) {
    //   this.otptimersecond = 60;
    //   this.lastseconds();
    // }
    // else {
    //   this.setotptimer();
    //   this.setotptimersecond();
    // }
    this.authservice.apipost('loginWithOtp/', {
      // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->s
      phone: this.selectedcountry == 'India' ? JSON.stringify(this.regphonenumber) : "",//8968357570
      otp: "",
      device_token: "0",
      device_type: "0",
      email: this.loginForm.value.phonenumber,
      // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
      country: this.selectedcountry
      // ...end...
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.otp = '1';
        if (this.selectedcountry == "UAE") {
          Swal.fire('OTP has been sent to your email address')
        }
        else {
          Swal.fire('OTP has been sent to your Email address and Mobile number')
        }


        $(document).ready(function () {
          $('.x').keypress(function (evt) {
            if (evt.which != 8) {
              if (this.id == "first") {
                $('#second').focus()
              }
              else if (this.id == "second") {
                $('#third').focus()
              }
              else if (this.id == "third") {
                $('#fourth').focus()
              }
              else if (this.id == "fourth") {
                //$('#fifth').focus()
              }
            }
          })
        })
        // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
        clearInterval(myVar);
        clearInterval(myVarsecond);
        if (response.expiry_time == 1) {

          this.otptimerminute = "00";
          this.permanentotptimestore = 0;
          this.otptimersecond = 60;
          this.otpvisible = true;
          this.otpbuttondisabled = true;
          this.lastseconds();
        }
        else {
          let concatminute = response.expiry_time - 1;
          if (response.expiry_time < 10) {
            this.otptimerminute = "0" + concatminute;
            this.permanentotptimestore = "0" + concatminute;
          }
          else {
            this.otptimerminute = response.expiry_time - 1;
            this.permanentotptimestore = response.expiry_time - 1;
          }
        
          if(response.expiry_time==0){

          }
          else{
            this.otptimersecond = 60;

            this.otpvisible = true;
            this.otpbuttondisabled = true;
  
            // this.setotptimer();
            this.setotptimersecond();
          }
         
        }

        // ...end...


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }
  // ...end....


  login({ value, valid }) {
    this.regphonenumber = value.mobile;
    // commented on 02 june 2020 by kunal start
    // var filter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,}$/;
    // end
    // added by kunal on 02 june 2020 by kunal start
    var filter = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
    // end
    if (this.log == '0') {
      if (JSON.stringify(value.phonenumber).length == 0 || $.trim(value.phonenumber).length == 0) {
        Swal.fire("Please enter a valid Mobile number or email id")
      }
      else if (JSON.stringify(value.password).length == 0 || $.trim(value.password).length == 0) {
        Swal.fire("Please enter your Password")
      }
      else if (JSON.stringify(value.password).length < 8 || filter.test(value.password) == false || value.password.length > 15) {
        Swal.fire("Please enter a valid Password")
      }
      else {
        this.authservice.apipost('login/', {
          username: value.phonenumber, //Abhin332@gmail.com
          password: value.password, //Abhi@1997
          device_token: "0",
          device_type: "0"
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            this.storage.store("username", response.response.username);
            this.storage.store("userid", response.response.id);
            this.storage.store("token", response.response.token);
            this.storage.store("islogged", 1);
            //  Added by Kunal bansal on 06 july 2021 start
            if (this.productdetail == null) {
              this.router.navigate(['/homepage/productListing'], { replaceUrl: true });
            }
            else {
              window.open(this.productdetail, "_self")
            }
            // end
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      }
    }
    else if (this.log == '1') {
      // <!-- phase 5 module country email regex validation added by Kunal bansal on 24 aug 2021 start -->
      var emailregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.otp == '0') {
        if (JSON.stringify(value.mobile).length == 0 || $.trim(value.mobile).length == 0 && JSON.stringify(value.mobile).length != 10 && this.selectedcountry == 'India') {
          Swal.fire("Please enter a valid Mobile number")
        }
        // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
        else if (emailregex.test(this.loginForm.value.phonenumber) == false && this.selectedcountry == 'UAE' && this.loginForm.value.phonenumber.trim() == "") {
          Swal.fire("Please enter a valid  email id")
        }

        else {
          this.authservice.apipost('loginWithOtp/', {
            // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->s
            phone: this.selectedcountry == 'India' ? JSON.stringify(value.mobile) : "",//8968357570
            otp: "",
            device_token: "0",
            device_type: "0",
            email: this.loginForm.value.phonenumber,
            // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
            country: this.selectedcountry
            // ...end...
          }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message)
            }
            else if (response.status == 200) {
              this.otp = '1';
              if (this.selectedcountry == "UAE") {
                Swal.fire('OTP has been sent to your email address')
              }
              else {
                Swal.fire('OTP has been sent to your Email address and Mobile number')
              }


              $(document).ready(function () {
                $('.x').keypress(function (evt) {
                  if (evt.which != 8) {
                    if (this.id == "first") {
                      $('#second').focus()
                    }
                    else if (this.id == "second") {
                      $('#third').focus()
                    }
                    else if (this.id == "third") {
                      $('#fourth').focus()
                    }
                    else if (this.id == "fourth") {
                      //$('#fifth').focus()
                    }
                  }
                })
              })
              // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
              clearInterval(myVar);
              clearInterval(myVarsecond);
              if (response.expiry_time == 1) {
                this.otptimerminute = "00";
                this.permanentotptimestore = 0;
                this.otptimersecond = 60;
                this.otpvisible = true;
                this.otpbuttondisabled = true;
                this.lastseconds();
              }
              else {
                let concatminute = response.expiry_time - 1;
                if (response.expiry_time < 10) {
                  this.otptimerminute = "0" + concatminute;
                  this.permanentotptimestore = "0" + concatminute;
                }
                else {
                  this.otptimerminute = response.expiry_time - 1;
                  this.permanentotptimestore = response.expiry_time - 1;
                }
                if(response.expiry_time==0){

                }
                else{
                  this.otptimersecond = 60;
      
                  this.otpvisible = true;
                  this.otpbuttondisabled = true;
        
                  // this.setotptimer();
                  this.setotptimersecond();
                }
              }

              // ...end...


            }
            else { Swal.fire("Server Down") }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet")
            }
          )
        }
      }
      else if (this.otp == '1') {

        if ($('#first').val().length == 0 && $('#second').val().length == 0 && $('#third').val().length == 0 && $('#fourth').val().length == 0) {
          Swal.fire("Please enter a valid OTP")
        }
        else {
          let emailotp = $('#first').val() + "" + $('#second').val() + "" + $('#third').val() + "" + $('#fourth').val();
          this.authservice.apipost('loginWithOtp/', {
            // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->s
            phone: this.selectedcountry == 'India' ? JSON.stringify(value.mobile) : "",//8968357570
            otp: emailotp,
            device_token: "0",
            device_type: "0",
            email: this.loginForm.value.phonenumber,
            country: this.selectedcountry
          }).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message)
            }
            else if (response.status == 200) {
              this.storage.store("username", response.response.username);
              this.storage.store("userid", response.response.id);
              this.storage.store("token", response.response.token);
              this.storage.store("islogged", 1);

              //  Added by Kunal bansal on 06 july 2021 start
              if (this.productdetail == null) {
                this.router.navigate(['/homepage/productListing'], { replaceUrl: true });
              }
              else {
                window.open(this.productdetail, "_self")
              }
              // end
              // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
              if (response.expiry_time == 1) {
                this.otptimerminute = "00";
                this.permanentotptimestore = 0;
                this.otptimersecond = 60;
                this.otpvisible = true;
                this.otpbuttondisabled = true;
                this.lastseconds();
              }
              else {
                let concatminute = response.expiry_time - 1;
                if (response.expiry_time < 10) {
                  this.otptimerminute = "0" + concatminute;
                  this.permanentotptimestore = "0" + concatminute;
                }
                else {
                  this.otptimerminute = response.expiry_time - 1;
                  this.permanentotptimestore = response.expiry_time - 1;
                }
                if(response.expiry_time==0){

                }
                else{
                  this.otptimersecond = 60;
      
                  this.otpvisible = true;
                  this.otpbuttondisabled = true;
        
                  // this.setotptimer();
                  this.setotptimersecond();
                }
              }
              // ...end...

            }
            else { Swal.fire("Server Down") }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet")
            }
          )
        }
      }
    }
  }

  // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 10 nov 2021 start -->
  automaticmoveonestep(i, event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
      if (i == 1) {
        $("#second").focus();

      }
      else if (i == 2) {
        $("#third").focus();

      }
      else if (i == 3) {
        $("#fourth").focus();


      }
      else {

      }


    }
    else {
      return false
    }

    // if (i == 4) {
    //   if (event.code == "Backspace") {
    //     $("#third").focus();
    //   }
    // }


  }
  // ...end...

  // phase 5 space disable added by Kunal bansal on 21 sep 2021 start
  disablespacebar(event) {
    if (event.keyCode == 32) {
      return false
    }
    else {
      return true
    }
  }
  // ...end...

  preventnumberval() {
    $(document).ready(function () {
      $('form').on('blur', 'input[type=number]', function (e) {
        $(this).off('mousewheel.disableScroll')
      })

      $("input[type=number]").keypress(function (evt) {
        if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
          if (evt.which == 8 || evt.which == 0) { }
          else { evt.preventDefault(); }
        }
      })
    });
  }

  handleChange(event) {

    if (this.log == '0') {
      this.log = '1';
      // phase 5 add otp timer module added by Kunal bansal on 13 sep 2021 start -->
      // this.otpvisible=false;
      // this.otpbuttondisabled=true;
      // clearInterval(myVar);
      // clearInterval(myVarsecond)
      // ...end...
      this.preventnumberval()
    }
    else {
      this.log = '0';
    }
  }


}