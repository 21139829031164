import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
})
// phase 5 service creatd by Kunal bansal to fetch pobox country based on po number on 12 aug 2021
export class Uaepoboxes {
    // uaepoboxes = [{ 'country': 'Abu Dhabi Central', no: 111188 },
    // { 'country': 'Dubai Central Karama', no: 333388 },
    // { 'country': 'Sharjah Central', no: 555588 },
    // { 'country': 'Ajman Central', no: 666688 },
    // { 'country': 'Umm Al Quwain Central', no: 777788 },
    // { 'country': 'Fujairah Central', no: 888888 },
    // { 'country': 'Ras Al Khaimah Central', no: 999988 }]
    uaepoboxes = [{
        'country': 'Abu Dhabi Emirate', no: 111188, 'cities': ["Abu Dhabi Island and Internal Islands City", "Abu Dhabi Municipality", "Al Ain City",
            "Al Ain Municipality", "Al Dhafra", "Al Shamkhah City", "Ar Ruways", "Bani Yas City", "Khalifah A City", "Musaffah", "Muzayri", "Zayed City"]
    },
    { 'country': 'Dubai', no: 333388, 'cities': ["Dubai"] },
    {
        'country': 'Sharjah Emirate', no: 555588, 'cities': ["Adh Dhayd", "Al Batayih", "Al Hamriyah", "Al Madam", "Dhaid", "Dibba Al Hesn",
            "Kalba", "Khawr Fakkan", "Khor Fakkan", "Milehah", "Murbaḩ", "Sharjah"]
    },
    { 'country': 'Ajman Emirate', no: 666688, 'cities': ["Ajman", "Ajman City", "Manama", "Masfout"] },
    { 'country': 'Umm al-Quwain', no: 777788, 'cities': ["Umm AL Quwain", "Umm Al Quwain City"] },
    {
        'country': 'Fujairah', no: 888888, 'cities': ["Al Fujairah City", "Al Fujairah Municipality", "Dibba Al Fujairah Municipality",
            "Dibba Al-Fujairah", "Dibba Al-Hisn", "Reef Al Fujairah City"]
    },
    { 'country': 'Ras al-Khaimah', no: 999988, 'cities': ["Ras Al Khaimah", "Ras Al Khaimah City"] }]


    Freezonecities = ["Abu Dhabi Airport Business City", "Abu Dhabi Media", "Masdar City", "Khalifa Industrial Zone Abu Dhabi", "Dubai CommerCity",
        "Dubai Internet City", "Dubai Media City", "Dubai Production City", "Dubai Studio City", "Dubai Outsource City", "Dubai Knowledge Park",
        "Dubai International Academic City", "Dubai Science Park", "Dubai Design District", "Dubai Multi Commodities Centre", "Jumeirah Lakes Towers",
        "Jebel Ali Free Zone", "Dubai Airport Free Zone", "Dubai Silicon Oasis", "Dubai HealthCare City", "International Humanitarian City", "Dubai Maritime City",
        "Gold and Diamond Park", "Dubai South", "Dubai International Financial Centre", "Meydan", "National Industries Park", "Sharjah Airport International", "Hamriyah Free Zone",
        "Sharjah Media City", "Ras Al Khaimah", "Ajman", "Ajman Media", "Umm Al Quwain", "Fujairah Free Zone Authority", "International Free Zone Authority", "Creative City"]

    constructor() {

    }

    Getponumber(number) {


        for (let u = 0; u < this.uaepoboxes.length; u++) {


            try {
                if (this.uaepoboxes[u].no == number) {

                    return [{ "response": this.uaepoboxes[u], status: 200 }];
                }
            }

            catch (err) {

            }


        }

    }

    //phase 5 added by Kunal bansal on 02 nov 2021 due to new scenario of pobox autofetch reklation break and city fetched based on country
    getallcountries(){
        for (let u = 0; u < this.uaepoboxes.length; u++) {


            try {
              

                    return [{ "response": this.uaepoboxes, status: 200 }];
            
            }

            catch (err) {

            }


        }
    }

    getcitybycountry(event){
        for (let u = 0; u < this.uaepoboxes.length; u++) {


            try {
              

                if (this.uaepoboxes[u].country == event) {

                    return [{ "response": this.uaepoboxes[u], status: 200 }];
                }
            
            }

            catch (err) {

            }


        }
    }
    // ...end...

     // phase 5 new cr uae freezone and mainland cities module added by Kunal bansal on 29 oct 2021 start
    getfreezonelisting() {
        try {


            return [{ "response": this.Freezonecities, status: 200 }];

        }

        catch (err) {

        }
    }
    // ...end...


}