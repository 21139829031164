import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'ngx-webstorage';
import { AuthService } from '../auth.service';
import { Globals } from '../globals';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as $ from 'jquery';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})

export class HomepageComponent implements OnInit {
  _opened: boolean = false;
  globals: Globals;
  closeResult: string;
  public visibleStatus: boolean = false;
  logged: number = 0;
  isProductpage: number = 0;
  isseller: number = 0;
  image: string;
  name: string;
  city: string;
  isSearched: number = 0;
  searchedarray: any[];
  selectedItems = [];
  selectedItems2 = [];
  selectedItems3 = [];
  category: any[];
  subcategory: any[];
  subcategory3: any[];

  subcategorystateactive: number = 0;
  temp: number = 2;

  searchstring: string = '';

  filters = [];
  category3 = [];
  drawerstatus: number;

  firstlevel = [];
  secondlevel = [];
  dropdownSettings2 = {};
  dropdownSettings3 = {};

  // Geolocation module added by Kunal bansal on 25 june 2021 start
  loggedstatus: number;
  locationstatus: number;
  Districtname: string;
  tab: number;
  // end
  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
  countrylocation: string;

  // ...end...
  // phase 5 agent check for cart and side drawer added by kunal bansal on 22 nov 2021 start
  userisagent: string;
  // ..end..

  // <!-- New web routes added by Kunal bansal as per new cr on 14 jan 2022 start  -->
  opennewweb: number = 2;
  activenewwebchild: number = 10;
  activefurtherchild: number = 10;
  newwebarray = [{ "title": "About Us", "fontaweicon": "fa fa-globe", further: [{ "title": "What we do", routelinkcode: 1 }, { "title": "Workflow", routelinkcode: 2 }, { "title": "Our Technology", routelinkcode: 3 }] },
  { "title": "Who we are", "fontaweicon": "fa fa-globe", further: [{ "title": "Who we are", routelinkcode: 4 }, { "title": "Leadership Team", routelinkcode: 5 }] }, { "title": "Careers", "fontaweicon": "fa fa-question-circle-o", routelink: "/AboutEOL/career", further: [] }, {
    "title": "Buy/Sell",
    "fontaweicon": "fa fa-globe", further: [{ "title": "Post Your Requirement", routelinkcode: 7 }, { "title": "Product Listing", routelinkcode: 8 }]
  }, { "title": "Help", "fontaweicon": "fa fa-globe", further: [{ "title": "FAQ", routelinkcode: 9 }, { "title": "Terms & Condition", routelinkcode: 10 }, { "title": "Contact us", routelinkcode: 11 }] }]
  
  webarray=[{ "title": "About Us", "fontaweicon": "../../assets/images/newweb/aboutus.png", routelink: "/AboutEOL/AboutUs"},
  { "title": "What We Do", "fontaweicon": "../../assets/images/newweb/whatwedo.png", routelink: "/AboutEOL/what-we-do"},
  { "title": "Our Technology", "fontaweicon": "../../assets/images/newweb/ourtechnology.png", routelink: "/AboutEOL/technology"},
  { "title": "Who We Are", "fontaweicon": "../../assets/images/newweb/whoweare.png", routelink: "/AboutEOL/who-we-are"},
  { "title": "Careers", "fontaweicon": "../../assets/images/newweb/careers.png", routelink: "/AboutEOL/career"}

]
  // ....end....


  constructor(private storage: SessionStorageService, private router: Router, private modalService: NgbModal, private authservice: AuthService, private spinner: NgxSpinnerService, globals: Globals) {
    this.globals = globals;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    // Added by Kunal bansal on 2 july 2020 start
    this.dropdownSettings3 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "category_name",
      classes: "font_drop",
      position: 'top',
      autoPosition: false,

      maxHeight: 200
    };

    // end
    if (this.router.url === '/homepage/productListing') {
      this.isProductpage = 1;
    }
    else {
      this.isProductpage = 0;
    }

    this.logged = this.storage.retrieve('islogged');

    // check if the user is logged in or not 
    if (this.logged == 1) {
      // get notifications
      this.authservice.apipost('getNotification/?flag=count', {}, 1).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        // logout of all devices when change password module added by Kunal bansal on 18jan2021
        else if (response.status == 401) {
          this.storage.store("islogged", 0);
          this.storage.clear("token");

          this.router.navigate(['/homepage/login']);

        }
        else if (response.status == 200) {

          this.globals.notificationcount = response.response
          this.globals.cartcount = response.cart_count
        }

        else { Swal.fire("Server Down") }

      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
      // get profile
      this.authservice.apiget('Profile/?flag=1', 1).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.image = response.response.image
          this.name = response.response.name
          this.city = response.response.city
          this.isseller = response.response.is_Seller
          // <!-- phase 5 module country render cart country payment check added by Kunal bansal on 25 aug 2021 start -->
          sessionStorage.setItem("phase5country", response.response.country);
          // ...end...
          // phase 5 agent check for cart and side drawer added by kunal bansal on 22 nov 2021 start
          if (response.response.my_agent_code == "" || response.response.my_agent_code == null) {
            sessionStorage.setItem("userisagent", "no");
            this.userisagent = "no";

          }
          else {
            sessionStorage.setItem("userisagent", "yes");
            this.userisagent = "yes";
          }
          // ...end...
          if (response.response.addtional_document == "" || response.response.addtional_document == null) {
            sessionStorage.setItem("additionaldocumentuploaded", "no");


          }
          else {
            sessionStorage.setItem("additionaldocumentuploaded", "yes");

          }
        }
        // logout of all devices when change password module added by Kunal bansal on 18jan2021
        else if (response.status == 401) {

          Swal.fire("That Account Old password has been changed. You have been signed out now.")
        }
        else { Swal.fire("Server Down") }
      }

        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    // Added by Kunal bansal on 2 july 2020 start
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "category_name",
      showCheckbox: false,
      classes: "font_drop",
      maxHeight: 200
    };
    // end



  }

  mytransactio() {
    sessionStorage.setItem("tokentype", "usertoken");
  }

  // phase 4 search suggession optimization by Kunal bansal on 18 aug 2021 start
  getsuggesionlist() {
    this.authservice.orderapiget("search").subscribe((res: any) => {
      let response = res.json();
      this.searchedarray = response.products_lsit;

    })
  }



  selectedsugession(event) {
    this.searchstring = event.target.value;
  }
  // ..................end............

  query(buycode) {

    if (buycode == 1) {
      this.storage.store('buystatus', 0);
    }
    else {
      this.storage.store('buystatus', 1);
    }
    // alert(event.target.value)
    this.authservice.apipost('getQueryList/',
      {
        platform: 0, //0 for web and 1 for   app
        offset: 1,
        "buy_request": buycode == 1 ? 'no' : "yes"
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }

        else if (response.status == 200) {

          this.storage.store("orderquerylist", response.response.query_list);
          this.storage.store('Totalorderqueries', response.response.total_query)
          if (this.router.url == "/homepage/orderqueries") {
            location.reload();
          }
          else {
            this.router.navigate(['/homepage/orderqueries']);
          }

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
  }


  home() {
    if (window.location.pathname == "/homepage/productListing") {
      this.storage.store('sidedraweractivate', 0);
      this.storage.store('pressedkey', 0);
      this.storage.store('categorytab', 0);
      this.storage.clear("brandlabelslisting");
      this.storage.clear("checkhomepagebrandactive");
      location.reload();
    }
    else {
      this.storage.store('sidedraweractivate', 0);
      this.storage.store('pressedkey', 0);
      this.storage.store('categorytab', 0);
      this.storage.clear("brandlabelslisting");
      this.router.navigate(['/homepage/productListing']);
    }
  }

  show() {
    this.drawerstatus = this.storage.retrieve('drawerstatus');
    if (this.drawerstatus == 1) {
      this.temp = undefined;

    }
    this.visibleStatus = !this.visibleStatus;
  }

  android() {
    window.open("https://play.google.com/store/apps/details?id=com.EOLstocks&hl=en", "_blank");
  }

  ios() {
    window.open("https://apps.apple.com/us/app/eol-stocks/id1446978223?ls=1", "_blank");
  }

  gotocart() {
    this.storage.clear('cart_from');
    this.router.navigate(['/homepage/cart']);
  }

  moveDown() {
    if ($(".selected").length == 0) {
      $("#divSearchResults span").addClass("selected").focus();
    }
    //Check if there is another link under, if no, go to top
    if ($(".selected").next("a").length > 0) {
      $(".selected").removeClass("selected").next("a").addClass("selected").focus();
    } else {
      $(".selected").removeClass("selected");
      $("#divSearchResults span").next().addClass("selected").focus();
    }
  }

  moveUp() {
    if ($(".selected").length == 0) {
      $("#divSearchResults span").addClass("selected").focus();
    }
    //Check if there is another link above, if no, go to bottom
    if ($(".selected").prev("a").length > 0) {
      $(".selected").removeClass("selected").prev("a").addClass("selected").focus();
    } else {
      $(".selected").removeClass("selected");
      $("#divSearchResults a:last-child").addClass("selected").focus();
    }
  }

  globenav(){
       this.router.navigate(['/AboutEOL/AboutUs']);
  }

  newwebsite() {
    if (this.opennewweb == 1) {
      this.opennewweb = 2;
    }
    else {
      this.opennewweb = 1;
    }
    this.activenewwebchild = 10;

    // this.router.navigate(['/AboutEOL/AboutUs']);

    // this.router.navigate(['/homepage/newweb']);

  }



  keyPress(event) {
    //console.log(event.code)
    switch (event.keyCode) {
      case 38:
        break;
      case 40:
        break;
      default:
        this.searchstring = event.target.value;
        if (event.target.value.length > 1 && event.keyCode != 13 && event.keyCode != undefined) {
          this.authservice.apipost('search/?pattern=' + event.target.value, {}, 1).subscribe((res: any) => {
            let response = res.json();
            if (response.status == 400 || response.status == 500 || response.status == 100) {
              Swal.fire(response.message)
            }
            else if (response.status == 200) {
              // this.searchedarray = response.response.search_list
              this.isSearched = 1;
            }
            else { Swal.fire("Server Down") }
          }
            , err => {
              this.spinner.hide();
              Swal.fire("Connect to the Internet")
            }
          )
        }
        if (event.target.value.length == 0) {
          // this.searchedarray = []
        }
    }
  }

  keyPress2(event) {
    if (event.keyCode == 30) {
      this.moveUp();
    }
    else if (event.keyCode == 40) {
      this.moveDown();
    }
    else {
      console.log(event.target.value)
    }
  }
  closeall() {
    this.modalService.dismissAll();
  }
  openfaq(i) {
    if (this.temp == i) {
      this.temp = undefined;

      this.storage.store('drawerstatus', 0)
    }
    else {
      this.temp = i;


      this.storage.store('drawerstatus', 1)
    }
  }

  removecategoryapi(id) {
    var store = this.firstlevel.indexOf(id.id);
    this.firstlevel.splice(store, 1)
    this.selectedItems2 = [];
    this.authservice.apipost('CategoryList/', {
      "category_id": [this.firstlevel], "flag": "subcategory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.filters = [];
        this.category3 = [];
        this.subcategory = response.response;
      }

      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // added by Kunal on 03 juy 2020 start
  categoryapi(id) {

    // this.firstlevel.push(id.id)
    this.firstlevel = id.id;
    this.selectedItems2 = [];
    this.authservice.apipost('CategoryList/', {
      "category_id": [this.firstlevel], "flag": "subcategory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.filters = [];
        this.category3 = [];
        this.subcategory = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // Side drawer module added by Kunal bansal on 21 july 2020 start
  filterapi() {
    if (this.selectedItems.length == 0) {


    }
    else {
      // Geolocation module added by Kunal bansal on 25 june 2021 start
      this.locationstatus = this.storage.retrieve("locationstatus");
      this.loggedstatus = this.storage.retrieve("islogged");
      this.tab = this.storage.retrieve("pressedkey");
      this.Districtname = this.storage.retrieve("districtname");
      // end
      // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      this.countrylocation = sessionStorage.getItem("phase5countrylocation");
      // ...end...
      // alert(this.selectedItems[0].id)
      this.authservice.apipost('homePage/', {
        "platform": 1,
        "tab": 0,
        "offset": 1,
        "category": this.selectedItems.length != 0 ? this.firstlevel : [],
        "categorylev2": this.selectedItems2.length != 0 ? this.filters : [],
        "subcategory": this.selectedItems3.length != 0 ? this.category3 : [],
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // let i=[];
          // for(let k=0;k<this.selectedItems.length;k++)
          // {
          //   i.push(this.selectedItems[k].id)
          // }
          this.storage.store('sidecategoryfilter1', this.selectedItems);
          this.storage.store('sidecategoryfilter2', this.selectedItems2);
          this.storage.store('sidecategoryfilter3', this.selectedItems3);
          this.storage.store('sidecat1id', this.selectedItems[0].id);
          this.storage.store('sidecat2id', this.filters);
          this.storage.store('sidecat3id', this.category3)


          this.storage.store('sidedrawer', response.response.buying_list);
          this.storage.store('sidedrawerbanner', response.response.banners);
          this.storage.store('sidedrawerbrands', response.response.brand_details);

          this.storage.store('sidedraweractivate', 1);
          this.closeall()


          if (this.router.url == "/homepage/productListing") {

            location.reload();

          }
          else {
            this.router.navigate(['/homepage/productListing']);

          }


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  // end

  // <!-- New web routes added by Kunal bansal as per new cr on 14 jan 2022 start  -->
  // newwebchildswitch(index) {
  //   if (this.newwebarray[index].title == "Careers") {
  //     this.router.navigate(['/AboutEOL/career'])
  //   }
  //   else {
  //     if (this.activenewwebchild == index) {
  //       this.activenewwebchild = 10;
  //     }
  //     else {
  //       this.activenewwebchild = index;
  //     }
  //   }



  // }

  newwebnav(a) {

    if (a == 1) {

      this.router.navigate(['AboutEOL/what-we-do']);
    }
    else if (a == 2) {
      // workflow navigate from any page added by Kunal bansal on 06 may 2021
      if (this.router.url == "/AboutEOL/what-we-do") {
        document.querySelector('#my-flow').scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      else {
        this.router.navigate(['AboutEOL/what-we-do']);
        setTimeout(() => {
          document.querySelector('#my-flow').scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 1100);
      }

    }
    else if (a == 3) {
      this.router.navigate(['AboutEOL/technology']);
    }
    else if (a == 4) {
      this.router.navigate(['AboutEOL/who-we-are']);
    }
    else if (a == 5) {
      this.router.navigate(['AboutEOL/Ourteam']);
    }
    else if (a == 6) {
      this.router.navigate(['AboutEOL/career']);
    }
    else if (a == 7) {
      this.router.navigate(['AboutEOL/post-your-requirement']);
    }
    else if (a == 8) {
      this.storage.store('sidedraweractivate', 0);
      this.storage.store('pressedkey', 0);
      this.storage.store('categorytab', 0);
      this.storage.clear("brandlabelslisting");
      this.router.navigate(['homepage/productListing']);
    }
    else if (a == 9) {
      this.router.navigate(['AboutEOL/faq']);
    }
    else if (a == 10) {
      this.router.navigate(['AboutEOL/terms-conditions']);
    }
    else if (a == 11) {
      this.router.navigate(['AboutEOL/contactus2']);
    }
    else if (a == 12) {
      this.router.navigate(['homepage/login']);
    }
    else if (a == 13) {
      this.storage.store("islogged", 0);
      this.storage.clear("token");
      this.router.navigate(['/homepage/login']);
    }
    else {
      this.router.navigate(['AboutEOL/AboutUs']);
    }


  }


  // end

  // added by Kunal on 03 juy 2020 start
  opencategorymodal(shopbycategory) {

    this.selectedItems = [];

    this.selectedItems2 = [];
    this.selectedItems3 = [];
    this.visibleStatus = !this.visibleStatus;
    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    this.authservice.apipost('CategoryList/', {
      flag: 'category'

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.category = response.response;
        this.subcategorystateactive = 1;

        this.selectedItems2 = [];
        this.filters = []
        this.selectedItems3 = [];
        this.subcategory3 = [];
        // this.selectedItems.push({
        //   "id":2,"image":"https://eolproductimages.s3.ap-south-1.amazonaws.com/category/dup_B.EElectronics&PowerEngineering.jpg","category_name":"Electronics"
        // })
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }


  // Remove on deselect 2nd level dropdown module added by Kunal bansal on 21 july 2020 start
  subcategoryapiremove(item: any) {

    var index = this.filters.indexOf(item.id);
    this.filters.splice(index, 1)
    this.subcategory3 = [];

    if (this.filters.length != 0) {
      this.authservice.apipost('CategoryList/', {
        "category_id": this.filters, "flag": "subcategory"
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          this.selectedItems3 = [];
          if (response.response.length != 0) {
            this.subcategory3 = response.response;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  // end

  // category call on select 2nd level dropdown module added by Kunal bansal on 21 july 2020 start
  subcategoryapi(item: any) {


    this.filters.push(item.id)
    this.authservice.apipost('CategoryList/', {
      "category_id": this.filters, "flag": "subcategory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems3 = [];
        this.subcategory3 = [];

        if (response.response.length != 0) {
          this.subcategory3 = response.response;
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // category call on select 3rd level dropdown module added by Kunal bansal on 21 july 2020 start
  subcategory2api(item: any) {
    this.category3.push(item.id)
    //    this.authservice.apipost('CategoryList/',{"category_id":this.category3,"flag":"subcategory"
    //   }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     Swal.fire(response.message)
    //   }
    //   else if (response.status == 200) {

    //     if(response.response.length !=0){
    //    this.subcategory3=response.response;
    //     }

    //    }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();
    //     Swal.fire("Connect to the Internet")
    //   }
    // )

  }

  subcategory2apiremove(item: any) {

    var index2 = this.category3.indexOf(item.id);
    this.category3.splice(index2, 1)


    //    if(this.category3.length!=0){
    //    this.authservice.apipost('CategoryList/',{"category_id":this.category3,"flag":"subcategory"
    //   }).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     Swal.fire(response.message)
    //   }
    //   else if (response.status == 200) {

    //     this.subcategory3=response.response;
    // }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();
    //     Swal.fire("Connect to the Internet")
    //   }
    // )
    // }
  }


  // end

  unregisteruser() {     // Added by Kunal bansal on 21 April 2020
    Swal.fire({
      title: 'Warning',
      text: "Are you sure you want to Unregister ?",

      showCancelButton: true,

      cancelButtonText: 'NO',
      confirmButtonText: 'YES',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.authservice.apipost('unRegistrationRequest/', {}).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {
            Swal.fire(response.message);
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      }
    })
  }

  notifications() {
    this.router.navigate(['/homepage/notifications']);
  }

  search() {
    if (this.searchstring == null || this.searchstring.length == 0 || this.searchstring == undefined) {
      Swal.fire("Please enter a keyword to search");
    }
    else {
      this.storage.store("searchstring", this.searchstring);
      if (this.router.url == "/homepage/search") {
        this.storage.clear('checkbanner');
        // <!-- phase 5 new scenario added by Kunal bansal on 27 sep 2021 start -->
        this.storage.clear('brandlabelslisting');
        // ...end...
        location.reload();

      }
      else {
        this.storage.clear('checkbanner');
        // <!-- phase 5 new scenario added by Kunal bansal on 27 sep 2021 start -->
        this.storage.clear('brandlabelslisting');
        // ...end...
        this.router.navigate(['/homepage/search']);

      }
    }
  }
  // open2(dispatch, id) {


  //   this.modalService.open(dispatch, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
  //     this.closeResult = `Closed with: ${result}`;
  //   }, (reason) => {
  //     this.closeResult = `Dismissed `;
  //   });
  // }
  search1(name) {
    this.searchstring = name;
    this.search();
  }

  focusOutFunction() {
    setTimeout(() => {
      this.isSearched = 0;
    }, 400);
  }

  // logout confirmation module added by Kunal bansal on 12 nov 2020 

  logout() {
    Swal.fire({
      title: 'Warning',
      text: "Are you sure you want to signout now?",

      showCancelButton: true,

      cancelButtonText: 'No',
      confirmButtonText: 'YES',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.storage.store("islogged", 0);
        this.storage.clear("token");
        // Sharing inventory new cr added by Kunal bansal on 07 july 2021 start
        sessionStorage.removeItem("pageurl");
        // end
        this.router.navigate(['/homepage/login']);
      }
    })


  }

}