import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})


export class Statecitylisting {

  // New service added and citystate static array implement and created by Kunal bansal on 29 june 2021 start
  Statedata = [
    {
      State_name: "Andaman & Nicobar Islands *",
      dist_name: [{ "c": "Nicobar" }, { "c": "North and Middle Andaman" }, { "c": "South Andaman" }]
    },
    {
      State_name: "Andhra Pradesh",
      dist_name: [{ "c": "Ananthapuram" }, { "c": "Chandauli" }, { "c": "Dibang Valley" }, { "c": "East Champaran" }, { "c": "Krishna" }, { "c": "Kurnool" }, { "c": "Prakasam" }, { "c": "SPS Nellore" }, { "c": "Srikakulam" }, { "c": "Visakhapatnam" }, { "c": "Vizianagaram" }, { "c": "West Godavari" }, { "c": "YSR Kadapa" }]
    },
    {
      State_name: "Arunachal Pradesh",
      dist_name: [{ "c": "Anjaw" }, { "c": "Bongaigaon" }, { "c": "Debagarh" }, { "c": "Dibrugarh" }, { "c": "Hardwar" }, { "c": "Kra Daadi" }, { "c": "Kurung Kumey" }, { "c": "Lohit" }, { "c": "Longding" }, { "c": "Lower Dibang Valley" }, { "c": "Lower Subansiri" }, { "c": "Namsai" }, { "c": "Papum Pare" }, { "c": "Papumpare" }, { "c": "Shiyomi" }, { "c": "Siang" }, { "c": "Tawang" }, { "c": "Tirap" }, { "c": "Upper Siang" }, { "c": "Upper Subansiri" }, { "c": "West Kameng" }, { "c": "West Siang" }]
    },
    {
      State_name: "Assam",
      dist_name: [{ "c": "Baksa" }, { "c": "Barpeta" }, { "c": "Chitrakoot" }, { "c": "Chittaurgarh" }, { "c": "Chittoor" }, { "c": "Dang" }, { "c": "Darrang" }, { "c": "Dausa" }, { "c": "Dehradun" }, { "c": "Gadag" }, { "c": "Gadchiroli" }, { "c": "Giridih" }, { "c": "Hojai" }, { "c": "Jorhat" }, { "c": "Kamrup" }, { "c": "Kamrup Metropolitan" }, { "c": "Karbi Anglong" }, { "c": "Karimganj" }, { "c": "Kokrajhar" }, { "c": "Lakhimpur" }, { "c": "Majuli" }, { "c": "Morigaon" }, { "c": "Nagaon" }, { "c": "Nalbari" }, { "c": "Sivasagar" }, { "c": "Sonitpur" }, { "c": "South Salamara Mankachar" }, { "c": "Tinsukia" }, { "c": "Udalguri" }, { "c": "West Karbi Anglong" }]
    },
    {
      State_name: "Bihar",
      dist_name: [{ "c": "Araria" }, { "c": "Arwal" }, { "c": "Aurangabad" }, { "c": "Banka" }, { "c": "Begusarai" }, { "c": "Bhagalpur" }, { "c": "Bhojpur" }, { "c": "Chikballapur" }, { "c": "Fatehabad" }, { "c": "Fatehgarh Sahib" }, { "c": "Ganganagar" }, { "c": "Jamui" }, { "c": "Jehanabad " }, { "c": "Kaimur (Bhabua)" }, { "c": "Katihar" }, { "c": "Khagaria" }, { "c": "Kishanganj" }, { "c": "Lakhisarai" }, { "c": "Madhepura" }, { "c": "Madhubani" }, { "c": "Munger" }, { "c": "Muzaffarpur" }, { "c": "Nalanda" }, { "c": "Nawada" }, { "c": "Patna" }, { "c": "Purnea" }, { "c": "Rohtas" }, { "c": "Saharsa" }, { "c": "Samastipur" }, { "c": "Saran" }, { "c": "Sheikhpura" }, { "c": "Sheohar" }, { "c": "Sitamarhi" }, { "c": "Siwan" }, { "c": "Supaul" }, { "c": "Vaishali" }, { "c": "West Champaran" }]
    },
    {
      State_name: "Chandigarh *",
      dist_name: [{ "c": "Chandigarh" }]
    },
    {
      State_name: "Chhattisgarh",
      dist_name: [{ "c": "Balod" }, { "c": "Balodabazar Bhatapara" }, { "c": "Balrampur" }, { "c": "Bastar" }, { "c": "Bemetara" }, { "c": "Bijapur" }, { "c": "Bilaspur" }, { "c": "Chhindwara" }, { "c": "Dantewada" }, { "c": "Dhemaji" }, { "c": "Gariaband" }, { "c": "Janjgir-Champa" }, { "c": "Jashpur" }, { "c": "Kanker" }, { "c": "Kawardha" }, { "c": "Kondagaon" }, { "c": "Korba" }, { "c": "Korea" }, { "c": "Mahasamund" }, { "c": "Mungeli" }, { "c": "Narayanpur" }, { "c": "Raigarh" }, { "c": "Raipur" }, { "c": "Rajnandgaon" }, { "c": "Sukma" }, { "c": "Surajpur" }, { "c": "Surguja" }]
    },
    {
      State_name: "Dadra and Nagar Haveli and Daman and Diu *",
      dist_name: [{ "c": "Burhanpur" }, { "c": "Chaibasa" }, { "c": "Dhalai" }]
    },
    {
      State_name: "Goa",
      dist_name: [{ "c": "North Goa" }, { "c": "South Goa" }]
    },
    {
      State_name: "Gujarat",
      dist_name: [{ "c": "Ahmedabad" }, { "c": "Amreli" }, { "c": "Anand" }, { "c": "Aravalli" }, { "c": "Banaskantha" }, { "c": "Bharuch" }, { "c": "Bhavnagar" }, { "c": "Dhanbad" }, { "c": "Dhar" }, { "c": "Dharmapuri" }, { "c": "Erode" }, { "c": "Etah" }, { "c": "Etawah" }, { "c": "Gir Somnath" }, { "c": "Jamnagar" }, { "c": "Junagadh" }, { "c": "Kheda" }, { "c": "Kutch" }, { "c": "Mahesana" }, { "c": "Mahisagar" }, { "c": "Morbi" }, { "c": "Narmada" }, { "c": "Navsari" }, { "c": "Panchmahal" }, { "c": "Patan" }, { "c": "Porbandar" }, { "c": "Rajkot" }, { "c": "Sabarkantha" }, { "c": "Surat" }, { "c": "Surendranagar" }, { "c": "Tapi" }, { "c": "Vadodara" }, { "c": "Valsad" }]
    },
    {
      State_name: "Haryana",
      dist_name: [{ "c": "Ambala" }, { "c": "Bhiwani" }, { "c": "Chhota Udaipur" }, { "c": "Dohad" }, { "c": "Durg" }, { "c": "Ghaziabad" }, { "c": "Gurdaspur" }, { "c": "Jhajjar" }, { "c": "Jind" }, { "c": "Kaithal" }, { "c": "Karnal" }, { "c": "Kurukshetra" }, { "c": "Mahendragarh" }, { "c": "Nah" }, { "c": "Palwal" }, { "c": "Panchkula" }, { "c": "Panipat" }, { "c": "Rewari" }, { "c": "Rohtak" }, { "c": "Sirsa" }, { "c": "Sonipat" }, { "c": "Yamunanagar" }]
    },
    {
      State_name: "Himachal Pradesh",
      dist_name: [{ "c": "Bilaspur" }, { "c": "Dima Hasao (North Cachar Hills)" }, { "c": "Kangra" }, { "c": "Kinnaur" }, { "c": "Kullu" }, { "c": "Lahaul and Spiti" }, { "c": "Mandi" }, { "c": "Shimla" }, { "c": "Sirmaur" }, { "c": "Solan" }, { "c": "Una" }]
    },
    {
      State_name: "Jammu & Kashmir*",
      dist_name: [{ "c": "Anantnag" }, { "c": "Bandipore" }, { "c": "Baramulla" }, { "c": "Budgam" }, { "c": "Doda" }, { "c": "Ganderbal" }, { "c": "Jammu" }, { "c": "Kargil" }, { "c": "Kathua" }, { "c": "Kishtwar" }, { "c": "Kulgam" }, { "c": "Kupwara" }, { "c": "Leh - Ladakh" }, { "c": "Poonch" }, { "c": "Pulwama" }, { "c": "Rajouri" }, { "c": "Ramban" }, { "c": "Reasi" }, { "c": "Samba" }, { "c": "Shopian" }, { "c": "Srinagar" }, { "c": "Udhampur" }]
    },
    {
      State_name: "Jharkhand",
      dist_name: [{ "c": "Budaun" }, { "c": "Bulandshahr " }, { "c": "Dakshin Dinajpur" }, { "c": "Darbhanga" }, { "c": "Dharwad" }, { "c": "Faridabad" }, { "c": "Firozpur" }, { "c": "Gajapati" }, { "c": "Garhwal" }, { "c": "Guna" }, { "c": "Jamshedpur" }, { "c": "Jamtara" }, { "c": "Khunti" }, { "c": "Koderma" }, { "c": "Latehar" }, { "c": "Lohardaga" }, { "c": "Pakur" }, { "c": "Palamu" }, { "c": "Ramgarh" }, { "c": "Ranchi" }, { "c": "Sahibganj" }, { "c": "Seraikela" }, { "c": "Simdega" }]
    },
    {
      State_name: "Karnataka",
      dist_name: [{ "c": "Bagalkot" }, { "c": "Ballari (Bellary)" }, { "c": "Bangalore Rural" }, { "c": "Bangalore Urban" }, { "c": "Belagavi (Belgaum)" }, { "c": "Bidar" }, { "c": "Chamarajanagar" }, { "c": "Chamba" }, { "c": "Chamoli" }, { "c": "Charaideo" }, { "c": "Chennai" }, { "c": "Cuddalore" }, { "c": "East Kameng" }, { "c": "Garhwa" }, { "c": "Gorakhpur" }, { "c": "Gumla" }, { "c": "Kodagu" }, { "c": "Kolar" }, { "c": "Koppal" }, { "c": "Mandya" }, { "c": "Mysore" }, { "c": "Raichur" }, { "c": "Ramanagara" }, { "c": "Shimoga" }, { "c": "Tumkur" }, { "c": "Udupi" }, { "c": "Uttara Kannada (Karwar)" }, { "c": "Vijyapura (Bijapur)" }, { "c": "Yadgir" }]
    },
    {
      State_name: "Kerala",
      dist_name: [{ "c": "Alappuzha" }, { "c": "Dindigul" }, { "c": "Hamirpur" }, { "c": "Kannur" }, { "c": "Kasaragod" }, { "c": "Kollam" }, { "c": "Kottayam" }, { "c": "Kozhikode" }, { "c": "Malappuram" }, { "c": "Palakkad" }, { "c": "Pathanamthitta" }, { "c": "Thrissur" }, { "c": "Trivandrum" }, { "c": "Wayanad" }]
    },
    {
      State_name: "Ladakh *",
      dist_name: [{ "c": "Kargil District" }, { "c": "Leh District" }]
    },
    {
      State_name: "Lakshadweep *",
      dist_name: [{ "c": "Lakshadweep" }]
    },
    {
      State_name: "Madhya Pradesh",
      dist_name: [{ "c": "Agar Malwa" }, { "c": "Alirajpur" }, { "c": "Anuppur" }, { "c": "Ashoknagar" }, { "c": "Balaghat" }, { "c": "Barwani" }, { "c": "Betul" }, { "c": "Bhind" }, { "c": "Bhopal" }, { "c": "Bundi" }, { "c": "Buxar" }, { "c": "Cachar" }, { "c": "Chhatarpur" }, { "c": "Churu" }, { "c": "Daman" }, { "c": "Darjiling " }, { "c": "Dewas" }, { "c": "Gautam Buddha Nagar" }, { "c": "Ghazipur" }, { "c": "Gonda" }, { "c": "Gurugram" }, { "c": "Hardoi" }, { "c": "Hassan" }, { "c": "Jhabua" }, { "c": "Katni" }, { "c": "Khandwa" }, { "c": "Khargone" }, { "c": "Mandla" }, { "c": "Mandsaur" }, { "c": "Morena" }, { "c": "Narsinghpur" }, { "c": "Neemuch" }, { "c": "Niwari" }, { "c": "Panna" }, { "c": "Raisen" }, { "c": "Rajgarh" }, { "c": "Ratlam" }, { "c": "Rewa" }, { "c": "Sagar" }, { "c": "Satna" }, { "c": "Sehore" }, { "c": "Seoni" }, { "c": "Shahdol" }, { "c": "Shajapur" }, { "c": "Sheopur" }, { "c": "Shivpuri" }, { "c": "Sidhi" }, { "c": "Singrouli" }, { "c": "Tikamgarh" }, { "c": "Ujjain" }, { "c": "Umaria" }, { "c": "Vidisha" }]
    },
    {
      State_name: "Maharashtra",
      dist_name: [{ "c": "Ahmednagar" }, { "c": "Akola" }, { "c": "Amravati" }, { "c": "Aurangabad" }, { "c": "Beed" }, { "c": "Bhandara" }, { "c": "Biswanath" }, { "c": "Davanagere" }, { "c": "East Khasi Hills" }, { "c": "Gandhinagar" }, { "c": "Guntur" }, { "c": "Hugli " }, { "c": "Idukki" }, { "c": "Kolhapur" }, { "c": "Latur" }, { "c": "Mumbai City" }, { "c": "Mumbai Suburban" }, { "c": "Nagpur" }, { "c": "Nanded" }, { "c": "Nandurbar" }, { "c": "Nashik" }, { "c": "Osmanabad" }, { "c": "Palghar" }, { "c": "Parbhani" }, { "c": "Pune" }, { "c": "Raigad" }, { "c": "Ratnagiri" }, { "c": "Sangli" }, { "c": "Satara" }, { "c": "Sindhudurg" }, { "c": "Solapur" }, { "c": "Thane" }, { "c": "Wardha" }, { "c": "Washim" }, { "c": "Yavatmal" }]
    },
    {
      State_name: "Manipur",
      dist_name: [{ "c": "Bishnupur" }, { "c": "Chandel" }, { "c": "Churachanpur" }, { "c": "Hamirpur" }, { "c": "Haora " }, { "c": "Imphal East" }, { "c": "Imphal West" }, { "c": "Jiribam" }, { "c": "Kakching" }, { "c": "Kamjong" }, { "c": "Kangpokpi (Sadar Hills)" }, { "c": "Noney" }, { "c": "Pherzawl" }, { "c": "Senapati" }, { "c": "Tamenglong" }, { "c": "Tengnoupal" }, { "c": "Thoubal" }, { "c": "Ukhrul" }]
    },
    {
      State_name: "Meghalaya",
      dist_name: [{ "c": "East Garo Hills" }, { "c": "East Jaintia Hills" }, { "c": "East Khasi Hills" }, { "c": "North Garo Hills" }, { "c": "Ri Bhoi" }, { "c": "South Garo Hills" }, { "c": "South West Garo Hills" }, { "c": "West Garo Hills" }, { "c": "West Jaintia Hills" }, { "c": "West Khasi Hills" }]
    },
    {
      State_name: "Mizoram",
      dist_name: [{ "c": "Aizawl" }, { "c": "Champhai" }, { "c": "Kolasib" }, { "c": "Lawngtlai" }, { "c": "Lunglei" }, { "c": "Mamit" }, { "c": "Saiha" }, { "c": "Serchhip" }]
    },
    {
      State_name: "Nagaland",
      dist_name: [{ "c": "Dimapur" }, { "c": "Kiphire" }, { "c": "Kohima" }, { "c": "Longleng" }, { "c": "Mokokchung" }, { "c": "Mon" }, { "c": "Peren" }, { "c": "Phek" }, { "c": "Tuensang" }, { "c": "Wokha" }, { "c": "Zunheboto" }]
    },
    {
      State_name: "Odisha",
      dist_name: [{ "c": "Angul" }, { "c": "Balangir" }, { "c": "Baleshwar" }, { "c": "Bargarh" }, { "c": "Bhadrak" }, { "c": "Boudh" }, { "c": "Cuttack" }, { "c": "Deogarh" }, { "c": "Dhenkanal" }, { "c": "Gajapati" }, { "c": "Ganjam" }, { "c": "Jagatsinghpur" }, { "c": "Jajpur" }, { "c": "Jharsuguda" }, { "c": "Kalahandi" }, { "c": "Kandhamal" }, { "c": "Kendrapara" }, { "c": "Kendujhar" }, { "c": "Khordha" }, { "c": "Koraput" }, { "c": "Malkangiri" }, { "c": "Mayurbhanj" }, { "c": "Nabarangapur" }, { "c": "Nayagarh" }, { "c": "Nuapada" }, { "c": "Puri" }, { "c": "Rayagada" }, { "c": "Sambalpur" }, { "c": "Subarnapur" }, { "c": "Sundargarh" }]
    },
    {
      State_name: "Puducherry *",
      dist_name: [{ "c": "Karaikal" }, { "c": "Mahe" }, { "c": "Puducherry" }, { "c": "Yanam" }]
    },
    {
      State_name: "Punjab",
      dist_name: [{ "c": "Barnala" }, { "c": "Bathinda" }, { "c": "Faridkot" }, { "c": "Fategarh Sahib" }, { "c": "Fazilka" }, { "c": "Ferozepur" }, { "c": "Gurdaspur" }, { "c": "Hoshiarpur" }, { "c": "Jalandhar" }, { "c": "Kapurthala" }, { "c": "Ludhiana" }, { "c": "Mansa" }, { "c": "Moga" }, { "c": "Pathankot" }, { "c": "Patiala" }, { "c": "Rupnagar" }, { "c": "Sangrur" }, { "c": "Sas Nagar" }, { "c": "SBS Nagar" }, { "c": "Sri Muktsar Sahib" }, { "c": "Tarn Taran" }]
    },
    {
      State_name: "Rajasthan",
      dist_name: [{ "c": "Ajmer" }, { "c": "Alwar" }, { "c": "Banswara" }, { "c": "Baran" }, { "c": "Barmer" }, { "c": "Bharatpur" }, { "c": "Bhilwara" }, { "c": "Bikaner" }, { "c": "Bundi" }, { "c": "Chittorgarh" }, { "c": "Churu" }, { "c": "Dausa" }, { "c": "Dholpur" }, { "c": "Dungarpur" }, { "c": "Hanumangarh" }, { "c": "Jaipur" }, { "c": "Jaisalmer" }, { "c": "Jalore" }, { "c": "Jhalawar" }, { "c": "Jhunjhunu" }, { "c": "Jodhpur" }, { "c": "Karauli" }, { "c": "Kota" }, { "c": "Nagaur" }, { "c": "Pali" }, { "c": "Pratapgarh" }, { "c": "Rajsamand" }, { "c": "Sawai Madhopur" }, { "c": "Sikar" }, { "c": "Sirohi" }, { "c": "Sri Ganganagar" }, { "c": "Tonk" }, { "c": "Udaipur" }]
    },
    {
      State_name: "Sikkim",
      dist_name: [{ "c": "East" }, { "c": "North" }, { "c": "South" }, { "c": "West" }]
    },
    {
      State_name: "Tamil Nadu",
      dist_name: [{ "c": "Ariyalur" }, { "c": "Chengalpet" }, { "c": "Chennai" }, { "c": "Coimbatore" }, { "c": "Cuddalore" }, { "c": "Dharampuri" }, { "c": "Dindigul" }, { "c": "Erode" }, { "c": "Kallakurichi" }, { "c": "Kancheepuram" }, { "c": "Kanyakumari" }, { "c": "Karur" }, { "c": "Krishnagiri" }, { "c": "Madurai" }, { "c": "Mayiladuthurai" }, { "c": "Nagapattinam" }, { "c": "Namakkal" }, { "c": "Perambalur" }, { "c": "Pudukkottai" }, { "c": "Ramanathapuram" }, { "c": "Ranipet" }, { "c": "Salem" }, { "c": "Sivagangai" }, { "c": "Tenkasi" }, { "c": "Thanjavur" }, { "c": "The Nilgiris" }, { "c": "Theni" }, { "c": "Thirunelveli" }, { "c": "Thiruvallur" }, { "c": "Thiruvarur" }, { "c": "Tiruchirappalli" }, { "c": "Tirupathur" }, { "c": "Tiruppur" }, { "c": "Tiruvannamalai" }, { "c": "Tuticorin" }, { "c": "Vellore" }, { "c": "Viluppuram" }, { "c": "Virudhunagar" }]
    },
    {
      State_name: "Telangana",
      dist_name: [{ "c": "Adliabad" }, { "c": "Bhadradri Kothagudem" }, { "c": "Hyderabad" }, { "c": "Jagtial" }, { "c": "Jangaon" }, { "c": "Jayshankar Bhupalpally" }, { "c": "Jogulamba Gadwal" }, { "c": "Kamareddy" }, { "c": "Karimnagar" }, { "c": "Khammam" }, { "c": "Kumuram Bheem Asifabad" }, { "c": "Mahabubabad" }, { "c": "Mahabubnagar" }, { "c": "Mancherial" }, { "c": "Medak" }, { "c": "Medchal Malkajgiri" }, { "c": "Mulugu" }, { "c": "Nagarkurnool" }, { "c": "Nalgonda" }, { "c": "Narayanpet" }, { "c": "Nirmal" }, { "c": "Nizamabad" }, { "c": "Peddapalli" }, { "c": "Rajanna Sircilla" }, { "c": "Rangareddy" }, { "c": "Sangareddy" }, { "c": "Siddipet" }, { "c": "Suryapet" }, { "c": "Vikarbad" }, { "c": "Wanaparthy" }, { "c": "Warangal Rural" }, { "c": "Warangal Urban" }, { "c": "Yadadri Bhuvanagiri" }]
    },
    {
      State_name: "The NCT of Delhi *",
      dist_name: [{ "c": "Central Delhi" }, { "c": "East Delhi" }, { "c": "New Delhi" }, { "c": "North Delhi" }, { "c": "North East Delhi" }, { "c": "North West Delhi" }, { "c": "Shahdara" }, { "c": "South Delhi" }, { "c": "South East Delhi" }, { "c": "South West Delhi" }, { "c": "West Delhi" }]
    },
    {
      State_name: "Tripura",
      dist_name: [{ "c": "Dhalai" }, { "c": "Gomati" }, { "c": "Khowai" }, { "c": "North Tripura " }, { "c": "Sepahijala" }, { "c": "South Tripura " }, { "c": "Unakoti" }, { "c": "West Tripura " }]
    },
    {
      State_name: "Uttar Pradesh",
      dist_name: [{ "c": "Agra" }, { "c": "Aligarh" }, { "c": "Ambedaker Nagar" }, { "c": "Amethi" }, { "c": "Amorha" }, { "c": "Auraiya" }, { "c": "Ayodhya" }, { "c": "Azamgarh" }, { "c": "Baghpat" }, { "c": "Bahraich" }, { "c": "Ballia" }, { "c": "Balrampur" }, { "c": "Banda" }, { "c": "Barabanki" }, { "c": "Bareilly" }, { "c": "Basti" }, { "c": "Bhadohi" }, { "c": "Bijnor" }, { "c": "Budaun" }, { "c": "Bulandshahar" }, { "c": "Chandauli" }, { "c": "Chitrakoot" }, { "c": "Deoria" }, { "c": "Etah" }, { "c": "Etawah" }, { "c": "Farrukhabad" }, { "c": "Fatehpur" }, { "c": "Firozabad" }, { "c": "Gautam Buddha Nagar" }, { "c": "Ghaziabad" }, { "c": "Ghazipur" }, { "c": "Gonda" }, { "c": "Gorakhpur" }, { "c": "Hamirpur" }, { "c": "Hapur" }, { "c": "Hardoi" }, { "c": "Hathras" }, { "c": "Jalaun" }, { "c": "Jaunpur" }, { "c": "Jhansi" }, { "c": "Kannauj" }, { "c": "Kanpur Dehat" }, { "c": "Kanpur Nagar" }, { "c": "Kasganj" }, { "c": "Kaushambi" }, { "c": "Kushi Nagar" }, { "c": "Lakhimpur Kheri" }, { "c": "Lalitpur" }, { "c": "Lucknow" }, { "c": "Maharajganj" }, { "c": "Mahoba" }, { "c": "Mainpuri" }, { "c": "Mathura" }, { "c": "Mau" }, { "c": "Meerut" }, { "c": "Mirzapur" }, { "c": "Moradabad" }, { "c": "Muzaffar Nagar" }, { "c": "Pilibhit" }, { "c": "Pratapgarh" }, { "c": "Prayagraj" }, { "c": "Raebareli" }, { "c": "Rampur" }, { "c": "Saharanpur" }, { "c": "Sambhal" }, { "c": "Sant Kabir Nagar" }, { "c": "Shahjahanpur" }, { "c": "Shamli" }, { "c": "Shrawasti" }, { "c": "Siddharth Nagar" }, { "c": "Sitapur" }, { "c": "Sonbhadra" }, { "c": "Sultanpur" }, { "c": "Unnao" }, { "c": "Varanasi" }]
    },
    {
      State_name: "Uttarakhand",
      dist_name: [{ "c": "Almora" }, { "c": "Bageshwar" }, { "c": "Chamoli" }, { "c": "Champawat" }, { "c": "Dehradun" }, { "c": "Haridwar" }, { "c": "Nainital" }, { "c": "Pauri Garhwal" }, { "c": "Pithoragarh" }, { "c": "Rudraprayag" }, { "c": "Tehri Garhwal" }, { "c": "Udham Singh Nagar" }, { "c": "Uttarkashi" }]
    },
    {
      State_name: "West Bengal",
      dist_name: [{ "c": "Alipurduar" }, { "c": "Bankura " }, { "c": "Birbhum" }, { "c": "Cooch Bihar" }, { "c": "Dakshin Dinajpur" }, { "c": "Darjeeling" }, { "c": "Hooghly" }, { "c": "Howrah" }, { "c": "Jalpaiguri" }, { "c": "Jhargram" }, { "c": "Kalimpong" }, { "c": "Kolkata" }, { "c": "Malda" }, { "c": "Murshidabad " }, { "c": "Nadia " }, { "c": "Paschim Burdwan" }, { "c": "Paschim Medinipur" }, { "c": "Purba Burdwan" }, { "c": "Purba Medinipur" }, { "c": "Puruliya" }, { "c": "Uttar Dinajpur" }]
    },
  ];
}
// end

