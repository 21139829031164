import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {



  constructor(private http: Http, private spinner: NgxSpinnerService, private storage: SessionStorageService) { }

  showTodayDate() {
    let ndate = new Date();
    return ndate;
  }

  // get user current location function and api added by KUnal bansal on 21 june 2021 start
  getPosition(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.spinner.show();
      navigator.geolocation.getCurrentPosition(resp => {
        this.storage.store("locationstatus", 1);
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });

      },
        err => {
          this.storage.store("locationstatus", 0);
          Swal.fire('Refresh page and Allow location first to continue');
          this.spinner.hide();
          reject(err);

        });
    });


  }
  // get user current location function and api added by KUnal bansal on 21 june 2021 start
  geocoding(url) {

    // var headers = new Headers();
    // var token = this.storage.retrieve('token');
    // headers.append('Content-Type', 'application/json');
    // headers.append('Token', token);
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?key=" + url).pipe(map(
      (data: Response) => {
        this.spinner.hide();
        return data;
      }
    ))
  }

  // end
  
    // get user current location function and api added by KUnal bansal on 22 june 2021 start
  // geolocation() {
 
  //     this.spinner.show();
  //     var headers = new Headers();
  //     var token = this.storage.retrieve('token');
  //     headers.append('Content-Type', 'application/json');
  //     headers.append('Token', token);
  //     return this.http.post("https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyBvQhRMAEoYfqwjNkt1fhM12JZ71yMsD6o",{}).pipe(map(
  //       (data: Response) => {
  //         this.spinner.hide();
  //         return data;
  //       }
  //     ))
  //   }


  apipost(url, body, hide = 0) {
    if (hide == 0) {
      this.spinner.show();
      var headers = new Headers();
      var token = this.storage.retrieve('token');
      headers.append('Content-Type', 'application/json');
      headers.append('Token', token);
      return this.http.post(environment.baseurl + url, body, { headers: headers }).pipe(map(
        (data: Response) => {
          this.spinner.hide();
          return data;
        }
      ))
    }
    else {
      var headers = new Headers();
      var token = this.storage.retrieve('token');
      headers.append('Content-Type', 'application/json');
      headers.append('Token', token);
      return this.http.post(environment.baseurl + url, body, { headers: headers }).pipe(map(
        (data: Response) => {
          return data;
        }
      ))
    }
  }


  apiget(url, hide = 0) {
    if (hide == 0) {
      this.spinner.show();
      var headers = new Headers();
      var token = this.storage.retrieve('token');
      headers.append('Content-Type', 'application/json');
      headers.append('Token', token);
      return this.http.get(environment.baseurl + url, { headers: headers }).pipe(map(
        (data: Response) => {
          this.spinner.hide();
          return data;
        }
      ))
    }
    else {
      var headers = new Headers();
      var token = this.storage.retrieve('token');
      headers.append('Content-Type', 'application/json');
      headers.append('Token', token);
      return this.http.get(environment.baseurl + url, { headers: headers }).pipe(map(
        (data: Response) => {
          return data;
        }
      ))
    }
  }





  // special function created for silently load ask now order related query api by KUnal bansal on 23 feb 2021 start
  orderapiget(url, hide = 0) {
    if (hide == 0) {
      // this.spinner.show();
      var headers = new Headers();
      var token = this.storage.retrieve('token');
      headers.append('Content-Type', 'application/json');
      headers.append('Token', token);
      return this.http.get(environment.baseurl + url, { headers: headers }).pipe(map(
        (data: Response) => {
          // this.spinner.hide();
          return data;
        }
      ))
    }
    else {
      var headers = new Headers();
      var token = this.storage.retrieve('token');
      headers.append('Content-Type', 'application/json');
      headers.append('Token', token);
      return this.http.get(environment.baseurl + url, { headers: headers }).pipe(map(
        (data: Response) => {
          return data;
        }
      ))
    }
  }
  // end

  apidelete(url, id) {
    this.spinner.show();
    var headers = new Headers();
    var token = this.storage.retrieve('token');
    headers.append('Content-Type', 'application/json');
    headers.append('Token', token);
    return this.http.delete(environment.baseurl + url + id, { headers: headers }).pipe(map(
      (data: Response) => {
        this.spinner.hide();
        return data;
      }
    ))
  }

  formdata(url, body) {
    this.spinner.show();
    var headers = new Headers();
    var token = this.storage.retrieve('token');
    headers.append('Token', token);
    return this.http.post(environment.baseurl + url, body, { headers: headers }).pipe(map(
      (data: Response) => {
        this.spinner.hide();
        return data;
      }
    ))
  }

}
