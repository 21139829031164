import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'ngx-webstorage';
import { ConnectionService } from 'ng-connection-service';  
import * as $ from 'jquery';
import { Router, NavigationEnd } from '@angular/router';
declare let gtag:Function;
declare let fbq:Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  isConnected = true;  
  noInternetConnection: boolean; 
  status:string;
  // title = 'appBootstrap';

  // model;
  constructor(private router:Router,private translate: TranslateService, private storage: SessionStorageService,private connectionService: ConnectionService) {
    let language = this.storage.retrieve('language');
    if (language == 'Hindi') {
      translate.setDefaultLang('hin');
    }
    else if (language == 'Gujarati') {
      translate.setDefaultLang('guj');
    }
    else if (language == 'Punjabi') {
      translate.setDefaultLang('punjabi');
    }
    else if (language == 'Arabic') {
      translate.setDefaultLang('arabic');
    }
    else {
      translate.setDefaultLang('en');
    }
    this.router.events.subscribe(event => {
      // if (event instanceof NavigationEnd) {
      //   (<any>window).ga('set', 'page', event.urlAfterRedirects);
      //   (<any>window).ga('send', 'pageview');
      // }
      if(event instanceof NavigationEnd){
        gtag('config','G-8ZY8PY10M5',{'page_path' : event.url});
        fbq('track', 'PageView');
      }
    });
    // to check offline online net coneectivity module added by Kunal bansal on 11 jan 2021 start
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    //   if (this.isConnected) {
    //     this.status = "ONLINE";
    //     alert(this.status)
    //   }
    //   else {
    //     this.status = "OFFLINE";
    //     alert(this.status)
    //   }
    // })
    // end
  }
    //translate.setDefaultLang('hin');
  }
