import { Component, OnInit, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { AuthService } from '../auth.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';
var x;

@Component({
  selector: 'app-my-orders-buy',
  templateUrl: './my-orders-buy.component.html',
  styleUrls: ['./my-orders-buy.component.css'],
  providers: [NgbModalConfig, NgbModal]
})

export class MyOrdersBuyComponent implements OnInit {
  orders: any[];
  orderstatus: any[];
  allorother: number = 0;
  selectedsid: string = '';
  sellername: string = '';
  closeResult: string = '';
  reviews: string = '';
  listing: any=[];
  selectedtab: number = 1;
  totalcount: any;
  flag: number = 1;
  category: any[];
  offset: number = 1;
  activefilter: number = 0;

  faq: any[];
  temp: number;
  temp2: number = 0;
  checktick: boolean;
  compare: number;
  set: number = 2;
  checks: any[];
  checked: any[];
  checkedsel: number = 1;
  lastchild: number = 0;
  selectallactivate: number = 0;
  selectedItems = [];
  dropdownSettings2 = {};
  dropdownSettings3 = {};
  ordernumbers = [];
  categorylisting = [];
  categorylisting2 = [];
  checkradio: number = 0;

  filter = [];
  pickupdatefrom: string = "";
  pickupdateto: string = "";
  selectedorderno: any[];
  selectedorderstatus: any[];
  selectedordertype: any[];

  pushorderno = [];
  pushorderstatus = [];
  pushordertype = [];

  showcategory = [];

  selectallfor = [];
  selectactive: number = 1;

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  showlabel: number = 0;
  Appliedcatfilters = [];
  duplicatecatfilters = [];
  Applieddatefrom = [];
  Applieddateto = [];
  Appliedordero = [];
  Appliedorderstatus = [];
  Appliedordertype = [];

  tab: number = 0;

  tomindate: string = "";
  frommaxdate: string = "";
  tomaxdate: string = "";
  orderid: number;
  // end
  // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->
  agentcodedata = [];
  selectedagentcode: any[];
  agentcodeapi = [];
  Appliedagentcodeapi = [];
  noagentcode: number = 0;
  agentSettings = {};
  manualsearchfield: string;
  cancelreason: string = '';
  cancelcategory: string = '1';

  // phase 4 new cr partial payment module inventory filter params added by Kunal bansal on 09 august 2021 start
  selectedpayment = [];
  selectedpaymentlabels = [];
  Appliedpaymentlabels = [];
  paymentmodule = [];
  paymentlisting: any[];

  country:string="India";





  constructor(config: NgbModalConfig, private authservice: AuthService, private router: Router, private storage: SessionStorageService, private modalService: NgbModal, private spinner: NgxSpinnerService) {
    // config.backdrop = 'static'; to avoid mdal close on side click


  }

  @HostListener("window:scroll", ["$event"])

  // onWindowScroll(e) {

  //   let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
  //   let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   let max1 = document.body.scrollHeight - 10;

  //   if (Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1) {
  //     if (this.listing.length < this.totalcount) {
  //       if (this.activefilter == 0) {
  //         this.getlist(this.selectedtab, this.flag);
  //       }
  //       else {
  //         this.filter = this.storage.retrieve('filterorders')
  //         this.Applyfilter(this.allorother, this.offset)
  //       }
  //     }
  //   }
  onWindowScroll(e) {

    if (($(window).scrollTop() + $(window).innerHeight()) >= $(document).height() - this.getScrollbarWidth()) {
      if (this.listing.length < this.totalcount) {
        if (this.activefilter == 0) {
          // this.getlist(this.selectedtab, this.flag);
          // commented on 25jan2021 to add above line by Kunal bansal 
          this.getlist(this.allorother, this.flag);
        }
        else {
          this.filter = this.storage.retrieve('filterorders')
          this.Applyfilter(this.allorother, this.offset)
        }
      }
    }
  }


  getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);

    return widthNoScroll - widthWithScroll;
  }

  ngOnChanges(changes: any) {
    if (changes.orders.currentValue != undefined) {
      this.listing = changes.orders.currentValue.buying_list.in_progress
      //console.log("2",changes.orders.currentValue.buying_list.in_progress)

    }


  }


  ngOnInit() {
    // this.agentcodedata.push({ 'name': "Kunal12345", "id": 0 }, { 'name': "Abc123444F", "id": 2 },{ 'name': "Abc123444F", "id": 3 }, { 'name': "Abc123444F", "id": 4}, { 'name': "Abc123444F", "id": 5}, { 'name': "Abc123444F", "id": 6 },  { 'name': "Blank", "id": 7 },{ 'name': "Abc123444F", "id": 8 }, { 'name': "Abc123444F", "id": 9 } )
    this.country = sessionStorage.getItem('phase5country');
    $('.open-datetimepicker').click(function (event) {
      event.preventDefault();
      $('#datetimepicker').click();
    });
    // added reset scenario stay on that tab on 25jan2021 by Kunal bansal
    this.tab = this.storage.retrieve("myorderbuytab");
    if (this.tab == null) {
      this.getlist(0, 1)
      this.allorother = 0;
      this.tab = 0;
    }
    else {
      this.getlist(this.tab, 1)
      if (this.tab == 0) {
        this.allorother = 0;
      }
      else {
        this.allorother = 1;
      }
    }

    // this.categorylisting.push({
    //   "name":"1","id":1
    // },{ "name":2,"id":2},{ "name":"3","id":3},{ "name":"4","id":4},{ "name":"5","id":5},{ "name":"6","id":6},{ "name":"7","id":7},{ "name":"8","id":8},{ "name":"9","id":9},{ "name":"10","id":10},{ "name":"11","id":3},{ "name":"12","id":12},{ "name":"13","id":13},{ "name":"14","id":14},{ "name":"15","id":15},{ "name":"16","id":16}
    // ,{ "name":"16","id":16},{ "name":"17","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3}
    // ,{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3}
    // ,{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"3","id":3},{ "name":"100","id":100})

    this.categorylisting2.push({
      "name": "Offline", "id": 1
    }, { "name": "Online", "id": 0 })
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      enableCheckAll: false,
      text: "Select",
      labelKey: "name",
      classes: "font_drop",
      maxHeight: 200

    };
    this.dropdownSettings3 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      enableCheckAll: false,
      labelKey: "ordernumber",
      classes: "font_drop",
      maxHeight: 200

    };
    this.agentSettings = {
      singleSelection: false,
      enableFilterSelectAll: true,
      enableSearchFilter: false,
      text: "Select",
      enableCheckAll: true,
      labelKey: "code",
      classes: "font_drop",
      maxHeight: 200

    };

    // <!-- phase 4 cr payment option filteration module added by Kunal bansal on 09 august 2021 start -->
    this.paymentmodule = [{ "name": "Online Payment", id: 2 }, { "name": "POD", id: 1 }, { "name": "Partial Payment", id: 3 }];
    // end

  }

  ngOnDestroy() {
    this.activefilter = 0;
    this.storage.clear('myorderbuytab');
  }
  succed() {
    alert('hlo')
    $(function () {
      $("#datepicker").datepicker();
    });
  }


  onChange(a) {
    this.temp = undefined;
    this.temp2 = undefined;
    this.checkradio = a;
    if (a == 1) {
      this.pushorderno = [];
      this.pushorderstatus = [];
      this.pushordertype = [];

      this.selectedorderno = [];
    }

    if (a == 2) {
      this.pickupdatefrom = "";
      this.pickupdateto = "";
      this.pushorderstatus = [];
      this.pushordertype = [];
      this.authservice.apiget('static_Filter_Data/?rtype=buy').subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // alert(JSON.stringify(response.response.buying_list))


          this.ordernumbers = response.response;


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    if (a == 3) {
      this.pickupdatefrom = "";
      this.pickupdateto = "";
      this.pushorderno = [];
      this.pushordertype = [];
      this.authservice.apiget('static_Filter_Data/?rtype=status').subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // alert(JSON.stringify(response.response.buying_list))


          this.categorylisting = response.response;


        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    if (a == 4) {
      this.pickupdatefrom = "";
      this.pickupdateto = "";
      this.pushorderno = [];
      this.pushorderstatus = [];

    }
  }

  // phase 4 new cr partial payment module in my order params added by Kunal bansal on 09 august 2021 start
  paymentselection(event) {
    if (this.selectedpayment.includes(event.id)) {

    }
    else {
      this.selectedpayment.push(event.id);
      this.selectedpaymentlabels.push({ "name": event.name });
    }
  }

  paymentDeselection(event) {
    let find = this.selectedpayment.indexOf(event.id);
    this.selectedpayment.splice(find, 1);
    this.selectedpaymentlabels.splice(find, 1);
  }

  // end


  Removefilter() {
    // this.showcategory = [];
    // this.filter = [];
    // this.pickupdatefrom = "";
    // this.pickupdateto = "";
    // this.selectedorderno = [];
    // this.selectedorderstatus = [];
    // this.selectedordertype = [];
    // this.pushorderno=[];
    // this.pushorderstatus=[];
    // this.pushordertype;
    // $(".yup[type='checkbox']").prop('checked', false);

    // for (let index = 0; index < this.category.length; index++) {
    //   for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {
    //     if (this.selectallfor.includes(this.category[index].child_category[Innerindex].id)) {
    //     }
    //     else {

    //       this.selectallfor.push(this.category[index].child_category[Innerindex].id);
    //     }

    //   }
    // }
    location.reload();

    // 25jan2021 470point in progress

  }
  onOpen() {

    this.temp2 = undefined;
    this.temp = undefined;
    // if (this.filter.length == 0) {
    //   Swal.fire('Please select Category First')
    // }

  }

  // sorty by module for pushing idis by Kunal bansal on 31 oct 2020 start

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  Byorderno(item: any) {
    this.pushorderno.push(item.ordernumber);

    // this.Appliedordero.push(item)

    // alert(item.ordernumber)
  }
  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  Byorderstatus(item: any) {

    this.pushorderstatus.push(item.id);
    // this.Appliedorderstatus.push(item)
  }
  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  Byordertype(item: any) {

    this.pushordertype.push(item.id);
    // this.Appliedordertype.push(item)
  }
  // end

  removeorderno(item: any) {
    var orno = this.pushorderno.indexOf(item.ordernumber);

    this.pushorderno.splice(orno, 1)

  }
  onSelectAllorderno(item: any) {
    for (let index = 0; index < this.selectedorderno.length; index++) {
      this.Appliedordero.push({ 'ordernumber': this.selectedorderno[index].ordernumber, 'id': this.selectedorderno[index].id })
      this.pushorderno.push(this.selectedorderno[index].ordernumber)
    }
  }

  onDeSelectAllorderno(item: any) {

    this.Appliedordero = [];
    this.pushorderno = [];

  }

  removestatus(item: any) {
    var orstatus = this.pushorderstatus.indexOf(item.id);

    this.pushorderstatus.splice(orstatus, 1)

  }
  removeordertype(item: any) {
    var ortype = this.pushorderstatus.indexOf(item.id);

    this.pushordertype.splice(ortype, 1)

  }

  // end

  opencategorymodal(shopbycategory) {
    this.offset = 1;
    this.showcategory = [];

    this.selectactive = 1;
    this.selectedorderno = [];
    this.selectedorderstatus = [];
    this.selectedordertype = [];
    // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->
    this.selectedagentcode = [];
    this.agentcodeapi = [];
    // end
    this.pickupdatefrom = "";
    this.pickupdateto = "";
    this.tomindate = "";
    // module added by Kunal bansal on 2 feb 2021 start
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    // modified by Kunal bansal on 05 march 2021 start
    if (month.toString().length == 1 && day.toString().length == 2) {
      var fulldate = year + "-" + "0" + month + "-" + day;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 2 && day.toString().length == 1) {
      var fulldate = year + "-" + month + "-" + "0" + day;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else if (month.toString().length == 1 && day.toString().length == 1) {
      var fulldate = year + "-" + "0" + month + "-" + "0" + day;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }
    else {
      var fulldate = year + "-" + month + "-" + day;
      this.frommaxdate = fulldate;
      this.tomaxdate = fulldate;
    }

    // end
    //  end

    this.pushorderno = [];
    this.pushorderstatus = [];
    this.pushordertype = [];
    this.temp = undefined;
    this.temp2 = undefined;
    this.checkradio = 0;
    this.filter = [];
    this.checkedsel = 1;
    this.modalService.open(shopbycategory, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
    // api for category list start
    this.authservice.apipost('CategoryList/', {
      "flag": "filtercatgory"
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        this.category = response.response;
        this.faq = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end

    // api for Order number start

    this.authservice.apiget('static_Filter_Data/?rtype=buy').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))


        this.ordernumbers = response.response;


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end


    // api for order status
    this.authservice.apiget('static_Filter_Data/?rtype=status').subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))


        this.categorylisting = response.response;


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
    // api added by Kunal bansal on 22 march 2021 start
    this.authservice.apipost('static_Filter_Data/?rtype=agent_code', { tab: 0 }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))


        this.agentcodedata = response.response;
        this.agentcodedata.push({
          id: response.response.length + 1, "code": "Blank"
        })


      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end


  }
  //   toggleVisibility(e, a,cat1index,cat2index) {

  // // alert(e.target.checked)


  //     var index2 = this.filter.indexOf(a);


  //     if (e.target.checked == true) {
  //       $(".yup[type='checkbox']").prop('checked', true);
  //       this.filter.push(a)
  //       this.set = 1;


  //     }
  //     else {



  //           // alert(Innerindex)

  //             if(this.selectallactivate==1){
  //               this.filter.splice(index2, 1)

  //           let mThirdCat = this.category[cat1index].child_category[cat2index].child_category1;
  //           for (let Thirdindex = 0; Thirdindex < mThirdCat.length; Thirdindex++) {
  //         this.filter.splice(this.category[cat1index].child_category[cat2index].child_category1[Thirdindex],1)




  //       };
  //     }
  //     else{

  //       this.set = 0;

  //       this.filter.splice(index2, 1)
  //     }


  //     }


  //   }
  removeshowcategory(s, idchild, parent, child) {


    let index = this.filter.indexOf(idchild)

    this.Appliedcatfilters.splice(s, 1);
    this.filter.splice(index, 1)

    this.showcategory.splice(s, 1)


    this.category[parent].child_category[child].checked = false;




  }

  commonfilter() {

    var fromdate = this.pickupdatefrom.split('-').reverse().join('/');
    var todate = this.pickupdateto.split('-').reverse().join('/');
    if (this.pickupdateto.length == 0 && this.pickupdatefrom.length != 0) {
      Swal.fire("Please select To Date")
    }
    else {
      this.authservice.apipost('orderFilter/', {
        "platform": 0,
        "tab": this.selectedtab,
        "offset": 1,
        "category_id": this.filter,
        "orderdate": this.pickupdatefrom.length != 0 && this.pickupdateto.length != 0 ? fromdate + "-" + todate : "",
        "ordernumber": this.pushorderno,
        "orderstatus": this.pushorderstatus,
        "ordertype": this.pushordertype,
        "agent_code": this.agentcodeapi,
        "order_tab": "buy",
        // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start
        "payment_mode": this.selectedpayment
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.showlabel = 1;
          if (this.offset == 1) {
            this.listing = response.response.buying_list;


          }
          else {
            this.listing = this.listing.concat(response.response.buying_list);

          }
          this.totalcount = 0;
          // this.filter = [];
          //  this.realoffset=this.realoffset+1;
          // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start

          this.paymentlisting = [];
          this.selectedpayment = [];
          this.Appliedpaymentlabels = this.selectedpaymentlabels;
          this.selectedpaymentlabels = [];

          //  .........................end......................
          this.activefilter = 1;
          this.modalService.dismissAll();
          this.offset = this.offset + 1;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }


  removeapplieddate() {
    this.pickupdatefrom = "";
    this.pickupdateto = "";
    this.Applieddatefrom = [];
    this.Applieddateto = [];
    this.commonfilter();
  }

  removecatfilter(index, id) {

    this.Appliedcatfilters.splice(index, 1)
    let k = this.filter.indexOf(id);
    this.filter.splice(k, 1)
    this.commonfilter()
  }

  // New module added show appied flilter in my order by Kunal bansal on 24 nov 2020 start
  fromdate(event) {

    var fromdate = event.target.value.split('-').reverse().join('/');
    this.Applieddatefrom = fromdate;
    // added by Kunal bansal on 02feb2021 
    this.tomindate = event.target.value;




    // alert(event.target.value)
    // alert(newdate)


  }

  // end



  todate(event) {
    if (this.pickupdatefrom.length == 0) {
      Swal.fire("please select upper date first")
      this.pickupdateto = "";
    }
    else {

    }
    var todate = event.target.value.split('-').reverse().join('/');
    this.Applieddateto = todate;
  }

  // end

  // New module added show appied flilter in my order by Kunal bansal on 24 nov 2020 start
  removeordernumber(index, id) {
    this.Appliedordero.splice(index, 1)
    var ordno = this.pushorderno.indexOf(id)
    this.pushorderno.splice(ordno, 1)
    this.commonfilter();
  }
  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeorderstatus(index, id) {
    this.Appliedorderstatus.splice(index, 1)
    var ordstatus = this.pushorderstatus.indexOf(id)
    this.pushorderstatus.splice(ordstatus, 1)
    this.commonfilter();
  }
  // end

  // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
  removeordtype(index, id) {
    this.Appliedordertype.splice(index, 1)
    var ordtype = this.pushordertype.indexOf(id)
    this.pushordertype.splice(ordtype, 1)
    this.commonfilter();
  }
  // end


  toggleVisibility(e, a, catname, i, k) {


    var index2 = this.filter.indexOf(a);


    if (e.target.checked == true) {
      this.filter.push(a)
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      if (this.Appliedcatfilters.includes(catname)) {

      }
      else {
        this.Appliedcatfilters.push({ 'id': a, 'name': catname });

      }
      // end
      this.showcategory.push({ 'name': catname, "id": a, "parindex": i, "chiindex": k })





    }
    else {
      // New module added show appied flilter in inventory by Kunal bansal on 24 nov 2020 start
      var element2 = this.Appliedcatfilters.indexOf(catname);
      this.Appliedcatfilters.splice(element2, 1)
      // end
      for (let index = 0; index < this.showcategory.length; index++) {
        var element = this.showcategory[index].name;
        if (element == catname) {
          this.showcategory.splice(index, 1)

        }
      }
      this.filter.splice(index2, 1)


    }


  }

  openfaq(i) {

    if (this.temp == i) {
      this.temp = undefined;
    }
    else {


      this.temp = i

    }
  }

  selectall(a) {


    this.selectactive = 0;
    for (let index = 0; index < this.category.length; index++) {
      for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {

        if (this.filter.includes(this.category[index].child_category[Innerindex].id)) {
        }
        else {

          this.filter.push(this.category[index].child_category[Innerindex].id);
          this.Appliedcatfilters.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id });
          this.showcategory.push({ 'name': this.category[index].child_category[Innerindex].category_name, 'id': this.category[index].child_category[Innerindex].id, 'parindex': index, 'chiindex': Innerindex });
          // $(".yup[type='checkbox']").prop('checked', true);
          for (let i = 0; i < this.category.length; i++) {
            for (let k = 0; k < this.category[i].child_category.length; k++) {
              this.category[i].child_category[k].checked = true;
            }

          }
        }
      }
    }
    this.temp2 = 1;
    this.temp = 1;
  }

  Deselectall() {

    this.Appliedcatfilters = [];
    this.selectactive = 1;
    this.filter = [];
    this.showcategory = [];
    //  this.temp=undefined;
    //  this.temp2=undefined;
    for (let i = 0; i < this.category.length; i++) {
      for (let k = 0; k < this.category[i].child_category.length; k++) {
        this.category[i].child_category[k].checked = false;
      }

    }
  }

  // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 22 march 2021 start -->
  manualsearch() {
    this.offset = 1;
    this.authservice.apipost('myOrder_buy/', {
      "platform": 0,
      "tab": this.allorother,
      "offset": this.offset,
      "search_string": this.manualsearchfield
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.Applieddatefrom = [];
        this.Applieddateto = [];
        this.duplicatecatfilters = [];
        this.Appliedordero = [];
        this.Appliedorderstatus = [];
        this.Appliedordertype = [];
        this.Appliedagentcodeapi = [];
        if (this.offset == 1) {
          this.listing = response.response.buying_list;
          this.totalcount = response.response.total_count;
        }
        else {
          this.listing = this.listing.concat(response.response.buying_list);
        }
        //  this.offset=this.offset+1;
        this.manualsearchfield = "";



      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("Connect to the Internet")
        Swal.fire("Network Error")
      }
    )
    // this.listing=[];
  }
  agentchange(item: any) {
    // console.log(item)
    // alert(JSON.stringify(this.agentcodedata))
    if (this.agentcodeapi.includes(item.code)) {

    }
    else {
      this.agentcodeapi.push(item.code);
    }

    if (item.name == "Blank") {

    }
    // for(let f=0;f<this.agentcodedata.length;f++){
    //   if(this.agentcodedata[f].code=="Blank"){

    //   }
    // }
  }

  Removeagentchange(item: any) {
    if (this.agentcodeapi.includes(item.code)) {
      let k = this.agentcodeapi.indexOf(item.code);
      this.agentcodeapi.splice(k, 1);
    }
  }
  // added by Kunal bansal on 22 march 2021 start
  onSelectAllagentcode(event: any) {

    for (let z = 0; z < this.agentcodedata.length; z++) {
      if (this.agentcodeapi.includes(event[z].code)) {

      }
      else {
        this.agentcodeapi.push(event[z].code);
      }
    }
  }
  // end

  onDeSelectAllagentcode(event: any) {
    this.agentcodeapi = [];
  }
  // end

  openfaq2(c) {

    // this.checkradio = 5;
    if (this.temp2 == c) {
      this.temp = undefined;
      this.temp2 = undefined;
    }
    else {


      this.temp2 = 1;

    }

  }

  // <!-- cancel order  modal phase 4 added on 06 may 2021 by Kunal bansal-->
  cancelorder(cancel, id) {
    this.orderid = id
    this.modalService.open(cancel, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
  }


  Applyfilter(a, b) {
    var fromdate = this.pickupdatefrom.split('-').reverse().join('/');
    var todate = this.pickupdateto.split('-').reverse().join('/');




    this.storage.store('filterorders', this.filter)
    // if (this.filter.length == 0) {
    //   Swal.fire("Select at least one category filter cannot be empty")
    // }
    // else {

    if (this.pickupdateto.length == 0 && this.pickupdatefrom.length != 0) {
      Swal.fire("Please select To Date")
    }
    else {

      this.authservice.apipost('orderFilter/', {
        "platform": 0,
        "tab": a,
        "offset": b,
        "category_id": this.filter,
        "orderdate": this.pickupdatefrom.length != 0 && this.pickupdateto.length != 0 ? fromdate + "-" + todate : "",
        "ordernumber": this.pushorderno,
        "orderstatus": this.pushorderstatus,
        "ordertype": this.pushordertype,
        "agent_code": this.agentcodeapi,
        "order_tab": "buy",
        // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start
        "payment_mode": this.selectedpayment
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.duplicatecatfilters = this.Appliedcatfilters;
          this.Appliedcatfilters = [];
          this.Appliedordero = this.selectedorderno;
          this.Appliedorderstatus = this.selectedorderstatus;
          this.Appliedordertype = this.selectedordertype;
          // <!-- phase 3 new cr agent referral code module added by Kunal bansal on 02 march 2021 start -->
          this.Appliedagentcodeapi = this.agentcodeapi;
          // end

          this.showlabel = 1;
          if (this.offset == 1) {
            this.listing = response.response.buying_list;

          }
          else {
            this.listing = this.listing.concat(response.response.buying_list);
          }
          this.totalcount = response.response.total_count;
          // this.filter = [];
          //  this.realoffset=this.realoffset+1;
          // phase 4 new cr partial payment module my order filter params added by Kunal bansal on 09 august 2021 start

          this.paymentlisting = [];
          this.selectedpayment = [];
          this.Appliedpaymentlabels = this.selectedpaymentlabels;
          this.selectedpaymentlabels = [];

          
          //  .........................end......................
          this.activefilter = 1;
          this.modalService.dismissAll();
          this.offset = this.offset + 1;



        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    // }
  }


  // Applyfilter(a) {

  // this.filter=[];

  //     this.filter = [];
  // alert(this.checked)

  // for (let index = 0; index < this.category.length; index++) {
  //   for (let Innerindex = 0; Innerindex < this.category[index].child_category.length; Innerindex++) {
  //     // alert(Innerindex)
  //     if (this.category[index].child_category[Innerindex].checked==true) {
  //       if (this.filter.includes(this.category[index].child_category[Innerindex].id)) {
  //       }
  //       else {
  //        this.filter.push(this.category[index].child_category[Innerindex].id);
  //       }
  //     }
  //     let mThirdCat = this.category[index].child_category[Innerindex].child_category1;
  //     for (let Thirdindex = 0; Thirdindex < mThirdCat.length; Thirdindex++) {
  //       if (mThirdCat[Thirdindex].checked==true) {
  //         if (this.filter.includes(mThirdCat[Thirdindex].id)) {
  //         } else {
  //           this.filter.push(mThirdCat[Thirdindex].id);
  //         }
  //       }
  //     }
  //   }
  // };
  // this.setState({ visibleFilterModal: false });
  // console.log("------------selectedCategoryId------->", selectedCategoryId);
  // console.log("------------thirdLevelCategoryId-------->", thirdLevelCategoryId);
  // let mTemp = [...selectedCategoryId, ...thirdLevelCategoryId];
  // console.log("---------mTemp------->", mTemp);

  // this.storage.store('filterorder', this.filter)
  // this.authservice.apipost('orderFilter/', {
  //   "platform": 0,
  //   "tab": a,
  //   "offset": this.offset,
  //   "category_id": this.filter,
  //   "order_tab": "buy"
  // }).subscribe((res: any) => {
  //   let response = res.json();
  //   if (response.status == 400 || response.status == 500 || response.status == 100) {
  //     Swal.fire(response.message)
  //   }
  //   else if (response.status == 200) {
  //     this.activefilter = 1;
  //     this.checkedsel=1;
  //     this.offset = this.offset + 1;
  //     this.filter = [];
  //     this.modalService.dismissAll();

  //     this.listing = response.response.buying_list;
  //     this.totalcount = response.response.total_count;

  //   }
  //   else { Swal.fire("Server Down") }
  // }
  //   , err => {
  //     this.spinner.hide();
  //     Swal.fire("Connect to the Internet")
  //   }
  // )
  //   }


  getlist(list, flag) {
    this.authservice.apipost('myOrder_buy/', {
      "platform": 0,
      "tab": list,
      "offset": flag
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // alert(JSON.stringify(response.response.buying_list))
        if (flag == 1) {
          this.listing = response.response.buying_list;
        
          // alert(JSON.stringify(this.listing))
          this.totalcount = response.response.total_count;
          this.selectedtab = list


        }
        else {
          this.listing = this.listing.concat(response.response.buying_list)
        }
        this.flag = this.flag + 1
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        // Swal.fire("Connect to the Internet")
        Swal.fire("Network Error")
      }
    )
  }


  orderdetails(id) {
    this.storage.store("orderid", id);
    this.storage.store("from_buyorsell", 0);
    this.router.navigate(['/homepage/orderdetails']);
  }

  ratepro(ratepop, sid, sellername, orderid) {
    this.selectedsid = sid;
    this.sellername = sellername;
    // added by Kunal bansal on 03feb2021 to add module of giving rate to seller per order
    this.orderid = orderid;
    // end
    // alert(this.sellername)
    this.modalService.open(ratepop, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed `;
    });
    $(document).ready(function () {
      $('input.sr-only').click(function (e) {
        var tmp;
        if (e.currentTarget.id == 'radio-1') {
          tmp = '1'
        } else if (e.currentTarget.id == 'radio-2') {
          tmp = '2'
        } else if (e.currentTarget.id == 'radio-3') {
          tmp = '3'
        } else if (e.currentTarget.id == 'radio-4') {
          tmp = '4'
        } else { tmp = '5' }
        console.log(tmp)
        x = tmp
      });
    });
  }

  submitrate() {
    if (x == 0 || x == undefined) {
      Swal.fire('Please rate the seller first')
    }
    else {
      this.authservice.apipost('sellerReviews/', {
        rating: x,
        product_seller_id: this.selectedsid,
        // added by Kunal bansal on 03feb2021 to add module of giving rate to seller per order
        order_id: this.orderid,
        // end
        brief: this.reviews
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message);
        }
        else if (response.status == 200) {
          location.reload();
        }
        else { Swal.fire("Server Down"); }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet");
        }
      )
    }
  }

  closeall() {
    this.modalService.dismissAll();
    x = 0;
  }

  markreceived(oid) {
    this.authservice.apipost('orderAction/', {
      action: "Received",
      order_id: oid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        Swal.fire({
          title: response.message,
          allowOutsideClick: false,
          showCancelButton: false,
        }).then((result) => {
          if (result.value) {
            location.reload();
          }
        })
        this.modalService.dismissAll();
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // phase 4 cancellation module added by Kunal bansal on 21 may 2021 start
  cancelor() {
    if (this.cancelcategory == '' || this.cancelcategory == null) {
      Swal.fire('Please select a valid category from the dropdown');
    }
    else if (this.cancelreason == '' || this.cancelreason == null) {
      Swal.fire('Please add a valid reason for cancelling the order');
    }
    else {
      this.authservice.apipost('orderAction/', {
        action: "Cancelled",
        order_id: this.orderid,
        special_notes: this.cancelcategory,
        reason: this.cancelreason
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.activefilter = 0;
          this.modalService.dismissAll();
          location.reload();
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  // end

  viewchange() {

    if (this.allorother == 0) {

      this.allorother = 1;


      this.flag = 1
      // if (this.activefilter == 0) {
      // added on 25jan2021 to add above line by Kunal bansal 
      this.getlist(1, 1)
      this.storage.store("myorderbuytab", 1)
      this.Applieddatefrom = [];
      this.Applieddateto = [];
      this.duplicatecatfilters = [];
      this.Appliedordero = [];
      this.Appliedorderstatus = [];
      this.Appliedordertype = [];
      this.Appliedagentcodeapi = [];
      // }
      // else {
      //   this.filter = this.storage.retrieve('filterorder')
      //   this.offset = 1;
      //   this.Applyfilter(1, 0)
      //   this.storage.store("myorderbuytab",1)
      // }
      //console.log(this.listing+"total")
    }
    else {

      this.allorother = 0;

      // added on 25jan2021 to add above line by Kunal bansal 
      this.flag = 1
      // if (this.activefilter == 0) {
      this.getlist(0, 1)
      this.storage.store("myorderbuytab", 0)
      this.Applieddatefrom = [];
      this.Applieddateto = [];
      this.duplicatecatfilters = [];
      this.Appliedordero = [];
      this.Appliedorderstatus = [];
      this.Appliedordertype = [];
      this.Appliedagentcodeapi = [];
      // }
      // else {
      //   this.filter = this.storage.retrieve('filterorder')
      //   this.offset = 1;
      //   this.Applyfilter(0, 1)
      //   this.storage.store("myorderbuytab",0)
      // }
      //console.log(this.listing+"open")
    }
  }


}
