import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-sell-list',
  templateUrl: './sell-list.component.html',
  styleUrls: ['./sell-list.component.css']
})
export class SellListComponent implements OnInit {
  @Input() Buyselllist: any;
  p: number = 1;
  
  constructor(private storage: SessionStorageService, private router: Router) { }

  ngOnInit() {
  }

  productdetail(id) {  
    this.storage.store("pdid", id);
    this.storage.store("ptype", 1);
    this.router.navigate(['/homepage/productdetail']);
  }

}