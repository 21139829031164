import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'app-Videoplayer',
  templateUrl: './Videoplayer.html',
  styleUrls: ['./Videoplayer.css']
})

export class Videoplayer implements OnInit {
  video: any[];

  constructor(private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.video = this.storage.retrieve('videostorage');
    // alert(JSON.stringify(this.video))

  }

  ngOnDestroy() {
    this.storage.clear('videostorage')
  }

  skip(value) {
    let video: any = document.getElementById("my_video_1");
    video.currentTime += value;
  }
}