import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-buy-request',
  templateUrl: './buy-request.component.html',
  styleUrls: ['./buy-request.component.css']
})
  
export class BuyRequestComponent implements OnInit {
  buydata: any;
  id: any;
  pos1:string;
  pos2:string;
  pos3:string;
pos1name:string;
pos2name:string;
pos3name:string;
prop1select:any[];
prop2select:any[];
prop3select:any[];
  comments: string ;
  price: string ;
  quantity: string ;
  categoryid:number;
  productname:string='';
  
  constructor(private storage: SessionStorageService, private router: Router, private authservice: AuthService,private spinner: NgxSpinnerService) { }

  ngOnInit() {



    this.categoryid=this.storage.retrieve('productdetailscatid');
    
    this.productname=this.storage.retrieve('detailproductname');
    this.id = this.storage.retrieve('buydata');
    if (this.id == null || this.id == undefined) {
      this.router.navigate(['/homepage/productlisting']);
    }
    else {
      
      this.want();
      // this.authservice.apipost('buyRequest/?request_flag=0', {
      //   "product_id": this.id
      // }).subscribe((res: any) => {
      //   let response = res.json();
      //   if (response.status == 400 || response.status == 500 || response.status == 100) {
      //     Swal.fire(response.message);
      //   }
      //   else if (response.status == 200) {
      //     this.buydata = response.response;
      //   }
      //   else { Swal.fire("Server Down"); }
      // }
      //   , err => {
      //     this.spinner.hide();
      //     Swal.fire("Connect to the Internet");
      //   }
      // )
    }
  }

  want(){
   
   
      // this.authservice.apipost('buyRequest/?request_flag=0', {
        // this.authservice.apipost('wanrMoreQauntity/', {
          this.authservice.apipost('buyRequest/?request_flag=0', {
          "product_id":this.id,
      "category_id":this.categoryid
    }).subscribe((res: any) => {
      let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
       
       this.pos1name=response.response.option_list2[0].db_key;
       this.pos2name=response.response.option_list2[1].db_key;
       this.pos3name=response.response.option_list2[2].db_key;
       this.prop1select=response.response.option_list2[0].p_value;
       this.prop2select=response.response.option_list2[1].p_value;
       this.prop3select=response.response.option_list2[2].p_value;
      
      
        }
        else { Swal.fire("Server Down") }
      }
        , err => {   
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
    )
  }
  

submit() {
  // if (this.memory == undefined || this.color == undefined || this.internal == undefined || this.price == undefined || this.quantity == undefined || this.comments == undefined) {
  //   Swal.fire('Please fill in the mandatory fields');
  // }
  if (this.pos1 == undefined || this.pos2 == undefined || this.pos3 == undefined || this.price == undefined || this.quantity == undefined || this.comments == undefined) {
    Swal.fire('Please fill in the mandatory fields');
  }
  else {
    this.authservice.apipost('buyRequest/?request_flag=1', {
        "product_id":this.id,
        "category_id":this.categoryid,
        "position1":this.pos1,
        "position2":this.pos2,
        "position3":this.pos3,
        "price":this.price,
        "quantity":this.quantity,
        "comment":this.comments
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        Swal.fire(response.message);
        this.router.navigate(['/homepage/productlisting']);
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }
 
}

}