import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {
  CouponList: any[];
  psellerid: any[];
  code: string;
  from: number;



  constructor(private storage: SessionStorageService, private authservice: AuthService, private spinner: NgxSpinnerService,
    private modalService: NgbModal, private router: Router) { }

  ngOnInit() {

    this.from = this.storage.retrieve('cart_from');
    this.psellerid = JSON.parse(this.storage.retrieve('p_seller_id'));
// new param "product_seller_id" added in couponlist on 30june 2020 by kunal 
    this.authservice.apipost('CouponList/', { payment_flag: JSON.parse(this.storage.retrieve('paymenttype')),product_seller_id:this.psellerid }).subscribe((res: any) => {//modified by Kunal retreive data coming from cart.components.th

      let response = res.json();
      if (response.status == 102 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message);
      }
      else if (response.status == 200) {
        this.CouponList = response.response;
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )
  }
  ngOnDestroy(){
    if (window.location.pathname == "/homepage/cart"){
     
    }
    else{
      this.storage.store('checkcodpre',1);
      this.storage.clear("walletactive");
       // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 15 sep 2021 start -->
      this.storage.store("placeorderbuyerradio", "self");
      this.storage.clear("selectedbuyerdetail");
       // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 20 sep 2021 start -->
      this.storage.store("verifyotp",false);
      this.storage.store("generateotpactive", 0)
    }
  }
  apply(i) {
    if (i == 0) {
      if (this.code == undefined || this.code.length < 6) {
        Swal.fire("Please enter a valid coupon")
      }
      else {
        console.log(this.code, "call validatecoupon function")
        this.validatecoupon(this.code)
      }
    }
    else {
      console.log(i, "call validatecoupon function")
      this.validatecoupon(i)
    }
  }
  validatecoupon(coup) {

    this.authservice.apipost('ValidateCoupon/', {
      "coupon": coup,
      "product_seller_id": this.psellerid
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 102 || response.status == 500 || response.status == 100) {

        Swal.fire(response.message);

      }
      else if (response.status == 200) {

        //if(this.from == 1){
        this.storage.store("coupon", coup)
        this.router.navigate(['/homepage/cart'])
        // }
        // else{
        //   //navigate to cart api
        // }
      }
      else { Swal.fire("Server Down"); }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet");
      }
    )

  }

}
