import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import * as $ from 'jquery';
import Swal from 'sweetalert2';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

let myVarsecond;
let myVar;

@Component({
  selector: 'app-signup-verification',
  templateUrl: './signup-verification.component.html',
  styleUrls: ['./signup-verification.component.css']
})

export class SignupVerificationComponent implements OnInit {
  signupdata: any = {};
  childcategory: any = [];
  // phase 5 otp timer module added by Kunal bansal on 16 sep 2021 start -->
  otptimerminute: any;
  permanentotptimestore: any;
  otptimersecond: any = 60;
  otpbuttondisabled = true;
  otpvisible: boolean = true;
  selectedcountry: string;

  constructor(private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) { }

  ngOnInit() {

    // phase 5 otp timer module added by Kunal bansal on 16 sep 2021 start -->
    clearInterval(myVar);
    clearInterval(myVarsecond);
    this.signupdata = this.storage.retrieve('signupdata');
    let otpconcat;
    otpconcat=this.storage.retrieve("signupotptiming");
    if(otpconcat<10){
      let addzero;
      addzero=this.storage.retrieve("signupotptiming")-1;
      this.otptimerminute = "0"+addzero;
    }
    else{
      this.otptimerminute = this.storage.retrieve("signupotptiming")-1;
    }
  
    // this.setotptimer();
    if(otpconcat==0){

    }
    else{
      this.setotptimersecond();
    }
    
    // ...end...
    this.selectedcountry = this.storage.retrieve("signupverifycountry");
    this.childcategory = JSON.stringify(this.storage.retrieve('childcategorystore'));
    if (this.selectedcountry == "UAE") {
      Swal.fire('OTP has been sent to your email address')
    }
    else {
      Swal.fire('OTP has been sent to your Email address and Mobile number')
    }

    // console.log(this.childcategory);


    $(document).ready(function () {
      $('.x').keypress(function (evt) {
        if (evt.which != 8) {
          if (this.id == "first") {
            $('#second').focus()
          }
          else if (this.id == "second") {
            $('#third').focus()
          }
          else if (this.id == "third") {
            $('#fourth').focus()
          }
          else if (this.id == "fourth") {
            //$('#fifth').focus()
          }
        }
      })
      $('.xy').keypress(function (evt) {
        if (evt.which != 8) {
          if (this.id == "first1") {
            $('#second2').focus()
          }
          else if (this.id == "second2") {
            $('#third3').focus()
          }
          else if (this.id == "third3") {
            $('#fourth4').focus()
          }
          else if (this.id == "fourth4") {
            //$('#fifth5').focus()
          }
        }
      })
    });

  }

  // phase 4 search place order on behalf of buyer added by Kunal bansal on 16 sep 2021 start -->
  setotptimer() {
    myVar = setInterval(() => {
      if (this.otptimerminute <= 10) {
        let otpminute;

        otpminute = this.otptimerminute - 1;
        this.otptimerminute = "0" + otpminute;
      }
      else {
        this.otptimerminute = this.otptimerminute - 1;
      }
      // this.otptimerminute = this.otptimerminute - 1;
      if (this.otptimerminute == 0) {
        clearInterval(myVar);
        // clearInterval(myVarsecond);
        // this.otptimerminute = 30;
        // this.otptimersecond = 60;
        // this.setotptimer();
        // this.setotptimersecond();
      }
    }, 60000);

  }

  // <!-- phase 5 place order on behlf of buyer added by Kunal bansal on 10 nov 2021 start -->
  automaticmoveonestep(i, event) {
    if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 96 && event.keyCode <= 105)) {
      if (i == 1) {
        $("#second").focus();

      }
      else if (i == 2) {
        $("#third").focus();

      }
      else if (i == 3) {
        $("#fourth").focus();


      }
      else {

      }


    }
    else {
      return false
    }

    // if (i == 4) {
    //   if (event.code == "Backspace") {
    //     $("#third").focus();
    //   }
    // }


  }
  // ...end...

  setotptimersecond() {
    myVarsecond = setInterval(() => {


      if (this.otptimersecond <= 10) {
        let otp;

        otp = this.otptimersecond - 1;
        this.otptimersecond = "0" + otp;
      }
      else {
        this.otptimersecond = this.otptimersecond - 1;
      }
      if (this.otptimersecond == 0) {
        if (this.otptimerminute == 0) {
          // this.otptimersecond = 60;
          // setTimeout(() => {

          this.otptimersecond = "0" + 0;
          clearInterval(myVarsecond);
          this.otpbuttondisabled = false;
          // }, 60000);


        }
        else {
          this.otptimersecond = 60;
          if (this.otptimerminute <= 10) {
            let otpminute;

            otpminute = this.otptimerminute - 1;
            this.otptimerminute = "0" + otpminute;
          }
          else {
            this.otptimerminute = this.otptimerminute - 1;
          }
        }


      }
    }, 1000);
  }

  lastseconds() {

    myVarsecond = setInterval(() => {


      if (this.otptimersecond <= 10) {
        let otp;

        otp = this.otptimersecond - 1;
        this.otptimersecond = "0" + otp;
      }
      else {
        this.otptimersecond = this.otptimersecond - 1;
      }
      if (this.otptimersecond == 0) {




        this.otptimersecond = "0" + 0;
        clearInterval(myVarsecond);
        this.otpbuttondisabled = false;





      }
    }, 1000);

  }


  resetotp(expiretime) {
    if(expiretime==0){

    }
    else{
      let time = this.permanentotptimestore;
      this.otpvisible = true;
      this.otptimerminute = time;
      this.otptimersecond = 60;
      this.otpbuttondisabled = true;
      clearInterval(myVar);
      clearInterval(myVarsecond);
  
      // this.setotptimer();
      if (expiretime == 1) {
        this.lastseconds();
      }
      else {
        this.setotptimersecond();
      }
    }
   

  }

  generateotp() {

    let formData = new FormData();
    formData.append("email", this.signupdata.email),
      formData.append('phone', this.signupdata.phone),
      formData.append('sms_otp', ''),
      formData.append('email_otp', ''),
      formData.append('agent_code', this.signupdata.agent_code),
      // formData.append('category', JSON.stringify(this.childcategory)),
      formData.append('country', this.selectedcountry),

      this.authservice.formdata('registerUser/', formData).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          let concatminute=response.expiry_time-1;
          if(response.expiry_time<10){
           this.otptimerminute = "0"+concatminute;
           this.permanentotptimestore = "0"+concatminute;
          }
          else{
           this.otptimerminute = response.expiry_time-1;
           this.permanentotptimestore = response.expiry_time-1;
          }
          if (this.selectedcountry == "UAE") {
            Swal.fire('OTP has been sent to your email address')
          }
          else {
            Swal.fire('OTP has been sent to your Email address and Mobile number')
          }
          this.resetotp(response.expiry_time);
    // this.otpvisible = true;
    // this.otpbuttondisabled=true;
    // this.setotptimer();
    // this.setotptimersecond();
    // ...end...
  }
        else { Swal.fire("Server Down") }
      }
        , err => {
  this.spinner.hide();
  Swal.fire("Connect to the Internet")
}
      )

  }

// ...end...

// ...end....

// one otp field module added by Kunal bansal on 28 aug 2020 

validate() {

  let phoneotp = $('#first').val() + "" + $('#second').val() + "" + $('#third').val() + "" + $('#fourth').val();
  // let emailotp = $('#first1').val() + "" + $('#second2').val() + "" + $('#third3').val() + "" + $('#fourth4').val();
  let emailotp = $('#first').val() + "" + $('#second').val() + "" + $('#third').val() + "" + $('#fourth').val();
  if ($('#first').val() == "" || $('#second').val() == "" || $('#third').val() == "" || $('#fourth').val() == "") {
    Swal.fire("Please enter a valid OTP which we have sent to your Mobile number.")

  }


  // else if ($('#first1').val() == "" || $('#second2').val() == "" || $('#third3').val() == "" || $('#fourth4').val() == "") {
  //   Swal.fire("Please enter a valid OTP which we have sent to your Email Id.")
  // }
  else {
    // console.log(this.signupdata)
    // console.log(phoneotp)
    // <!-- .....Phase 5 new cr Country selection module raw data form converterted to formdata added by Kunal bansal on 17 aug 2021 start........
    this.signupdata["sms_otp"] = phoneotp;
    this.signupdata["email_otp"] = emailotp;
    this.signupdata["device_token"] = "0";
    this.signupdata["device_type"] = "5";
    delete this.signupdata['confirmpassword'];
    // ..............raw data form commented by Kunal bansal on 17 aug 2021 to add phase 5 country module with formdata length start......
    // this.authservice.apipost('registerUser/', this.signupdata).subscribe((res: any) => {
    //   let response = res.json();
    //   if (response.status == 400 || response.status == 500 || response.status == 100) {
    //     Swal.fire(response.message)

    //   }
    //   else if (response.status == 200) {
    //     this.storage.store("username", response.response.username);
    //     this.storage.store("userid", response.response.id);
    //     this.storage.store("token", response.response.token);
    //     this.storage.store("islogged", 1);
    //     this.router.navigate(['/homepage/productListing'], { replaceUrl: true });
    //   }
    //   else { Swal.fire("Server Down") }
    // }
    //   , err => {
    //     this.spinner.hide();
    //     Swal.fire("Connect to the Internet")
    //   }
    // )
    // ...end...

    // <!-- .....Phase 5 new cr Country selection module raw data form converterted to formdata added by Kunal bansal on 17 aug 2021 start........
    let formData = new FormData();
    let signup = this.signupdata;
    formData.append('sms_otp', phoneotp),
      formData.append('email_otp', emailotp),
      formData.append('device_token', "0"),
      formData.append('device_type', '5'),
      // phase 5 cr country module added by 17 aug 2021 by Kunal bansal start
      formData.append('name', signup.name.trim()),
      formData.append('email', signup.email.trim()),
      formData.append('password', signup.password),
      formData.append('country', signup.country),
      formData.append('pincode', signup.pincode),
      formData.append('state', signup.state),
      formData.append('city', signup.city),
      formData.append('store_name', signup.store_name.trim()),
      formData.append('display_name', signup.display_name),
      formData.append('user_business_type', signup.user_business_type),
      formData.append('location_type', signup.location_type),
      formData.append('trn_no', signup.trn_no),
      formData.append('trade_license', signup.tradelicense == undefined ? "" : signup.tradelicense),
      formData.append('additional_doc', signup.additional_doc == undefined ? "" : signup.additional_doc),
      formData.append('phone', signup.phone),
      formData.append('notify_allow', signup.notify_allow)
    formData.append('user_location', signup.uaeselectedlocation)
    //  phase 5 modified by KUnal bansal due to app scenatio not working on 14 sep 2021
    let mcheck = JSON.parse(this.childcategory)
    for (let item = 0; item < mcheck.length; item++) {
      // console.log(this.childcategory[item])
      formData.append("category", mcheck[item].toString());
    }




    // formData.append('category',this.childcategory)

    this.authservice.formdata('registerUser/', formData).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)

      }
      else if (response.status == 200) {
        this.storage.store("username", response.response.username);
        this.storage.store("userid", response.response.id);
        this.storage.store("token", response.response.token);
        this.storage.store("islogged", 1);
        this.router.navigate(['/homepage/productListing'], { replaceUrl: true });
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
}

}
