import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionStorageService} from 'ngx-webstorage';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor( private storage:SessionStorageService, private router: Router){  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let x = this.storage.retrieve('islogged');
      if (x==0|| x== undefined || x == null){
        //console.log('logged out')
        this.router.navigate(['homepage/login']);
      }
      else {
        //console.log('logged in')
        return true;
      }
  }
}