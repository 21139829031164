export const environment = {
  production: true,
  baseurl: 'https://eoladmin.softprodigyphp.in/app_api/',
  invshare: "https://www.eolstocks.com/homepage/productdetail?pid=",
  razor_key: 'rzp_test_Xl0iS8BgLClxsP',
  serverUrl: 'https://adminweb.eolstocks.com/api'
};


// export const environment = {
//   production: false,
//   // http://eoladmin.softprodigyphp.in/app_api/
//   baseurl : ' http://eoladmin2.softprodigyphp.in/app_api/',
//   razor_key : 'rzp_test_Xl0iS8BgLClxsP',
//   serverUrl:'http://eollaravel-env.eba-kvexd4hc.ap-south-1.elasticbeanstalk.com/api'
// };