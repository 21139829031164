import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Options, LabelType } from 'ng5-slider';
import { SessionStorageService } from 'ngx-webstorage';


@Component({
  selector: 'app-Brandlisting',
  templateUrl: '../commondesign/commondesign.html',
  // templateUrl: './Brandlisting.html',
  styleUrls: ['./Brandlisting.css']
})

export class Brandlisting implements OnInit {
  categoryid: number;
  parentid: number;
  routeid: number
  realmaxload: number;
  realoffset: number = 1;
  tab: number;
  selectedbrandid: number;
  buylist: any[];
  selllist: any[];
  brands: any[];
  banners: any[];
  brandid: number;
  brandactive: number = 0;
  filteractive: number = 0;
  category: any[];
  filterdata: any[];
  dropdownSettings = {};
  dropdownSettings2 = {};
  selectedhighrange: number;
  selectedlowrange: number;
  selectedbrand = [];
  selectedItems = [];
  selectedItems2 = [];
  selectedcolor = [];
  selectedmemory = [];
  selectedstorage = [];
  selectedbattery = [];
  selecteddisplay = [];
  categorylevel3: any[];
  selectedItems3: any[];
  subcategory: any[];
  subcategoryactive: number = 0;
  setfilactive: number = 0;

  sortcheck: number;
  sortactive: number = 0;

  filter1 = [];
  filter2 = [];
  filter3 = [];
  brands1 = [];
  brands2 = [];
  brands3 = [];
  brands4 = [];
  brands5 = [];
  brands6 = [];
  selectedFilters = [];
  parentname: string;
  catfilters: string;
  superparentname: string;
  superparentid: number;

  productlength: number = 4;

  checkhomepagebrandactive: number = 0;
  homepagetab: number;
  homepagebrandid: number;
  // Geolocation module added by Kunal bansal on 23 june 2021 start
  loggedstatus: number;
  locationstatus: number;
  Districtname: string;
  // end
  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
  countrylocation: string;

  // ...end...
  storelabel = [];

  //  phase 5 category wise view on branch and search added by Kunal bansal on 24 sep 2021 start
  labellisting = [];
  parentlabel: string;
  buysell: number;
  activepage: string = "brandpage";

  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b>' + value;
        case LabelType.High:
          return '<b>Max price:</b>' + value;
        default:
          return '' + value;
      }
    }
  };
 

  constructor(private storage: SessionStorageService, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) { }


  @HostListener("window:scroll", ["$event"])

  onWindowScroll(e) {
    //  phase 5 pagination scenario removed as per discussion with client
    // let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
    // let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
    // let max = document.documentElement.scrollHeight;
    // let max1 = document.body.scrollHeight - 10;
    // console.log(Math.round(pos) + " == " + max + " and " + Math.round(pos1) + " == " + max1)
    // if (Math.round(pos) == max) {

    // alert('reached')
    // pagination issue fixed by Kunal bansal on 05feb2021
    // if (this.tab == 0 && this.realmaxload > this.buylist.length || this.tab == 1 && this.realmaxload > this.selllist.length) {
    // this.defaultapi(this.realoffset)

    // this.brandscreenapi();
    // alert('yes')
    //   }
    // }
  }

  ngOnInit() {
    // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
    this.countrylocation = sessionStorage.getItem("phase5countrylocation");
    // ...end...
    this.routeid = this.storage.retrieve('categorybrandid');
    this.storelabel = this.storage.retrieve('brandlabelslisting');
    if (this.storelabel == null) {
      this.storelabel = [];
    }

    this.tab = this.storage.retrieve('categorytab');
    // New scenario if link copy and open directly it should navigate to homepage directly start added by Kunal on 07 july 2021
    this.tab = this.storage.retrieve('categorytab');
    this.buysell = this.tab;
    if (this.tab == null) {
      this.router.navigate(['/homepage/productListing']);

    }
    else {
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      this.locationstatus = this.storage.retrieve("locationstatus");
      this.loggedstatus = this.storage.retrieve("islogged");
      this.Districtname = this.storage.retrieve("districtname");
      // end


      this.checkhomepagebrandactive = this.storage.retrieve("checkhomepagebrandactive");
      if (this.checkhomepagebrandactive == 1) {

        this.routeid = this.storage.retrieve("homepagebrandid");
        this.tab = this.storage.retrieve("homepagebrandtab");
        this.buysell = this.tab;
        this.brands = this.storage.retrieve("homepagebrands");
        this.banners = this.storage.retrieve("homepagebanners");
        this.storelabel == null ? this.brandscreenapi() : this.defaultbrandlisting();



      }
      else {

        this.brands = this.storage.retrieve('brandbrands');
        this.banners = this.storage.retrieve('brandbanners');

        this.storelabel == null ? this.brandscreenapi() : this.defaultbrandlisting();




      }


      this.superparentid = this.storage.retrieve('supparentid')
      this.superparentname = this.storage.retrieve('supparentname')
      // this.storage.store('supparentid',supparid)
      //   this.storage.store('supparentname',supparname)

      this.categoryid = this.storage.retrieve('catid')
      this.catfilters = this.storage.retrieve('categoname')
      this.parentid = this.storage.retrieve('parid')
      this.parentname = this.storage.retrieve('parentcategoryname')

      // this.tab = this.storage.retrieve('cattab')

      // alert(this.parentname)


      // this.checkapi();
      // this.Categoryid(this.tab)

      this.dropdownSettings = {
        singleSelection: false,
        enableFilterSelectAll: false,
        enableSearchFilter: false,
        text: "Select",
        labelKey: "value",
        primaryKey: "Id",
        badgeShowLimit: 4,
        classes: "font_drop",
        maxHeight: 200
      };
      // Added by Kunal bansal on 2 july 2020 start
      this.dropdownSettings2 = {
        singleSelection: true,
        enableFilterSelectAll: false,
        enableSearchFilter: false,
        text: "Select",
        labelKey: "category_name",
        classes: "font_drop",
        maxHeight: 200
      };
    }
    // alert(this.sellerid)
    // alert(JSON.stringify(this.sellerproductlisting))
  }


  buysellchange(b) {
    this.tab = b;
    this.buysell = b;
    this.storage.store('categorytab', b);
    this.storage.store('pressedkey', b)
    this.storage.store("homepagebrandtab", b);
    this.realoffset = 1;
    if (this.filteractive == 0 && this.sortactive == 0) {
      this.checkapi()
    }
    else if (this.sortactive == 1) {
      this.sortapi(this.sortcheck, 1)
    }
    else if (this.brandactive == 1) {
      this.brandapi(this.routeid)
    }
    else {
      this.filterapi(1)
    }

  }

  checkapi() {
    if (this.brandactive == 1) {
      this.brandapi(this.routeid)
    }
    else {
      this.Categoryid(this.tab)
    }

  }

  closeall() {
    this.modalService.dismissAll();


  }


  Categoryid(b) {

    this.authservice.apipost('homePage/', {
      "platform": 0,
      "tab": b,
      "offset": 1,
      "category": this.superparentname == '' ? this.parentid : this.superparentid,
      "categorylev2": this.superparentname == '' ? [this.categoryid] : [this.parentid],
      "subcategory": this.superparentname == '' ? [] : [this.categoryid],
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {



        this.realoffset = this.realoffset + 1;
        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {

          this.brands = response.response.brand_details;
          this.banners = response.response.banners;
          this.buylist = this.realoffset == 1 ? this.buylist.concat(response.response.buying_list) : response.response.buying_list;


        }
        else {
          this.banners = response.response.banners;
          this.brands = response.response.brand_details;
          this.selllist = this.realoffset == 1 ? this.selllist.concat(response.response.selling_list) : response.response.selling_list;
        }
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // phase 5 added by Kunal bansal on 27 sep 2021 start

  onlybrandcallapi(id) {
    this.routeid = id;
    // this.storage.store('categorybrandid',id);
    this.authservice.apipost('getBrandProductList/', {
      "brandid": id,
      "platform": 0, //0 for web and 1 for app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": 1,
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      "location": this.locationstatus && this.tab == 0 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation,
      "remove_list": []
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.storelabel = [];
        this.storage.clear("brandlabelslisting");
        // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
        this.labellisting = response.response.request_data;
        this.parentlabel = response.response.brand_name;
        // ...end...
        this.brandactive = 1;

        this.selectedbrandid = id;
        // this.catfilters = '';
        this.storage.store("homepagebrandid", id);

        this.realoffset = this.realoffset + 1;

        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {

          this.buylist = response.response.buying_list;

        }
        else {

          this.selllist = response.response.selling_list;
        }
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // ...end...

  Filtergoing(a) {

    if (a == 2) {

      this.authservice.apipost('homePage/', {
        "platform": 0,
        "tab": 0,
        "offset": 1,
        "category": this.superparentname == '' ? this.parentid : this.superparentid,
        "categorylev2": this.superparentname == '' ? [this.categoryid] : [this.parentid],
        "subcategory": [],
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.catfilters = '';
          this.storage.store('categoname', '')

          this.realoffset = this.realoffset + 1;
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {

            this.brands = response.response.brand_details;
            this.banners = response.response.banners;
            this.buylist = response.response.buying_list;
            // this.buylist = this.realoffset == 1 ? this.buylist.concat(response.response.buying_list) : response.response.buying_list;

          }
          else {
            this.banners = response.response.banners;
            this.brands = response.response.brand_details;
            this.selllist = response.response.selling_list;
            // this.selllist = this.realoffset == 1 ? this.selllist.concat(response.response.selling_list) : response.response.selling_list;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else if (a == 1) {

      this.authservice.apipost('homePage/', {
        "platform": 0,
        "tab": 0,
        "offset": 1,
        "category": this.superparentname == '' ? this.parentid : this.superparentid,
        "categorylev2": [],
        "subcategory": [],
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.parentname = '';
          // this.storage.store('parentcategoryname', '')
          // this.superparentname='';
          // this.storage.store('supparentname','');
          if (this.superparentname == '') {
            this.router.navigate(['/homepage/categorylist']);
          }

          this.realoffset = this.realoffset + 1;
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {

            this.brands = response.response.brand_details;
            this.banners = response.response.banners;
            this.buylist = this.realoffset == 1 ? this.buylist.concat(response.response.buying_list) : response.response.buying_list;

          }
          else {
            this.banners = response.response.banners;
            this.brands = response.response.brand_details;
            this.selllist = this.realoffset == 1 ? this.selllist.concat(response.response.selling_list) : response.response.selling_list;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else if (a == 5) {
      this.router.navigate(['/homepage/productListing']);
    }


  }



  // if (window.location.pathname == "/homepage/productListing") {

  //   location.reload();
  // }
  // else {

  //   this.router.navigate(['/homepage/productListing']);
  // }


  // end


  defaultbrandlisting() {
    this.authservice.apipost('getBrandProductList/', {
      "brandid": this.routeid,
      "platform": 0, //0 for web and 1 for app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": 1,
      "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
      "country": this.countrylocation,
      "remove_list": this.storelabel
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.brandactive = 1;
        this.selectedbrandid = this.routeid;

        // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
        this.labellisting = response.response.request_data;
        this.parentlabel = response.response.brand_name;
        // ...end...
        // this.catfilters = '';
        // pagination issue for samsung for fixed by Kunal bansal on 05feb2021
        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {

          this.buylist = response.response.buying_list
          this.labellisting = response.response.request_data;
          this.realoffset = this.realoffset + 1;
        }
        else {

          this.selllist = response.response.selling_list;
          this.realoffset = this.realoffset + 1;
        }
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }

  // <!-- phase 5 new scenario added by Kunal bansal on 27 sep 2021 start -->
  Removelabel(id, child) {
    if (id == 0) {
      this.storage.clear('brandlabelslisting');
      this.router.navigate(['/homepage/productListing']);
    }
    else {


      this.storelabel.push(id);
      // phase 5 auto child append for removal added by Kunal bansal on 01 oct 2021 start
      if (child != undefined) {
        if (child.length > 0) {
          for (let b = 0; b < child.length; b++) {
            this.storelabel.push(child[b])
          }
        }
      }
      // ...end...
      this.storage.store('brandid', this.routeid);
      this.storage.store('brandlabelslisting', this.storelabel);
      this.authservice.apipost('getBrandProductList/', {
        "brandid": this.routeid,
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": 1,
        "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
        "country": this.countrylocation,
        "remove_list": this.storelabel
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          this.brandactive = 1;
          this.selectedbrandid = this.routeid;
          // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
          this.labellisting = response.response.request_data;
          this.parentlabel = response.response.brand_name;

          // ...end...
          // this.catfilters = '';
          // pagination issue for samsung for fixed by Kunal bansal on 05feb2021
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {

            this.buylist = response.response.buying_list
            this.labellisting = response.response.request_data;
            this.realoffset = this.realoffset + 1;
          }
          else {

            this.selllist = response.response.selling_list;
            this.realoffset = this.realoffset + 1;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }
  //  ...end...

  removefilter(i, index) {
    if (i == 1) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems.splice(index, 1);
      this.selectedItems2.splice(index, 1);
      this.selectedItems3.splice(index, 1);
      this.selectedlowrange = 0;
      this.selectedhighrange = 0;
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];

      this.filterapi(1)
    }
    if (i == 12) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems2.splice(index, 1);
      this.selectedItems3.splice(index, 1);
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];

      this.filterapi(1)
    }
    if (i == 13) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems3.splice(index, 1);
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];

      this.filterapi(1)
    }
    if (i == 2 && this.brands1.length != 0) {

      this.selectedbrand.splice(index, 1)

      this.filterapi(1)
    }
    if (i == 3 && this.brands2.length != 0) {

      this.selectedcolor.splice(index, 1)

      this.filterapi(1)
    }
    if (i == 4 && this.brands3.length != 0) {

      this.selectedmemory.splice(index, 1)

      this.filterapi(1)
    }
    if (i == 5 && this.brands4.length != 0) {

      this.selectedstorage.splice(index, 1)

      this.filterapi(1)
    }
    if (i == 6 && this.brands5.length != 0) {

      this.selectedbattery.splice(index, 1)

      this.filterapi(1)
    }
    if (i == 7 && this.brands6.length != 0) {

      this.selecteddisplay.splice(index, 1)

      this.filterapi(1)
    }
  }
  // end

  sortmoadal(sort) {
    this.modalService.open(sort, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  }

  sortapi(i, offset) {
    this.sortcheck = i;

    this.authservice.apipost('homePage/?sort_flag=' + i, {
      "platform": 0, //0 for web and 1 for app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": offset,
      // Geolocation module added by Kunal bansal on 25 june 2021 start
      "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",

      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.sortactive = 1;
        if (offset != 1) { this.realoffset = this.realoffset + 1; }
        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {
          this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
        }
        else {
          this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
        }

        this.modalService.dismissAll();
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  filtermoadal(filter) {
    // this.selectedbrand = [];
    // this.selectedItems = [];
    // this.selectedItems2 = [];
    // this.selectedcolor = [];
    // this.selectedmemory = [];
    // this.selectedstorage = [];
    // this.selectedbattery = [];
    // this.selecteddisplay = [];
    this.authservice.apipost('filter_by/', { "category_id": 3 }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.filterdata = [];
        this.options.floor = response.response.min_price;
        this.selectedlowrange = 0;
        this.selectedhighrange = 0;
        this.options.ceil = response.response.max_price;

        this.modalService.open(filter, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    // Added by Kunal on 02 july 2020 start
    this.authservice.apipost('CategoryList/', {
      flag: 'category'

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.category = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }


  // function added by Kunal for category based filter added on 2 july 2020 start
  onItemSelect(a) {

    this.filterdata = [];
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;
    // Added by Kunal on 02 july 2020 start
    this.authservice.apipost('CategoryList/', {
      "category_id": [a], "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems2 = [];
        this.subcategory = response.response;
        this.subcategoryactive = 1;

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }
  // module added by KUnal bansal in case subcategory remove if category array empty 2 july 2020 start
  // OnItemDeSelect($event){
  //  this.subcategoryactive=0;
  //  this.selectedItems2=[];
  // }
  // end


  // added on 06 july 2020 by Kunal bansal start
  onItemSelect2(id) {
    this.filterdata = [];
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;
    this.authservice.apipost('CategoryList/', {
      "category_id": [id], "flag": "subcategorylevel2"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems3 = [];

        // if(response.response.length ==0){
        //   this.selectedItems3=[];
        //   this.fourthlevelfilter(id)

        // }
        // else{

        this.categorylevel3 = response.response;
        if (this.categorylevel3.length == 0) {
          this.fourthlevelfilter(id)
        }
        else {
          console.log('ggg')
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  selectedfilteractivate() {
    // selected filter shown on homepage module added on 16 july 2020 by Kunal bansal start
    this.selectedFilters = [];

    this.setfilactive = 1;
    if (this.selectedItems.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems[0].category_name
      })
    }
    if (this.selectedItems2.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems2[0].category_name
      })
    }
    if (this.selectedItems3.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems3[0].category_name
      })
    }
    this.filter1 = this.selectedItems;
    this.filter2 = this.selectedItems2;
    this.filter3 = this.selectedItems3;
    this.brands1 = this.selectedbrand;
    this.brands2 = this.selectedcolor;
    this.brands3 = this.selectedmemory;
    this.brands4 = this.selectedstorage;
    this.brands5 = this.selectedbattery;
    this.brands6 = this.selecteddisplay;


    // end
  }

  filterapi(offset) {

    let filters = [];
    if (this.selectedbrand != undefined) {
      filters.push({
        "id": 0,
        "name": "Brands",
        "selected": this.selectedbrand
      })

    }
    if (this.selectedcolor != undefined) {
      filters.push({
        "id": 1,
        "name": "Color",
        "selected": this.selectedcolor
      })
    }
    if (this.selectedmemory != undefined) {
      filters.push({
        "id": 2,
        "name": "Memory",
        "selected": this.selectedmemory
      })
    }
    if (this.selectedstorage != undefined) {
      filters.push({
        "id": 3,
        "name": "Internal Storage",
        "selected": this.selectedstorage
      })
    }
    if (this.selectedbattery != undefined) {
      filters.push({
        "id": 4,
        "name": "Battery",
        "selected": this.selectedbattery
      })
    }
    if (this.selecteddisplay != undefined) {
      filters.push({
        "id": 5,
        "name": "Display",
        "selected": this.selecteddisplay
      })
    }
    this.authservice.apipost('filterSort/', {
      "category": this.selectedItems.length != 0 ? [{ "id": this.selectedItems[0].id, "name": this.selectedItems[0].category_name }] : [],
      "categorylev2": this.selectedItems2.length != 0 ? [{ "id": this.selectedItems2[0].id, "name": this.selectedItems2[0].category_name }] : [],
      "subcategory": this.selectedItems3.length != 0 ? [{ "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name }] : [],
      "max_price": this.selectedhighrange,
      "min_price": this.selectedlowrange,
      "filters": filters,
      "platform": 0, //0 for web and 1 for app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": offset,
      // Geolocation module added by Kunal bansal on 25 june 2021 start
      "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        // this.selectedbrandid =;
        this.catfilters = '';
        this.brandactive = 0;
        if (offset != 1) { this.realoffset = this.realoffset + 1; }
        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {

          this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)

        }
        else {

          this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
        }
        this.filteractive = 1;
        this.selectedfilteractivate()
        this.modalService.dismissAll();

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }



  fourthlevelfilter(id) {

    this.selectedbrand = [];

    this.selectedcolor = [];
    this.selectedmemory = [];
    this.selectedstorage = [];
    this.selectedbattery = [];
    this.selecteddisplay = [];
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;
    this.authservice.apipost('filter_by/', { "category_id": id }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        if (response.response.filters[0].filter_items.length != 0) {
          this.filterdata = response.response;
          this.options.floor = response.response.min_price;
          this.selectedlowrange = response.response.min_price;
          this.selectedhighrange = response.response.max_price;
          this.options.ceil = response.response.max_price;

        }
        else {

          this.filterdata = [];
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  bannerdetails(actiontype, videolink, ban, banid) {      // Added by Kunal on 23 april 2020

    if (actiontype == 4) {
      this.router.navigate(['./homepage/Video']);
      this.storage.store("videostorage", videolink);
    }
    else if (actiontype == 2) {

      window.open(ban, "_blank");

    }
    else if (actiontype == 3) {

      if (videolink.includes('http')) {

        window.open(videolink, "_blank");
      }
      else {

        window.open("//" + videolink, "_blank");
      }

    }
    else if (actiontype == 5) {

      this.storage.store("bannerid", banid);
      this.storage.store('checkbanner', 1)
      this.router.navigate(['/homepage/search']);
    }

  }

  brandscreenapi() {
    // this.storage.store('categorybrandid',id);
    // this.selectedbrandid = this.routeid;

    // this.routeid=id;
    // this.storage.store('categorybrandid',id);
    this.authservice.apipost('getBrandProductList/', {
      "brandid": this.routeid,
      "platform": 0, //0 for web and 1 for app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": this.realoffset,
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      "location": this.locationstatus == 1 && this.tab == 0 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation,
      "remove_list": this.storelabel
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.brandactive = 1;

        this.selectedbrandid = this.routeid;

        // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
        this.labellisting = response.response.request_data;
        this.parentlabel = response.response.brand_name;
        // ...end...
        // this.catfilters = '';
        // pagination issue for samsung for fixed by Kunal bansal on 05feb2021
        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {

          this.buylist = this.realoffset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list);
          this.labellisting = response.response.request_data;
          this.realoffset = this.realoffset + 1;
        }
        else {

          this.selllist = this.realoffset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list);
          this.realoffset = this.realoffset + 1;
        }
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  brandapi(id) {
    // this.storage.store('categorybrandid',id);
    // this.selectedbrandid = this.routeid;


    this.routeid = id;
    // this.storage.store('categorybrandid',id);
    this.authservice.apipost('getBrandProductList/', {
      "brandid": id,
      "platform": 0, //0 for web and 1 for app
      "tab": this.tab,// 0 for buy and 1 for sell
      "offset": 1,
      // Geolocation module added by Kunal bansal on 23 june 2021 start
      "location": this.locationstatus && this.tab == 0 ? this.Districtname : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation,
      "remove_list": this.storelabel
    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        // phase 5 category wise brand search seller module added by Kunal bansal on 27 sep 2021
        this.labellisting = response.response.request_data;
        this.parentlabel = response.response.brand_name;
        // ...end...
        this.brandactive = 1;

        this.selectedbrandid = id;
        // this.catfilters = '';
        this.storage.store("homepagebrandid", id);

        this.realoffset = this.realoffset + 1;

        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {

          this.buylist = this.realoffset == 1 ? this.buylist.concat(response.response.buying_list) : response.response.buying_list;

        }
        else {

          this.selllist = this.realoffset == 1 ? this.selllist.concat(response.response.selling_list) : response.response.selling_list;
        }
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }

  // product status not coming issue fixed by Kunal bansal on 12feb2021
  pdetail(id, cateid) {

    this.storage.store("pdid", id);

    this.storage.store("ptype", 0);
    // this.router.navigate(['/homepage/productdetail']);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: cateid, ptype: 0 } });
  }

  // product status not coming issue fixed by Kunal bansal on 12feb2021
  productdetail(id, cateid) {

    this.storage.store("pdid", id);

    this.storage.store("ptype", 1);
    // this.router.navigate(['/homepage/productdetail']);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: cateid, ptype: 1 } });


  }

}