import { Component, OnInit, ViewEncapsulation, HostListener, ViewChild, ElementRef, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';
import { Http, Response, Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { SessionStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from 'ngx-spinner';
import { Options, LabelType } from 'ng5-slider';
import Swal from 'sweetalert2';
import { ResponseOptions } from '@angular/http';
import { ThrowStmt } from '@angular/compiler';
import { THROW_IF_NOT_FOUND } from '@angular/core/src/di/injector';


@Component({
  selector: 'app-product-listing',
  templateUrl: './product-listing.component.html',
  styleUrls: ['./product-listing.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ProductListingComponent implements OnInit {
  logged: any = 0;
  tab: number = 0;
  selectedbrand = [];
  selectedcolor = [];
  selectedmemory = [];
  selectedstorage = [];
  selectedbattery = [];
  selecteddisplay = [];
  selectedItems = [];
  selectedItems2 = [];
  selectedItems3 = [];
  dropdownSettings = {};
  dropdownSettings2 = {};
  selectedbrandid = '';
  isbranded: number = 0;
  isfiltered: number = 0;
  issortedtype: number = 10;
  banners: any[];


  device: number = 0;
  brands: any[];

  setfilterflag: number = 0;
  sidedraweractivate: number = 0;
  sidedrawerresponse: any[];
  sidedrawerbanner: any[];

  // added by Kunal bansal start on 02 july 2020
  category: any[];
  subcategory: any[]
  categorylevel3: any[]
  subcategoryactive: number = 0;
  // end
  ismobile: number = 0;
  selectedhighrange: number;
  selectedlowrange: number;
  selllist: any = [];
  buylist: any = [];
  recentlyviewed: any[];
  filterdata: any[];
  realoffset: number = 1
  realmaxload: number;
  selloffset: number = 1;
  buyoffset: number = 1;
  maxbuy: number;
  maxsell: number;
  filter: number = 1;
  checkcategoryimage: number = 0;

  selectedFilters = [];


  filter1 = [];
  filter2 = [];
  filter3 = [];
  brands1 = [];
  brands2 = [];
  brands3 = [];
  brands4 = [];
  brands5 = [];
  brands6 = [];
  sidecategoryfilters1 = [];
  sidecategoryfilters2 = [];
  sidecategoryfilters3 = [];
  sidecat1: any[];
  sidecat2: any[];
  sidecat3: any[];
  setfilactive: number = 0;
  homePageactive: number = 0;
  catid: number;
  checkbannerlength: number;

  firstlevel = [];
  secondlevel = [];

  filtersortfirst = [];
  filtersortsecond = [];
  filtersortthird = [];

  locationon: number = 0;
  googleapiresult: any[];
  District: string;
  State: string;
  // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
  countrylocation: string;

  // ...end...

  options: Options = {
    floor: 0,
    ceil: 100,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return '<b>Min price:</b>' + value;
        case LabelType.High:
          return '<b>Max price:</b>' + value;
        default:
          return '' + value;
      }
    }
  };

  constructor(private http: Http, private storage: SessionStorageService, lc: NgZone, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService, private modalService: NgbModal) {

    window.onscroll = () => {
      let status = "not reached";
      let windowHeight = "innerHeight" in window ? window.innerHeight
        : document.documentElement.offsetHeight;
      let body = document.body, html = document.documentElement;
      let docHeight = Math.max(body.scrollHeight,
        body.offsetHeight, html.clientHeight,
        html.scrollHeight, html.offsetHeight);
      let windowBottom = windowHeight + window.pageYOffset;
      if (Math.round(windowBottom) >= docHeight) {
        if (window.location.pathname == "/homepage/productdetail" || window.location.pathname == "/homepage/cart") {

        }
        else if (window.location.pathname.includes('newhome')) {

        }
        else {
          // alert('reached')
          if ((this.tab == 0 && this.realmaxload > this.buylist.length) || (this.tab == 1 && this.realmaxload > this.selllist.length)) {

            this.buysellchange(this.tab, this.realoffset)
          }
        }
      }
      lc.run(() => {

      });
    };
  }
  // commented by Kunal bansal on 12 jan 2021 to fix sort by issue
  // @HostListener("window:scroll", ["$event"])

  // onWindowScroll(e) {
  //   let pos = document.documentElement.scrollTop + document.documentElement.offsetHeight;
  //   let pos1 = e.target['scrollingElement'].scrollTop + document.documentElement.offsetHeight;
  //   let max = document.documentElement.scrollHeight;
  //   let max1 = document.body.scrollHeight - 10;

  //   if (Math.round(pos) == max) {
  //     if ((this.tab == 0 && this.realmaxload > this.buylist.length) || (this.tab == 1 && this.realmaxload > this.selllist.length)) {
  //       this.buysellchange(this.tab, this.realoffset)

  //     }
  //   }
  // }
  // if(Math.round(pos) == max || Math.round(pos1) == max1 || Math.round(pos1) > max1) modify this line above to fix pagination wrong offset hit issue on 21/04/2020 by Kunal bansal
  ngOnInit() {
    // get user current location function and api added by KUnal bansal on 23 june 2021 start
    this.logged = this.storage.retrieve('islogged');
    this.getcurrentlocation();
    // end

  }

  ngOnDestroy() {
    this.storage.clear('sidedraweractivate')
    this.storage.clear('sidecategoryfilter1')
    this.storage.clear('sidecategoryfilter2')
    this.storage.clear('sidecategoryfilter3')
    this.sidecategoryfilters1 = [];
    this.sidecategoryfilters2 = [];
    this.sidecategoryfilters3 = [];
  }
  onSelectAllfirst() {

    for (let k = 0; k < this.category.length; k++) {
      this.filtersortfirst.push({ 'id': this.category[k].id, 'name': this.category[k].category_name })
      this.firstlevel.push(this.category[k].id)
    }
    this.authservice.apipost('CategoryList/', {
      "category_id": this.firstlevel, "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems2 = [];
        this.subcategory = response.response;
        this.subcategoryactive = 1;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

  }

  // added by Kunal bansal on 21 june 2021 start
  hithomepageapi() {
    // this.storage.store("pressedkey", 0); //added by Kunal on 03/16/2020
    this.tab = this.storage.retrieve('pressedkey')
    if (this.tab == null) {
      this.tab = 0;
      this.storage.store('categorytab', 0);
      this.storage.store('pressedkey', 0);

    }
    this.storage.clear('bannersearch');

    // Side drawer module added by Kunal bansal on 21 july 2020 start
    this.sidedraweractivate = this.storage.retrieve('sidedraweractivate');
    if (this.sidedraweractivate == 1 && this.tab == 0 || this.sidedraweractivate == 1 && this.tab == 1) {
      this.setfilterflag = 1;
      this.sidecat1 = this.storage.retrieve('sidecat1id');

      this.filtersortfirst = this.sidecat1;


      this.sidecat2 = this.storage.retrieve('sidecat2id');
      if (this.sidecat2.length > 0) {
        this.filtersortsecond = this.sidecat2;
      }
      this.sidecat3 = this.storage.retrieve('sidecat3id');
      if (this.sidecat3.length > 0) {
        this.filtersortthird = this.sidecat3;
      }
      this.sidecategoryfilters1 = this.storage.retrieve('sidecategoryfilter1')
      this.sidecategoryfilters2 = this.storage.retrieve('sidecategoryfilter2')
      this.sidecategoryfilters3 = this.storage.retrieve('sidecategoryfilter3')


      this.buylist = this.storage.retrieve('sidedrawer');
      this.banners = this.storage.retrieve('sidedrawerbanner');
      this.brands = this.storage.retrieve('sidedrawerbrands');
      this.spinner.hide();

    }
    else {
      this.logged = this.storage.retrieve('islogged');
      this.defaultapi(this.tab, 1);
    }
    // end
    setTimeout(() => {
      $('.carousel-control-next-icon').click();
    }, 4000);


    if (window.innerWidth < 400) {
      this.device = 1;
    }
    this.logged = this.storage.retrieve('islogged');

    if (window.screen.width <= 360) { // 768px portrait
      this.ismobile = 1;
    }
    this.dropdownSettings = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "value",
      primaryKey: "Id",
      badgeShowLimit: 4,
      autoPosition: false,
      position: "top",
      classes: "font_drop",
      maxHeight: 200
    };
    // Added by Kunal bansal on 2 july 2020 start
    this.dropdownSettings2 = {
      singleSelection: false,
      enableFilterSelectAll: false,
      enableSearchFilter: false,
      text: "Select",
      labelKey: "category_name",
      classes: "font_drop",
      position: 'top',
      autoPosition: false,

      maxHeight: 200
    };
    // end


    $(document).ready(function () {
      $("#myCarousel").on("touchstart", function (event) {
        var xClick = event.originalEvent.touches[0].pageX;
        $(this).one("touchmove", function (event) {
          var xMove = event.originalEvent.touches[0].pageX;
          if (Math.floor(xClick - xMove) > 5) {
            $('.carousel-control-next-icon').click();
          }
          else if (Math.floor(xClick - xMove) < -5) {
            $('.carousel-control-prev-icon').click();
          }
        });
        $("#myCarousel").on("touchend", function () {
          $(this).off("touchmove");
        });
      });

    });
  }


  // end

  // geolocation(){
  //   this.authservice.geolocation().subscribe((res: any) => {
  //     let response = res.json();
  //    console.log(response)
  //   }
  //     , err => {
  //       this.spinner.hide();
  //       Swal.fire("Connect to the Internet")
  //     }
  //   )
  // }


  // get user current location function and api added by Kunal bansal on 23 june 2021 start
  getcurrentlocation() {

    this.getPosition().then(pos => {

      this.geocoding(`AIzaSyBvQhRMAEoYfqwjNkt1fhM12JZ71yMsD6o&latlng=${pos.lat},${pos.lng}`).subscribe((res: any) => {
        let response = res.json();
        console.log(response);
        this.googleapiresult = response.results;
        for (let h = 0; h < this.googleapiresult[0].address_components.length; h++) {
          if (this.googleapiresult[0].address_components[h].types.includes("administrative_area_level_2")) {
            // lower case added by Kunal bansal on 05 july 2021 start
            this.District = this.googleapiresult[0].address_components[h].short_name.toLowerCase();
            this.storage.store("districtname",this.District)
            // end

          }
          if (this.googleapiresult[0].address_components[h].types.includes("administrative_area_level_1")) {
            this.State = this.googleapiresult[0].address_components[h].long_name;
          }
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          if (this.googleapiresult[0].address_components[h].types.includes("country")) {
            sessionStorage.setItem("phase5countrylocation", this.googleapiresult[0].address_components[h].long_name.toLowerCase());
            this.countrylocation = this.googleapiresult[0].address_components[h].long_name.toLowerCase();
          }
          // ...end...
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        
          // ...end...
        }
        console.log(this.State);
        console.log(this.District);



        this.hithomepageapi();


      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
      // this.Longitude=pos.lng;
      // this.latitude=pos.lat;
      // console.log('longitude : '+pos.lng+"  "+'latitude : '+pos.lat);
      // alert(this.Longitude);
      // alert(this.latitude);
    })
  }
  // end

  // get user current location function and api added by KUnal bansal on 21 june 2021 start
  getPosition(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.spinner.show();
      navigator.geolocation.getCurrentPosition(resp => {
        this.storage.store("locationstatus", 1);
        this.locationon = 1;
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });

      },
        err => {
          this.storage.store("locationstatus", 0);
          this.storage.store("districtname", "");
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          sessionStorage.setItem("phase5countrylocation", "");
          // ...end...
          // <!-- ..................................Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          this.countrylocation = "";
          // ...end...
          this.locationon = 0;
          // Swal.fire('Please allow location to view Location specific inventory');

          this.hithomepageapi();

          // reject(err);

        });
    });


  }

  geocoding(url) {

    // var headers = new Headers();
    // var token = this.storage.retrieve('token');
    // headers.append('Content-Type', 'application/json');
    // headers.append('Token', token);
    return this.http.get("https://maps.googleapis.com/maps/api/geocode/json?key=" + url).pipe(map(
      (data: Response) => {
        this.spinner.hide();
        return data;
      }
    ))
  }

  // end

  // function added by Kunal for category based filter added on 2 july 2020 start
  onItemSelect(item: any) {
    if (this.firstlevel.includes(item.id)) {

    }
    else {
      this.firstlevel.push(item.id)
      this.filtersortfirst.push({ 'id': item.id, 'name': item.category_name })
    }
    this.filterdata = [];
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;
    // Added by Kunal on 02 july 2020 start
    this.authservice.apipost('CategoryList/', {
      "category_id": this.firstlevel, "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems2 = [];
        this.subcategory = response.response;
        this.subcategoryactive = 1;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }
  Removefirstlevel(item: any) {
    this.selectedItems2 = [];
    for (let k = 0; k < this.filtersortfirst.length; k++) {
      if (this.filtersortfirst[k].name == item.category_name) {
        this.filtersortfirst.splice(k, 1)
      }
    }
    // Kiran code manual merge on 19jan2021 start
    let parent = this.firstlevel.indexOf(item.id);
    this.firstlevel.splice(parent, 1);

    this.authservice.apipost('CategoryList/', {
      "category_id": this.firstlevel, "flag": "subcategory"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        // Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.subcategory = response.response;
        this.subcategoryactive = 1;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    // end
  }

  onDeSelectAllfirst(item: any) {
    this.filtersortfirst = [];
    this.firstlevel = [];
    this.authservice.apipost('CategoryList/', {
      "category_id": [], "flag": "subcategory"

    })
      .subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          // Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.selectedItems2 = [];
          this.subcategory = response.response;
          this.subcategoryactive = 1;
        }
        else { Swal.fire("Server Down") }


      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )

  }

  // module added by KUnal bansal in case subcategory remove if category array empty 2 july 2020 start
  // OnItemDeSelect($event){
  //  this.subcategoryactive=0;
  //  this.selectedItems2=[];
  // }
  // end

  onSelectAllsecond(item: any) {
    for (let g = 0; g < this.subcategory.length; g++) {
      this.filtersortsecond.push({ 'id': this.subcategory[g].id, 'name': this.subcategory[g].category_name })
    }
  }

  onDeSelectAllsecond(item: any) {
    this.filtersortsecond = [];
  }


  // added on 06 july 2020 by Kunal bansal start
  onItemSelect2(item: any) {
    if (this.secondlevel.includes(item.id)) {

    }
    else {
      this.secondlevel.push(item.id)
      this.filtersortsecond.push({ 'id': item.id, 'name': item.category_name })
    }
    this.filterdata = [];
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;
    this.authservice.apipost('CategoryList/', {
      "category_id": this.secondlevel, "flag": "subcategorylevel2"

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.selectedItems3 = [];

        // if(response.response.length ==0){
        //   this.selectedItems3=[];
        //   this.fourthlevelfilter(id)

        // }
        // else{

        this.categorylevel3 = response.response;
        if (this.categorylevel3.length == 0) {
          this.fourthlevelfilter(item.id)
        }
        else {
          console.log('ggg')
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  // end
  Removesecondlevel(item: any) {
    for (let k = 0; k < this.filtersortsecond.length; k++) {
      if (this.filtersortsecond[k].name == item.category_name) {
        this.filtersortsecond.splice(k, 1)
      }
    }
  }

  LocationAllow(i, offset) {

    if (offset == 2) {
      this.authservice.apipost('homePageCategoryList/', {
        "platform": 0, //0 for web and 1 for   app
        "tab": i,// 0 for buy and 1 for sell
        "offset": this.realoffset
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          this.homePageactive = 3;
          this.setfilterflag = 0;
          // alert(JSON.stringify(response.response.banners[2].action_type))
          this.realoffset = this.realoffset + 1;
          this.realmaxload = response.response.total_count;
          if (this.device == 0) {
            this.banners = response.response.banners;


            //this.banners = response.response.banners_web;
          }
          else {
            this.banners = response.response.banners;

            //this.banners = response.response.banners_app;
          }
          this.brands = response.response.categories_list[0].brands;

          this.recentlyviewed = response.response.recently_viewed;
          if (this.tab == 0) {

            this.buylist = response.response.categories_list;
          }
          else {
            this.selllist = response.response.selling_list;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else {

      this.authservice.apipost('homePageCategoryList/', {
        "platform": 0, //0 for web and 1 for app
        "tab": i,// 0 for buy and 1 for sell
        "offset": this.realoffset,//modified by Kunal on 27 march 2020
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {

          // this.homePageactive = 3;
          // this.checkcategoryimage=1;
          // this.setfilterflag = 0;

          this.realmaxload = response.response.total_count;
          if (this.device == 0) {
            this.checkbannerlength = response.response.banners.length;
            this.banners = response.response.banners;

            // alert(JSON.stringify(this.banners))
            //this.banners = response.response.banners_web;
          }
          else {
            this.checkbannerlength = response.response.banners.length;
            this.banners = response.response.banners;

            //this.banners = response.response.banners_app;
          }
          this.brands = response.response.brand_details;

          this.recentlyviewed = response.response.recently_viewed;

          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.categories : this.buylist.concat(response.response.categories)
            this.realoffset = this.realoffset + 1;
          }
          else {
            this.selllist = offset == 1 ? response.response.categories : this.selllist.concat(response.response.categories)
            this.realoffset = this.realoffset + 1;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("Connect to the Internet")
          Swal.fire("Network Error")
        }
      )
    }
  }





  defaultapi(i, offset) {

    if (offset == 2) {
      this.authservice.apipost('homePageCategoryList/', {
        "platform": 0, //0 for web and 1 for   app
        "tab": i,// 0 for buy and 1 for sell
        "offset": this.realoffset
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.homePageactive = 3;
          this.setfilterflag = 0;
          // alert(JSON.stringify(response.response.banners[2].action_type))
          this.realoffset = this.realoffset + 1;
          this.realmaxload = response.response.total_count;
          if (this.device == 0) {
            this.banners = response.response.banners;


            //this.banners = response.response.banners_web;
          }
          else {
            this.banners = response.response.banners;

            //this.banners = response.response.banners_app;
          }
          this.brands = response.response.categories_list[0].brands;

          this.recentlyviewed = response.response.recently_viewed;
          if (this.tab == 0) {

            this.buylist = response.response.categories_list;
          }
          else {
            this.selllist = response.response.selling_list;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else {

      this.authservice.apipost('homePageCategoryList/', {
        "platform": 0, //0 for web and 1 for app
        "tab": i,// 0 for buy and 1 for sell
        "offset": this.realoffset,//modified by Kunal on 27 march 2020
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          // this.homePageactive = 3;
          // this.checkcategoryimage=1;
          // this.setfilterflag = 0;

          this.realmaxload = response.response.total_count;
          if (this.device == 0) {
            this.checkbannerlength = response.response.banners.length;
            this.banners = response.response.banners;

            // alert(JSON.stringify(this.banners))
            //this.banners = response.response.banners_web;
          }
          else {
            this.checkbannerlength = response.response.banners.length;
            this.banners = response.response.banners;

            //this.banners = response.response.banners_app;
          }
          this.brands = response.response.brand_details;

          this.recentlyviewed = response.response.recently_viewed;

          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.categories : this.buylist.concat(response.response.categories)
            this.realoffset = this.realoffset + 1;
          }
          else {
            this.selllist = offset == 1 ? response.response.categories : this.selllist.concat(response.response.categories)
            this.realoffset = this.realoffset + 1;
          }
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          // Swal.fire("Connect to the Internet")
          Swal.fire("Network Error")
        }
      )
    }
  }

  closeall() {
    this.modalService.dismissAll();
    // added by Kunal on 02 july 2020
    this.subcategoryactive = 0;
  }

  productdetail(id) {
    this.storage.store("pdid", id);
    this.storage.store('catid', this.catid)
    this.storage.store('tabdetail', this.tab)
    this.storage.store("ptype", 1);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: this.catid, ptype: this.tab } });
  }

  pdetail(id) {

    this.storage.store("pdid", id);
    this.storage.store('catid', this.catid)
    this.storage.store('tabdetail', this.tab)

    this.storage.store("ptype", 0);
    this.router.navigate(['/homepage/productdetail'], { queryParams: { pid: id, catid: this.catid, ptype: this.tab } });
  }

  brandapi(id, offset) {
    this.storage.store("homepagebrandtab", this.tab);
    this.storage.store("homepagebrandid", id);
    this.storage.store("homepagebanners", this.banners);
    this.storage.store("homepagebrands", this.brands)
    this.storage.store("checkhomepagebrandactive", 1);
    this.storage.store('supparentname', '');
    this.storage.store('parentcategoryname', '');
    this.storage.store('categoname', '');
    // Geolocation module added by Kunal bansal on 23 june 2021 start
    this.storage.store("locationstatus", this.locationon);
    this.storage.store("loggedstatus", this.logged);
    this.storage.store("districtname", this.District);
    // end
    this.router.navigate(['./homepage/brandlist']);
    // if (this.issortedtype != 10) {

    //   this.authservice.apipost('getBrandProductList/?sort_flag=' + this.issortedtype, {
    //     "brandid": id,
    //     "platform": 0,
    //     "tab": this.tab,
    //     "offset": offset
    //   }).subscribe((res: any) => {
    //     let response = res.json();
    //     if (response.status == 400 || response.status == 500 || response.status == 100) {
    //       Swal.fire(response.message)
    //     }
    //     else if (response.status == 200) {
    //       if (offset != 1) { this.realoffset = this.realoffset + 1; }
    //       this.realmaxload = response.response.total_count;
    //       this.isbranded = 1;
    //       this.selectedbrandid = id;
    //       if (this.tab == 0) {
    //         this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
    //       }
    //       else {
    //         this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
    //       }
    //     }
    //     else { Swal.fire("Server Down") }
    //   }
    //     , err => {
    //       this.spinner.hide();
    //       Swal.fire("Connect to the Internet")
    //     }
    //   )
    // }
    // else {
    //   this.authservice.apipost('getBrandProductList/', {
    //     "brandid": id,
    //     "platform": 0, 
    //     "tab": this.tab,
    //     "offset": offset
    //   }).subscribe((res: any) => {
    //     let response = res.json();
    //     if (response.status == 400 || response.status == 500 || response.status == 100) {
    //       Swal.fire(response.message)
    //     }
    //     else if (response.status == 200) {
    //       this.selectedFilters = [];
    //       this.brands1 = [];
    //       this.brands2 = [];
    //       this.brands3 = [];
    //       this.brands4 = [];
    //       this.brands5 = [];
    //       this.brands6 = [];
    //       this.filter1 = [];
    //       this.filter2 = [];
    //       this.filter3 = [];
    //       this.sidecategoryfilters1 = [];
    //       this.sidecategoryfilters2 = [];
    //       this.sidecategoryfilters3 = [];
    //       if (offset != 1) { this.realoffset = this.realoffset + 1; }
    //       this.realmaxload = response.response.total_count;
    //       this.isbranded = 1;
    //       this.selectedbrandid = id;
    //       if (this.tab == 0) {
    //         this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
    //         this.setfilterflag = 1;
    //       }
    //       else {
    //         this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
    //         this.setfilterflag = 1;
    //       }
    //     }
    //     else { Swal.fire("Server Down") }
    //   }
    //     , err => {
    //       this.spinner.hide();
    //       Swal.fire("Connect to the Internet")
    //     }
    //   )
    // }
  }


  check(h) {

  }

  sortapi(i, offset) {
    if (this.isfiltered == 1) {
      this.filterapi(i, offset);
    }
    else if (this.isbranded == 1) {
      this.authservice.apipost('getBrandProductList/?sort_flag=' + i, {
        "brandid": this.selectedbrandid,
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": offset,
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          if (offset != 1) { this.realoffset = this.realoffset + 1; }
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          }
          else {
            this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          }
          this.issortedtype = i;
          this.modalService.dismissAll();
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    else {
      console.log(this.sidecategoryfilters1)
      for (let b = 0; b < this.sidecategoryfilters1.length; b++) {
        // this.selectedItems.push({ 'id': this.sidecategoryfilters1[b].id, 'name': this.sidecategoryfilters1[b].category_name })
        // this.filtersortfirst.push({ 'id': this.sidecategoryfilters1[b].id, 'name': this.sidecategoryfilters1[b].category_name })
        if (this.selectedItems.includes(this.sidecategoryfilters1[b].id)) {

        }
        else {
          this.selectedItems.push(this.sidecategoryfilters1[b].id)
          this.filtersortfirst.push(this.sidecategoryfilters1[b].id)
        }

      }
      for (let b = 0; b < this.sidecategoryfilters2.length; b++) {
        if (this.sidecategoryfilters2.includes(this.sidecategoryfilters2[b].id)) {

        }
        else {
          this.selectedItems2.push(this.sidecategoryfilters2[b].id)
          this.filtersortsecond.push(this.sidecategoryfilters2[b].id)
        }
        // this.selectedItems2.push({ 'id': this.sidecategoryfilters2[b].id, 'name': this.sidecategoryfilters2[b].category_name })
        // this.filtersortsecond.push({ 'id': this.sidecategoryfilters2[b].id, 'name': this.sidecategoryfilters2[b].category_name })

      }
      for (let b = 0; b < this.sidecategoryfilters3.length; b++) {
        // this.selectedItems3.push({ 'id': this.sidecategoryfilters3[b].id, 'name': this.sidecategoryfilters3[b].category_name })
        // this.filtersortthird.push({ 'id': this.sidecategoryfilters3[b].id, 'name': this.sidecategoryfilters3[b].category_name })
        if (this.selectedItems3.includes(this.sidecategoryfilters3[b].id)) {

        }
        else {
          this.selectedItems3.push(this.sidecategoryfilters3[b].id);
          this.filtersortthird.push(this.sidecategoryfilters3[b].id);
        }

      }
      console.log(this.filtersortfirst)
      // "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
      // "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
      // "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
      this.authservice.apipost('homePage/?sort_flag=' + i, {
        "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
        "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
        "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": offset,
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.setfilterflag = 1;
          if (offset != 1) { this.realoffset = this.realoffset + 1; }
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          }
          else {
            this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          }
          this.issortedtype = i;
          this.modalService.dismissAll();
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  selectedfilteractivate() {
    // selected filter shown on homepage module added on 16 july 2020 by Kunal bansal start
    this.selectedFilters = [];

    this.setfilactive = 1;
    if (this.selectedItems.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems[0].category_name
      })
    }
    if (this.selectedItems2.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems2[0].category_name
      })
    }
    if (this.selectedItems3.length != 0) {
      this.selectedFilters.push({
        "id": this.selectedItems3[0].category_name
      })
    }

    this.filter1 = this.selectedItems;



    this.filter2 = this.selectedItems2;
    this.filter3 = this.selectedItems3;
    this.brands1 = this.selectedbrand;
    this.brands2 = this.selectedcolor;
    this.brands3 = this.selectedmemory;
    this.brands4 = this.selectedstorage;
    this.brands5 = this.selectedbattery;
    this.brands6 = this.selecteddisplay;


    // end
  }

  // module added on 17 july 2020 by Kunal bansal start

  removefilter(i, index, cname) {

    if (i == 1) {
      this.filter1.splice(index, 1)

      for (let z = 0; z < this.filtersortfirst.length; z++) {
        if (this.filtersortfirst[z].name == cname) {
          this.filtersortfirst.splice(z, 1)
        }
      }

      if (this.filtersortfirst.length >= 1) {
        this.filterapi(10, 1)
      }
      else {
        location.reload();
      }

      // this.selectedFilters.splice(index, 1)
      // //  this.selectedItems.splice(index,1);
      // //  this.selectedItems2.splice(0,1);
      // this.selectedItems.splice(index, 1);
      // this.selectedItems2.splice(index, 1);
      // this.selectedItems3.splice(index, 1);
      // this.selectedlowrange = 0;
      // this.selectedhighrange = 0;
      // this.selectedbrand = [];
      // this.selectedcolor = [];
      // this.selectedmemory = [];
      // this.selectedstorage = [];
      // this.selectedbattery = [];
      // this.selecteddisplay = [];

      // this.filterapi(10, 1)
      // location.reload();
    }
    if (i == 12) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems2.splice(index, 1);
      this.selectedItems3.splice(index, 1);
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];
      for (let y = 0; y < this.filtersortsecond.length; y++) {
        if (this.filtersortsecond[y].name == cname) {
          this.filtersortsecond.splice(y, 1)
        }
      }
      this.filterapi(10, 1)

    }
    if (i == 13) {
      this.selectedFilters.splice(index, 1)
      //  this.selectedItems.splice(index,1);
      //  this.selectedItems2.splice(0,1);
      this.selectedItems3.splice(index, 1);
      this.selectedbrand = [];
      this.selectedcolor = [];
      this.selectedmemory = [];
      this.selectedstorage = [];
      this.selectedbattery = [];
      this.selecteddisplay = [];

      this.filterapi(10, 1)
    }
    if (i == 2 && this.brands1.length != 0) {

      this.selectedbrand.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 3 && this.brands2.length != 0) {

      this.selectedcolor.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 4 && this.brands3.length != 0) {

      this.selectedmemory.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 5 && this.brands4.length != 0) {

      this.selectedstorage.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 6 && this.brands5.length != 0) {

      this.selectedbattery.splice(index, 1)

      this.filterapi(10, 1)
    }
    if (i == 7 && this.brands6.length != 0) {

      this.selecteddisplay.splice(index, 1)

      this.filterapi(10, 1)
    }
  }
  // end



  filterapi(issortedtype, offset) {

    let filters = [];
    if (this.selectedbrand != undefined) {
      filters.push({
        "id": 0,
        "name": "Brands",
        "selected": this.selectedbrand
      })

    }
    if (this.selectedcolor != undefined) {
      filters.push({
        "id": 1,
        "name": "Color",
        "selected": this.selectedcolor
      })
    }
    if (this.selectedmemory != undefined) {
      filters.push({
        "id": 2,
        "name": "Memory",
        "selected": this.selectedmemory
      })
    }
    if (this.selectedstorage != undefined) {
      filters.push({
        "id": 3,
        "name": "Internal Storage",
        "selected": this.selectedstorage
      })
    }
    if (this.selectedbattery != undefined) {
      filters.push({
        "id": 4,
        "name": "Battery",
        "selected": this.selectedbattery
      })
    }
    if (this.selecteddisplay != undefined) {
      filters.push({
        "id": 5,
        "name": "Display",
        "selected": this.selecteddisplay
      })
    }
    if (this.issortedtype != 10 || issortedtype != 10) {
      var x = this.issortedtype == 10 ? issortedtype : this.issortedtype;
      // filter sort params added and other mopification done on 24 march 2021 by Kunal bansal start
      this.authservice.apipost('filterSort/?sort_flag=' + x, {
        "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
        "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
        "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
        "max_price": this.selectedhighrange,
        "min_price": this.selectedlowrange,
        "filters": filters,
        "platform": 0, //0 for web and 1 for app
        "tab": this.tab,// 0 for buy and 1 for sell
        "offset": offset,
        // Geolocation module added by Kunal bansal on 25 june 2021 start
        "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
        // end
        // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
        "country": this.countrylocation
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          this.sidecategoryfilters1 = [];
          this.sidecategoryfilters2 = [];
          this.sidecategoryfilters3 = [];
          this.storage.clear('sidecategoryfilter1')
          this.storage.clear('sidecategoryfilter2')
          this.storage.clear('sidecategoryfilter3')
          this.selectedfilteractivate()
          // end
          if (offset != 1) { this.realoffset = this.realoffset + 1; }
          this.realmaxload = response.response.total_count;
          if (this.tab == 0) {
            this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          }
          else {
            this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          }
          this.modalService.dismissAll();
          this.isfiltered = 1;
          this.selectedbrandid = '';
          this.isbranded = 0
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
    // filter module added on 13 july 2020 start
    else {

      if (this.selectedItems.length == 0) {

      }
      else {
alert(this.countrylocation)
        this.authservice.apipost('filterSort/', {
          "category": this.selectedItems.length != 0 ? this.filtersortfirst : {},
          "categorylev2": this.selectedItems2.length != 0 ? this.filtersortsecond : [],
          "subcategory": this.selectedItems3.length != 0 ? { "id": this.selectedItems3[0].id, "name": this.selectedItems3[0].category_name } : [],
          "max_price": this.selectedhighrange,
          "min_price": this.selectedlowrange,
          "filters": filters,
          "platform": 0, //0 for web and 1 for app
          "tab": this.tab,// 0 for buy and 1 for sell
          "offset": offset,
          // Geolocation module added by Kunal bansal on 25 june 2021 start
          "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
          // end
          // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
          "country": this.countrylocation
        }).subscribe((res: any) => {
          let response = res.json();
          if (response.status == 400 || response.status == 500 || response.status == 100) {
            Swal.fire(response.message)
          }
          else if (response.status == 200) {


            this.sidecategoryfilters1 = [];
            this.sidecategoryfilters2 = [];
            this.sidecategoryfilters3 = [];
            this.storage.clear('sidecategoryfilter1')
            this.storage.clear('sidecategoryfilter2')
            this.storage.clear('sidecategoryfilter3')
            this.selectedfilteractivate()

            if (offset != 1) { this.realoffset = this.realoffset + 1; }
            this.realmaxload = response.response.total_count;
            if (this.tab == 0) {
              this.setfilterflag = 1;
              this.buylist = offset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)

            }
            else {
              this.setfilterflag = 1;
              this.selllist = offset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
            }
            this.modalService.dismissAll();
            this.isfiltered = 1;
            this.selectedbrandid = '';
            this.isbranded = 0
          }
          else { Swal.fire("Server Down") }
        }
          , err => {
            this.spinner.hide();
            Swal.fire("Connect to the Internet")
          }
        )
      }
    }
  }

  querybutton() { //Added by Kunal bansal on 24 april 2020

    this.router.navigate(['./homepage/asknow']);
  }





  buysellchange(i, offset) {
    this.storage.store("pressedkey", i);
    this.storage.store('categorytab', i);
    this.homePageactive = this.storage.retrieve('homePageactive');

    this.realoffset = i == this.tab ? this.realoffset : 1
    if (offset == 1) {
      this.tab = i;
      if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.sidedraweractivate == 0) {



        this.defaultapi(this.tab, 1)

        // end
      }
      else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.sidedraweractivate == 1) {
        this.sidecategory()
      }
      else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0) {

        this.setfilterflag = 0;


        this.defaultapi(this.tab, 1)

        // end
      }
      // else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.homePageactive == 0 && this.tab==1) {

      //   this.defaultapi(i, 1)
      // }
      else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.homePageactive == 1 && this.checkcategoryimage == 0) {
        this.setfilterflag = 1;

        // this.categoryimage(this.catid,this.realoffset)
      }

      // else if(this.tab==1 && this.setfilterflag && this.sidedraweractivate==1){
      //   alert('dsds')
      // }
      else if (this.issortedtype != 10 && this.isfiltered == 1) {
        this.filterapi(this.issortedtype, 1)
      }
      else if (this.issortedtype != 10 && this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, 1)
      }
      else if (this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, 1)
      }
      else if (this.issortedtype != 10) {

        this.sortapi(this.issortedtype, 1)
      }
      else if (this.isfiltered == 1) {
        this.filterapi(10, 1)
      }
    }
    else {
      this.tab = i;
      if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.homePageactive == 1 && this.checkcategoryimage == 1) {


        this.defaultapi(this.tab, 1)


      }
      else if (this.issortedtype == 10 && this.isfiltered == 0 && this.isbranded == 0 && this.homePageactive == 1 && this.checkcategoryimage == 0) {
        this.setfilterflag = 1;

        // this.categoryimage(this.catid,this.realoffset)
      }
      else if (this.issortedtype != 10 && this.isfiltered == 1) {
        this.filterapi(this.issortedtype, offset)
      }
      else if (this.issortedtype != 10 && this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, offset)
      }
      else if (this.isbranded == 1) {
        this.brandapi(this.selectedbrandid, offset)
      }
      else if (this.issortedtype != 10) {
        this.sortapi(this.issortedtype, offset)
      }
      else if (this.isfiltered == 1) {
        this.filterapi(10, offset)
      }
    }
  }


  sidecategory() {

    this.authservice.apipost('homePage/', {
      "platform": 0,
      "tab": this.tab,
      "offset": 1,
      "category": this.sidecat1.length != 0 ? this.filtersortfirst : [],
      "categorylev2": this.sidecat2.length != 0 ? this.filtersortsecond : [],
      "subcategory": this.sidecat3.length != 0 ? this.filtersortthird : [],
      // Geolocation module added by Kunal bansal on 25 june 2021 start
      "location": this.locationon == 1 && this.tab == 0 ? this.District : "",
      // end
      // <!-- ...........Phase 5 new cr Country selection module added by Kunal bansal on 16 sep 2021 start........ -->
      "country": this.countrylocation

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.realoffset = this.realoffset + 1;
        // if (this.tab == 0) {
        //   this.buylist = response.response.buying_list;
        //   this.brands = response.response.brand_details;
        // }
        // else {
        //   this.selllist = response.response.selling_list;
        // }

        this.realmaxload = response.response.total_count;
        if (this.tab == 0) {
          this.setfilterflag = 1;
          this.brands = response.response.brand_details;
          // this.buylist = this.realoffset == 1 ? response.response.buying_list : this.buylist.concat(response.response.buying_list)
          this.buylist = response.response.buying_list;

        }
        else {
          this.setfilterflag = 1;
          // this.selllist = this.realoffset == 1 ? response.response.selling_list : this.selllist.concat(response.response.selling_list)
          this.selllist = response.response.selling_list;
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }
  home() {
    if (window.location.pathname == "/homepage/productListing") {
      this.storage.store('sidedraweractivate', 0)
      location.reload();
    }
    else {
      this.storage.store('sidedraweractivate', 0)
      this.router.navigate(['/homepage/productListing']);
    }
  }


  removesidefilter(index, a, id) {

    if (a == 1) {
      this.sidecategoryfilters1.splice(index, 1)

      if (this.sidecategoryfilters1.length == 0) {
        this.storage.clear('sidecategoryfilter1');
        this.storage.clear('sidecategoryfilter2');
        this.storage.clear('sidecategoryfilter3');
        this.storage.clear('sidecat1id');
        this.storage.clear('sidecat2id');
        this.storage.clear('sidecat3id')

        if (window.location.pathname == "/homepage/productListing") {
          this.storage.store('sidedraweractivate', 0);
          this.storage.store('pressedkey', 0);
          this.storage.store('categorytab', 0);
          this.storage.clear("checkhomepagebrandactive");
          location.reload();
        }
        else {
          this.storage.store('sidedraweractivate', 0);
          this.storage.store('pressedkey', 0);
          this.storage.store('categorytab', 0);
          this.router.navigate(['/homepage/productListing']);
        }

      }
      else {
        // this.sidecat1 = [];
        // this.sidecat2 = [];
        // this.sidecategoryfilters2 = [];
        // this.sidecat3 = [];
        // this.sidecategoryfilters3 = []
        let v = this.filtersortfirst.indexOf(id);
        this.filtersortfirst.splice(v, 1)
        this.sidecategoryfilters1.splice(v, 1);
        this.sidecategory();
      }


    }
    else if (a == 2) {
      // alert('hlo1')
      // this.sidecat2.splice(index, 1)
      // alert('hlo2')
      // this.sidecategoryfilters2.splice(index, 1);
      // alert('hlo3')
      // this.storage.clear('sidecategoryfilter2');
      // alert('hlo4')
      // this.storage.clear('sidecat2id');
      // alert('hlo5')
      let v = this.filtersortsecond.indexOf(id);
      this.filtersortsecond.splice(v, 1)
      this.sidecategoryfilters2.splice(v, 1);

      // let v = this.sidecat1.indexOf(id);
      // alert('hlo6')
      // this.sidecat1.splice(v, 1);

      this.sidecategory();
    }
    else {
      this.sidecat3.splice(index, 1)
      this.sidecategoryfilters3.splice(index, 1)
      this.storage.clear('sidecategoryfilter3');
      this.storage.clear('sidecat3id')
      this.sidecategory();
    }
  }

  // new module added for click at category image list render by Kunal bansal on 17 july 2020
  categoryimage(categid, parentid, catname, parname, supparid, supparname) {

    if (parname != supparname) {
      this.storage.store('supparentid', supparid)
      this.storage.store('supparentname', supparname)
    }
    else {
      this.storage.store('supparentid', '')
      this.storage.store('supparentname', '')
    }
    this.catid = categid;
    this.storage.store('cattab', this.tab)
    this.storage.store('catid', categid)
    this.storage.store('parid', parentid)
    this.storage.store('categoname', catname)
    this.storage.store('parentcategoryname', parname)
    // Geolocation module added by Kunal bansal on 23 june 2021 start
    this.storage.store("locationstatus", this.locationon);
    this.storage.store("loggedstatus", this.logged);
    this.storage.store("districtname", this.District);
    // added by Kunal bansal on 15 july 2021 
    sessionStorage.setItem("removesecondfilter", "0");
    // end
    this.router.navigate(['./homepage/categorylist']);

  }

  fourthlevelfilter(id) {

    this.selectedbrand = [];

    this.selectedcolor = [];
    this.selectedmemory = [];
    this.selectedstorage = [];
    this.selectedbattery = [];
    this.selecteddisplay = [];
    this.selectedhighrange = 0;
    this.selectedlowrange = 0;
    this.authservice.apipost('filter_by/', { "category_id": id }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        if (response.response.filters[0].filter_items.length != 0) {
          this.filterdata = response.response;
          this.options.floor = response.response.min_price;
          this.selectedlowrange = response.response.min_price;
          this.selectedhighrange = response.response.max_price;
          this.options.ceil = response.response.max_price;

        }
        else {

          this.filterdata = [];
        }

      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
  }


  filtermoadal(filter) {
    // this.selectedbrand = [];
    this.selectedItems = [];
    this.firstlevel = [];
    this.selectedItems2 = [];
    this.secondlevel = [];
    this.selectedItems3 = [];


    // this.selectedcolor = [];
    // this.selectedmemory = [];
    // this.selectedstorage = [];
    // this.selectedbattery = [];
    // this.selecteddisplay = [];
    this.filtersortfirst = [];
    this.filtersortsecond = [];
    this.filtersortthird = [];
    this.authservice.apipost('filter_by/', { "category_id": 3 }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {

        this.filterdata = [];
        this.options.floor = response.response.min_price;
        this.selectedlowrange = 0;
        this.selectedhighrange = 0;
        this.options.ceil = response.response.max_price;

        this.modalService.open(filter, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )

    // Added by Kunal on 02 july 2020 start
    this.authservice.apipost('CategoryList/', {
      flag: 'category'

    }).subscribe((res: any) => {
      let response = res.json();
      if (response.status == 400 || response.status == 500 || response.status == 100) {
        Swal.fire(response.message)
      }
      else if (response.status == 200) {
        this.category = response.response;
      }
      else { Swal.fire("Server Down") }
    }
      , err => {
        this.spinner.hide();
        Swal.fire("Connect to the Internet")
      }
    )
    // end
  }


  // Reset All active filters added by Kunal on 29 july 2020
  Resetfilter() {

    if (window.location.pathname == "/homepage/productListing") {
      this.storage.store('sidedraweractivate', 0)
      location.reload();
    }
    else {
      this.storage.store('sidedraweractivate', 0)
      this.router.navigate(['/homepage/productListing']);
    }
  }
  // end

  sortmoadal(sort) {
    this.modalService.open(sort, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => { }, (reason) => { });
  }

  onOpen($event) {
    if ($event == true) {
      alert('open')
    }
    else {
      alert('close')
    }
  }

  onClose($event) {
    if ($event == true) {
      alert('close')
    }
    else {
      alert('close')
    }
  }

  bannerdetails(actiontype, videolink, ban, banid) {
    // Added by Kunal on 23 april 2020
    // alert(actiontype)
    if (actiontype == 4) {
      this.router.navigate(['./homepage/Video']);
      this.storage.store("videostorage", videolink);
    }

    // modifed by Kunal bansal on 23 feb 2021 start
    else if (actiontype == 2) {
      // Added on 17 june 2020 by Kunal bansal start
      // document.location.href = ;
      // added on 29 june 2020 start
      window.open(videolink, "_blank");
      // end
      // end
    }
    // end
    else if (actiontype == 3) {
      // Modified on 22 june 2020 by Kunal bansal start
      // added on 29 june 2020 start
      // 719 point(amazon link not open) fixed on 13 july 2020 by Kunal bansal start
      if (videolink.includes('http')) {

        window.open(videolink, "_blank");
      }
      else {

        window.open("//" + videolink, "_blank");
      }
      // end
      // commented on 29 june 2020
      // document.location.href = videolink;  // Added by Kunal on 25 april 2020
      // end
    }
    else if (actiontype == 5) {
      // added by Kunal on 22 june 2020 start
      this.storage.store("bannerid", banid);
      this.storage.store('checkbanner', 1);
    
     this.storage.clear('brandlabelslisting');
      this.router.navigate(['/homepage/search']);
      // alert('action type 5')
      // modified on 17 june 2020 by Kunal bansal start

      // end

    }
    else {
      // Added on 23 feb 2021 by Kunal bansal start

      window.open(ban, "_blank");
    }

  }



}
