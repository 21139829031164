import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { SignupGuardGuard } from './signup-guard.guard';
import { HomepageComponent } from './homepage/homepage.component';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { AddNewAddressComponent } from './add-new-address/add-new-address.component';
import { KycUploadComponent } from './kyc-upload/kyc-upload.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CartComponent } from './cart/cart.component';
import { CouponComponent } from './coupon/coupon.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignupVerificationComponent } from './signup-verification/signup-verification.component';
import { FaqComponent } from './faq/faq.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NotificationComponent } from './notification/notification.component';
import { AskNowComponent } from './ask-now/ask-now.component';
import { BuyRequestComponent } from './buy-request/buy-request.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyOrdersSellComponent } from './my-orders-sell/my-orders-sell.component';
import { MyOrdersBuyComponent } from './my-orders-buy/my-orders-buy.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryApprovedComponent } from './inventory-approved/inventory-approved.component';
import { InventoryPendingComponent } from './inventory-pending/inventory-pending.component';
import { InventoryRejectedComponent } from './inventory-rejected/inventory-rejected.component';
import { InventoryExpiringComponent } from './inventory-expiring/inventory-expiring.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { SearchedListComponent } from './searched-list/searched-list.component';
import { KycEditComponent } from './kyc-edit/kyc-edit.component';
import { BannerDetailsComponent } from './banner-details/banner-details.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import { Sellerproductlisting } from './sellerproductlisting/sellerproductlisting';
import { Changeaddress } from './changeaddress/changeaddress';
import { Videoplayer } from './Videoplayer/Videoplayer';
import { Orderqueries } from './orderqueries/Orderqueries';
import { Querydetails } from './querydetails/querydetails';
import { Categorylisting } from './Categorylisting/Categorylisting';
import { Brandlisting } from './Brandlisting/Brandlisting';

// phase 3 module
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WhoWeAreComponent } from './who-we-are/who-we-are.component';
import { CareersComponent } from './careers/careers.component';
import { TechnologyComponent } from './technology/technology.component';
import { WhatWeDoComponent } from './what-we-do/what-we-do.component';
import { TeamComponent } from './team/team.component';
import { BuySellComponent } from './buy-sell/buy-sell.component';
import { HelpComponent } from './help/help.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ContactComponent } from './contact/contact.component';
// phase 5 cr ledger point neew transaction screen added by Kunal bansal on 18 oct 2021 start
import {MyTransactionsComponent} from '../app/my-transactions/my-transactions.component';
import { MyBuyersComponent } from './my-buyers/my-buyers/my-buyers.component';
import { BuyerprotectGuard } from './Guards/buyerprotect.guard';
// ...end...




export const appRoutes: Routes = [
  {
    path: 'AboutEOL',
    children: [
      { path: 'AboutUs', component: HomeComponent },
      { path: 'career', component: CareersComponent },
      { path: 'who-we-are', component: WhoWeAreComponent },
      { path: 'technology', component: TechnologyComponent },
      { path: 'what-we-do', component: WhatWeDoComponent },
      { path: 'Ourteam', component: TeamComponent },
      { path: 'post-your-requirement', component: BuySellComponent },
      { path: 'faq', component: HelpComponent },
      { path: 'terms-conditions', component: TermsConditionsComponent },
      { path: 'contactus2', component: ContactComponent },
    ]
  },


  {
    path: 'homepage', component: HomepageComponent,
    children: [
      { path: 'productListing', component: ProductListingComponent },
      { path: 'productdetail', component: ProductDetailsComponent },
      { path: 'contactUs', component: ContactUsComponent },
      { path: 'aboutUs', component: AboutUsComponent },
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'forgotpassword', component: ForgotPasswordComponent },
      { path: 'Faq', component: FaqComponent },
      { path: 'TandC', component: TermsAndConditionsComponent },
      { path: 'search', component: SearchedListComponent },
      { path: 'team', component: OurTeamComponent },
      { path: 'bannerdetails', component: BannerDetailsComponent },
      { path: 'sellerproductlstng', component: Sellerproductlisting },
      { path: 'changeaddrss', component: Changeaddress },
      { path: 'Video', component: Videoplayer },
      { path: 'orderqueries', component: Orderqueries },
      { path: 'querydetail', component: Querydetails },
      { path: 'categorylist', component: Categorylisting },
      { path: 'brandlist', component: Brandlisting },
      { path: 'profile', component: MyProfileComponent, canActivate: [AuthGuard] },
      { path: 'addAddress', component: AddNewAddressComponent, canActivate: [AuthGuard] },
      { path: 'kycUpload', component: KycUploadComponent, canActivate: [AuthGuard] },
      { path: 'kycdetails', component: KycEditComponent, canActivate: [AuthGuard] },
      { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
      { path: 'applyCoupon', component: CouponComponent, canActivate: [AuthGuard] },
      { path: 'asknow', component: AskNowComponent, canActivate: [AuthGuard] },
      { path: 'buyrequest', component: BuyRequestComponent, canActivate: [AuthGuard] },
      { path: 'notifications', component: NotificationComponent, canActivate: [AuthGuard] },
      { path: 'signupverify', component: SignupVerificationComponent },
      {
        path: 'orders', component: MyOrdersComponent, canActivate: [AuthGuard],
        children: [
          { path: 'purchased', component: MyOrdersBuyComponent },
          { path: 'sold', component: MyOrdersSellComponent }
        ]
      },
      { path: 'ordertracking', component: OrderTrackingComponent, canActivate: [AuthGuard] },
      { path: 'orderdetails', component: OrderDetailsComponent, canActivate: [AuthGuard] },
      {
        path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard],
        children: [
          { path: 'approved', component: InventoryApprovedComponent },
          { path: 'pending', component: InventoryPendingComponent },
          { path: 'expired', component: InventoryExpiringComponent },
          { path: 'rejected', component: InventoryRejectedComponent },

        ]
      },
      { path: 'changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      // phase 5 cr ledger point neew transaction screen added by Kunal bansal on 18 oct 2021 start
      { path: 'transactions', component: MyTransactionsComponent, canActivate: [AuthGuard] },
        // phase 5 cr mybuyer added by Kunal bansal on 02 nov 2021 start
      { path: 'mybuyers', component: MyBuyersComponent, canActivate: [BuyerprotectGuard] },
// ...end...
    ]
  },
  { path: '', redirectTo: '/homepage/productListing', pathMatch: "full" },
  { path: '**', redirectTo: '/homepage/productListing', pathMatch: "full" }
];
