import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
// <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
import { Uaepoboxes } from '../../app/Services/Uaepoboxes.service';
// ....end....


@Component({
  selector: 'app-changeaddress',
  templateUrl: './changeaddress.html',
  styleUrls: ['./changeaddress.css']
})

export class Changeaddress implements OnInit {
  submitForm: FormGroup;
  updateaddress: any = [];
  fullname: string;
  mobile: number;
  Add1: string;
  Add2: string;
  land: string;
  pincode1: number;
  city1: string;
  state1: string;
  goingthroughgeneratepickup: number = 0;

  add1message: any;
  add2message: any;
  // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
  country = [];
  selectedcountry: string;
  uaecities = [];
  // ....end....
  // phase 5 location added in changeaddress by Kunal bansal on 04 oct 2021 start
  uaelocationcities = [{ "name": "Please Select Location", "value": "" }, { "name": "Freezone", "value": "Freezone" }, { "name": "Mainland", "value": "Mainland" }];
  uaelocationtype: string;
  Freezonelisting = [];
  uaecountries = [];


  constructor(private pobox: Uaepoboxes, private storage: SessionStorageService, private formBuilder: FormBuilder, private router: Router, private authservice: AuthService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.getfreezonelisting();
    this.gettalluaecountries();
    this.updateaddress = this.storage.retrieve('storedadd');      // Added by Kunal on 23 april 2020
    this.fullname = this.storage.retrieve('fullname')
    this.mobile = this.storage.retrieve('mobile')// Added by Kunal on 23 april 2020
    this.Add1 = this.storage.retrieve('Add1')
    this.Add2 = this.storage.retrieve('Add2')// Added by Kunal on 23 april 2020
    this.land = this.storage.retrieve('land')
    this.pincode1 = this.storage.retrieve('pincode1')// Added by Kunal on 23 april 2020
    this.city1 = this.storage.retrieve('city1')// Added by Kunal on 23 april 2020
    this.state1 = this.storage.retrieve('state1')
    this.goingthroughgeneratepickup = this.storage.retrieve("goingthroughgeneratepickup");
    // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->

    this.selectedcountry = sessionStorage.getItem("countrymodulecountry");
    console.log(this.city1 + "," + this.state1)

    // ...end...


    // phase 5 location added in changeaddress by Kunal bansal on 04 oct 2021 start

    this.submitForm = this.formBuilder.group({
      name: [''], phone: [''], Address1: [''], Address2: [''], landmark: [''], pincode: [''], city: [''], state: [''], country: [''], location_type: [''], uaeselectedlocation: ['']
    });
    this.submitForm.controls['name'].setValue(this.fullname)    // Added by Kunal on 23 april 2020
    this.submitForm.controls['phone'].setValue(this.mobile)
    this.submitForm.controls['Address1'].setValue(this.Add1) // Added by Kunal on 23 april 2020
    this.submitForm.controls['Address2'].setValue(this.Add2)  // Added by Kunal on 23 april 2020
    this.submitForm.controls['landmark'].setValue(this.land)
    this.submitForm.controls['pincode'].setValue(this.pincode1) // Added by Kunal on 23 april 2020
    this.submitForm.controls['city'].setValue(this.city1)
    this.submitForm.controls['state'].setValue(this.state1)    // Added by Kunal on 23 april 2020
    // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
    this.submitForm.patchValue({ "country": sessionStorage.getItem("countrymodulecountry") });
    this.submitForm.patchValue({ "uaeselectedlocation": sessionStorage.getItem("uaelocationtype") });


    if (sessionStorage.getItem("phase5locationtype") == null) {
      this.submitForm.patchValue({ "location_type": '' });
    }
    else {
      this.submitForm.patchValue({ "location_type": sessionStorage.getItem("phase5locationtype") });
    }
    this.uaelocationtype = this.submitForm.value.location_type;

    if (this.selectedcountry == "India") {
      this.country = ['India', 'UAE'];
    }
    else {
      this.country = ['UAE', 'India'];
      // this.getpobox();
      this.selectuaecountry(this.submitForm.value.state);
    }
    // ...end...
    $(document).ready(function () {
      $("input[type=number]").keypress(function (evt) {
        if ((evt.which < 48 || evt.which > 57) || ($(this).val().length == 10)) {
          if (evt.which == 8 || evt.which == 0) { }
          else { evt.preventDefault(); }
        }
      })
    });
  }


  ngOnDestroy() {
    this.storage.clear("storedadd")  // Added by Kunal on 23 april 2020
    this.storage.clear("fullname")
    this.storage.clear("mobile")  // Added by Kunal on 23 april 2020
    this.storage.clear("Add1")
    this.storage.clear("Add2")
    this.storage.clear("land") // Added by Kunal on 23 april 2020
    this.storage.clear("pincode1")
    this.storage.clear("city1")
    this.storage.clear("state1") // Added by Kunal on 23 april 2020
    //  added by Kunal bansal on 15 march 2021 start
    if (window.location.pathname == "/homepage/cart") {

    }
    else {
      this.storage.store('checkcodpre', 1)
    }
    // end
  }

  // phase 5 new cr uae freezone and mainland cities module added by Kunal bansal on 03 nov 2021 start
  getfreezonelisting() {
    try {
      this.pobox.getfreezonelisting().map(res => {

        this.Freezonelisting = res.response;
        console.log(this.Freezonelisting);
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  gettalluaecountries() {

    try {
      this.pobox.getallcountries().map(res => {

        if (res.status == 200) {
          console.log(res.response);

          for (let n = 0; n < res.response.length; n++) {
            this.uaecountries.push(res.response[n].country)
          }
          console.log(this.uaecountries)
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      console.log(err)
      // this.model.state = '';
      // // this.uaecities = [];
    }

  }

  inlineselectuaecountry(event) {
    this.submitForm.patchValue({
      city: ""
    })
    try {
      this.pobox.getcitybycountry(event).map(res => {

        if (res.status == 200) {
          this.uaecities = res.response.cities;
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      // this.uaecities = [];
      console.log(err)
    }
  }

  selectuaecountry(event) {

    try {
      this.pobox.getcitybycountry(event).map(res => {

        if (res.status == 200) {
          this.uaecities = res.response.cities;
        }
        else {

        }
      })
    }
    catch (err) {
      // Swal.fire("Enter valid Pobox number");
      // this.uaecities = [];
      console.log(err)
    }
  }
  // ...end...

  // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
  getpobox() {

    if (this.submitForm.value.pincode == undefined || this.submitForm.value.pincode=="") {
      // Swal.fire('Enter valid Po Box Number')
    }
    else {
      try {
        this.pobox.Getponumber(this.submitForm.value.pincode).map(res => {

          if (res.status == 200) {
            console.log(res.response)

            // this.submitForm.patchValue({
            //   state: res.response.country,

            // });
            // this.uaecities = res.response.cities;

          }
          else {

          }
        })
      }
      catch (err) {
        Swal.fire("Enter valid Pobox number");
        this.submitForm.patchValue({
          pincode: '',

        });
      }

    }


  }



  changecountry(event) {

    this.selectedcountry = event.target.value;
    this.submitForm.patchValue({
      pincode: '',
      state: '',
      city: '',
      location_type: ''

    });
    this.uaecities = [];

    if (event.target.value == "India") {
      this.country = ['India', 'UAE'];

    }
    else {
      this.country = ['UAE', 'India'];

    }


  }

  locationtype(event) {
    this.submitForm.patchValue({
      uaeselectedlocation: '',
    });
    this.uaelocationtype = event.target.value;


  }

  // ..............end.............

  // pincode auto not fetch module added and fixed by Kunal bansal on 28 aug 2020

  getpincode() {
    if (this.submitForm.value.pincode.length < 6 || this.submitForm.value.pincode.length > 6) {
      Swal.fire("Please enter a valid Pincode")
    }
    else {
      this.authservice.apipost('getPincode/', {
        "pincode": this.submitForm.value.pincode
      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500) {
          Swal.fire(response.message)
        }
        else if (response.status == 100) {
          this.submitForm.patchValue({ pincode: this.pincode1 })
          Swal.fire('No State City found linked to your pincode , Please try another pincode')
        }
        else if (response.status == 200) {
          this.pincode1 = this.submitForm.value.pincode;
          this.submitForm.patchValue({
            state: response.response.state,
            city: response.response.city
          });
        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }
  }

  // <!-- hint and validation add address new phase 4 module added by Kunal bansal on 09 april 2021 start -->
  add1validate() {

    // alert(this.addform.value.address1.length)
    this.add1message = "Maximum 70 Characters are allowed";
    if (this.submitForm.value.Address1.length == 70) {
      this.add1message = "Maximum 70 Characters limit reached";
    }
  }

  add2validate() {
    this.add2message = "Maximum 70 Characters are allowed";
    if (this.submitForm.value.Address2.length == 70) {
      this.add2message = "Maximum 70 Characters limit reached";
    }
  }

  emptymsg() {
    this.add1message = "";
    this.add2message = "";
  }

  // end


  submit(id) {
    // alert(this.submitForm.value.name.length)


    // alert(id)

    if ($.trim(this.submitForm.value.name).length == 0) {
      Swal.fire("Please enter a valid name")
    }
    else if ($.trim(this.submitForm.value.phone).length == 0) {
      Swal.fire("Please enter a valid Mobile no")
    }
    else if ($.trim(this.submitForm.value.Address1).length == 0) {
      Swal.fire("Please enter a valid Address")
    }
    else if ($.trim(this.submitForm.value.city).length == 0) {
      Swal.fire("Please enter a valid city")
    }
    else if ($.trim(this.submitForm.value.state).length == 0) {
      Swal.fire("Please enter a valid state")
    }
    else if (this.submitForm.value.uaeselectedlocation == '' && this.selectedcountry == 'UAE') {
      Swal.fire("Please select Freezone/Mainland location type")
    }
    // <!-- phase 5 location added in change address by Kunal bansal on 04 oct 2021 start -->
    else if (this.submitForm.value.location_type == '' && this.selectedcountry == 'UAE') {
      Swal.fire("Please select location type")
    }
    else {

      this.authservice.apipost('UpdateAddress/', {
        city: this.submitForm.value.city.trim(),
        state: this.submitForm.value.state.trim(),
        pincode: this.submitForm.value.pincode,
        name: this.submitForm.value.name,
        phone: this.submitForm.value.phone,
        address_line_1: this.submitForm.value.Address1,
        address_line_2: this.submitForm.value.Address2,
        landmark: this.submitForm.value.landmark,
        adid: id,
        // <!-- phase 5 module country render in my profile ,add edit and update added by Kunal bansal on 24 aug 2021 start -->
        country: this.selectedcountry,
        // <!-- phase 5 location added in change address by Kunal bansal on 04 oct 2021 start -->
        location_type: this.selectedcountry == 'UAE' ? this.submitForm.value.location_type : "",
        // ...end...
        user_location: this.submitForm.value.uaeselectedlocation,

        // Params added by Kunal bansal on 30 nov 2021 after phase 5 cr set shipping address when dispatch order 
        order_id: '',
        set_shipping: false,

      }).subscribe((res: any) => {
        let response = res.json();
        if (response.status == 400 || response.status == 500 || response.status == 100) {
          Swal.fire(response.message)
        }
        else if (response.status == 200) {
          Swal.fire(response.message);
          // <!-- phase 3 new cr selecting or add address module added by Kunal bansal on 12 march 2021 start-->
          if (this.goingthroughgeneratepickup == 1) {
            this.router.navigate(['./homepage/orders/sold'])
          }
          else if (this.goingthroughgeneratepickup == 2) {
            this.router.navigate(['./homepage/cart'])
          }
          else {
            this.router.navigate(['/homepage/profile']);
          }

        }
        else { Swal.fire("Server Down") }
      }
        , err => {
          this.spinner.hide();
          Swal.fire("Connect to the Internet")
        }
      )
    }

  }

}