import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataService } from '../data.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css','../../assets/Newwebfiles/style.css']
})
export class TeamComponent implements OnInit {
  contents:any[]=[];

  constructor(private server: DataService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
    this.getleadershipcontent();
  }

  private getleadershipcontent() {
    this.server.getleadershipcontent().then((response: any) => {
      this.contents = response.data[0];
    });
  }

}
